import React, { useEffect } from "react";
import "../../SalesReport/SalesReport.css";
import Logo from "../../../assets/images/logo.png";
import Logo2 from "../../../assets/images/logo2.svg";
import { useSelector } from "react-redux";
import { ReadCookie } from "../../../utils/readCookie";
import { useDispatch } from "react-redux";
import moment from "moment";

const dateFormatList = ["MM-DD-YYYY"];
const SalesReturnReportHeader = () => {
  const token = ReadCookie("token");
  const menuId = ReadCookie("menuId");
  const dispatch = useDispatch()
  const { RAPrint } = useSelector((state) => state);
  const urlParams = new URLSearchParams(window.location.search);
  const brand = urlParams.get('brand');
  const _logo = brand ? (brand === 'DL1961' ? Logo : Logo2) : Logo

  const headerData = RAPrint && RAPrint?.payload?.data[0];




  return (
    <div className='sales-report'>

      <div className="sales-report-header">
        {/* header Left */}
        <div className="header-left-side-return">
          {/* Logo & Text */}
          <div className="sales-report-Logo-Container">
            <div>
              <img width={250} height={72} src={_logo} />
            </div>
            {/* Logo Items */}
            <div className="sales-report-Logo_items">
              <div>DLPD CANADA LTD.</div>
              <div>15 Wertheim Court, Suite 409,</div>
              <div>Richmond Hill, ON L4B 3H7 , Canada</div>
              <div>Business # : 763395530RT0001</div>
              <div>Email : orderca@dl1961.com</div>
            </div>
            {/* Logo Items */}
          </div>
          {/* Items details */}
          <div className="items-detail-container">
            {/* Sold To */}
            <div className="sold-to-Container2">
              {/* <div className="sold-to">
                <div className="font_weight">Sold To :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_customer}</div>
              </div> */}
              <div className="sold-to-return">
                <div className="font_weight">Name :</div>
                <div className="sold-to-data">{headerData && headerData.bill_to_customer}</div>
              </div>
              <div className="sold-to-return">
                <div className="font_weight ">Address :</div>
                <div className="sold-to-data">
                  {headerData && headerData?.bill_to_address_1} { }
                  {headerData?.bill_to_address_2 && headerData?.bill_to_address_2}
                  {/* <div> */}
                  {headerData && ` ${headerData?.bill_to_city_name}, ${headerData?.bill_to_state_name}, ${headerData?.bill_to_country}, ${headerData?.bill_to_zip}`}
                  {/* </div> */}
                </div>
              </div>
              <div className="sold-to-return">
                <div className="font_weight">Email :</div>
                <div className="sold-to-data">{headerData && headerData.email}</div>
              </div>
              <div className="sold-to-return">
                <div className="font_weight">Tel No :</div>
                <div className="sold-to-data">{headerData && headerData.phone}</div>
              </div>
            </div>
            {/* Sold To */}
            {/* Ship To */}
            {/* <div className="ship-to-Container">
              <div className="sold-to">
                <div className="font_weight">Ship To :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_addressee}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Name :</div>
                <div className="sold-to-data">Attn: {headerData && headerData.customer_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 1 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_1}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 2 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_2}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">City :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_city_name}</div>
                <div className="font_weight">State :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_state_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Country :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_country_name}</div>
                <div className="font_weight">ZIP :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_zip}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Dept :</div>
                <div className="sold-to-data">{headerData && headerData.department}</div>
                <div className="font_weight">DC #</div>
                <div className="sold-to-data">{headerData && headerData.dc_no}</div>
                <div className="font_weight">Store :</div>
                <div className="sold-to-data">{headerData && headerData.store_name}</div>
              </div>

            </div> */}
            {/* Ship To */}
          </div>
          {/* Items details */}
        </div>
        {/* header Left */}
        {/* header Right */}
        <div className="header-right-side-return">
          <div className="sales-order-heading">Sales Return</div>
          <div className="sales-order-items-container">
            <div className="sales-order-items-left-return">
              <div className="font_weight">Ref # :</div>
              <div className="font_weight">RA Approval Date :</div>
              <div className="font_weight">Invoice # :</div>
            </div>
            <div className="sales_order_content_return">
              <div>{headerData && headerData.ra_no}</div>
              <div>{headerData && moment(headerData.ra_approval_date).format("MM-DD-YYYY")}</div>
              <div style={{ wordWrap: "break-word" }}>{headerData && headerData.invoice_header_no}</div>
            </div>
          </div>
        </div>
        {/* header Right */}
      </div>
    </div>
  );
};

export default SalesReturnReportHeader;
