import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Col, Row, Button } from 'antd';
import axios from 'axios';
import Swal from "sweetalert2";
import notification from '../../utils/notification';
import Tables from '../../components/Table/Tables';
import { ReadCookie } from '../../utils/readCookie';
import { TabTitle } from '../../utils/GeneralFunction';
import Loader from "react-loader-spinner";
import { BASEURL, MIGRATION_URL } from '../../constant/config';

const Routine = () => {
    TabTitle("Routine")
    const token = ReadCookie("token");
    const [dlLoading, setDlLoading] = useState(false);
    const [dlSuccess, setDlSuccess] = useState(false);
    const [wwLoading, setWWLoading] = useState(false);
    const [wwSuccess, setWWSuccess] = useState(false);
    const [routineLog, setRoutineLog] = useState([]);

    useEffect(() => {
        getRoutineLog()
    }, []);

    const getRoutineLog = () => {
        axios({
            method: "POST",
            url: `${BASEURL}getAll/Routine_stats`,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }).then(function (response) {

            setRoutineLog(response?.data?.data)
        })
    }


    const column = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 40,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Routine Name",
            dataIndex: "routine_name",
            key: "routine_name",
        },
        {
            title: "Routine Date",
            dataIndex: "r_date",
            key: "r_date",

        },
        {
            title: "Routine Time",
            dataIndex: "r_time",
            key: "r_time",

        },
        {
            title: "Total SKU",
            dataIndex: "total_skus",
            key: "total_skus",
        },
        {
            title: "Total Qty",
            dataIndex: "total_quantity",
            key: "total_quantity",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => {
                return text === true ? "True" : "False"

            }
        },
        {
            title: "Status",
            dataIndex: "user_name",
            key: "user_name",

        },
    ];

    const getBulkDL = () => {
        setDlLoading(true)
        axios.get(MIGRATION_URL + 'mssql/bulk-dl')
            .then(function (response) {
                if (response.status == 200) {
                    setDlSuccess(true)
                    notification('success', 'Bulk DL', 'Migration Successful')
                    getRoutineLog()
                }
            })
            .catch(function (error) {
                setDlSuccess(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Migration Failed",
                });
            })
            .finally(function () {
                setDlLoading(false)
            });
    }

    const getBulkWW = () => {
        setWWLoading(true)
        axios.get(MIGRATION_URL + 'mssql/bulk-ww')
            .then(function (response) {
                if (response.status == 200) {
                    setWWSuccess(true)
                    notification('success', 'Bulk WW', 'Migration Successful')
                    getRoutineLog()
                }
            })
            .catch(function (error) {
                setWWSuccess(false)
                const status = error?.response?.status || 500
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: status === 404 ? "No Data to Migrate" : "Migration Failed",
                });
            })
            .finally(function () {
                setWWLoading(false)
            });
    }

    return (
        <>
            <Row gutter={[18, 12]}>
                {
                    dlLoading ||
                        wwLoading ? (
                        <div>
                            <div className="loaderDiv">
                                <Loader type="Grid" color="#212121" height={40} width={40} />
                                <div>
                                    <p style={{ fontSize: 26 }}>Please Wait Until the current Routine is completed.</p>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: dlSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={dlSuccess && <CheckOutlined />} size='large' loading={dlLoading} onClick={() => getBulkDL()}>
                            Bulk DL
                        </Button>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: wwSuccess ? "green" : "#3d4b58", color: "#ffff" }} icon={wwSuccess && <CheckOutlined />} size='large' loading={wwLoading} onClick={() => getBulkWW()}>
                            Bulk WW
                        </Button>
                    </div>
                </Col>
            </Row >
            <div style={{ paddingTop: "200px" }}>
                <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={routineLog}
                    col={column}
                    paginate
                />
            </div>
        </>
    )
};
export default Routine;