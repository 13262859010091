import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "./pickticket.css"
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import {
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    Table,
    InputNumber,
    Space, Spin
} from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import { getStyle } from "../../redux/actions/style";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { getPendingAvailability, getPickTicketDetail } from "../../redux/actions/pickTicketSummary.action"
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { companyID, currencySymbol } from "../../utils/constants";

const { Option } = Select;

const PendingOrderAvailabilityBySize = () => {
    TabTitle("Pending Order Summary OTS")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const [toogle, setToogle] = useState(false);
    const submitButton = useRef(null);
    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const [pendingSummaryList, setPendingSummaryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);
    const queryParams = new URLSearchParams(window.location.search);
    const customerID = queryParams.get("customer_id");
    const companyId = ReadCookie("defaultCompany");
    const {
        gender,
        region,
        salePersonForm,
        defaultCompany,
        paymentterm,
        customerTypes,
        PendingOrderAvailability,
        saleOrderFormData,
        adminLocation
    } = useSelector((state) => state);
    const [excelData, setExcelData] = useState([])




    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId));
        dispatch(getAllCompany(token));
        dispatch(getStyle(token, menuId));
        dispatch(loadSalePerson(token));
        dispatch(getDefaultCompany(token));
        dispatch(getPaymentTerm(token));
        dispatch(getCustomerType(token));
        dispatch(getAdminLocation(token, menuId));
        dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
    }, [dispatch, menuId, token]);


    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "Order": v.order_header_no,
                    "Customer": v.billingcustomer,
                    "PO #": v.reference_no,
                    "Order Date": moment(v.order_date).format("MM-DD-YY"),
                    "Start Date": moment(v.start_date).format("MM-DD-YY"),
                    "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
                    "CIT": v.factor_decision,
                    "SKU": v.item_code,
                    "Style": v.style_name,
                    "Wash": v.wash_name,
                    "Inseam": v.inseam,
                    "Size": v.size,
                    "Total Order Qty": parseFloat(v.total_quantity).toFixed(0),
                    "Total Pending Qty": parseFloat(v.pending_qty).toFixed(0),
                    "Line Item Qty": parseFloat(v.quantity).toFixed(0),
                    "Line Item Pending Qty": parseFloat(v.pendingqty).toFixed(0),
                    "Actual Committed": parseFloat(v.quantity_committed).toFixed(0),
                    "Backorder": parseFloat(v.quantity_backorder).toFixed(0),
                    "Order Amount": numeral(v.amount).format("0,0.00"),
                    "Commit Status": v.commit_criteria_name,
                    "Pending Status": v.pengind_status,
                    "Payment Term": v.payment_term,
                    "Hold Status": v.status,
                    "Department": v.gender_name,
                    "Region": v.region_name,
                    "Sale Person Name": v.salesperson_name,
                    "Status": v.order_status,
                    "Notes": v.remarks,
                    "Subsidary": v.company_name,
                    "Bergen Qty": v.bergen_quantity,
                    "Intransit Qty": v.intrasit_quantity,
                    "Production Qty": v.production_quantity,
                    "PK Qty": v.pk_quantity,
                };
            })
        )
    }



    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'Order #',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
            
            render: (text, record) => (
                <a
                    href={`
                ${"sale order" ? `sale-order?mode=edit&order_id=${record.order_header_id}&customer_id=${record.customer_id}`
                            : ``
                        } 
                
                `}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {text}
                </a>
            ),
        },
        {
            title: 'Customer',
            dataIndex: 'billingcustomer',
            key: 'billingcustomer',
            
        },
        {
            title: 'PO #',
            dataIndex: 'reference_no',
            key: 'reference_no',
            
        },
        {
            title: 'Order Date',
            render: (text, record) => {
                return <>{moment(record.order_date).format("MM-DD-YYYY")}</>;
            },
            
        },
        {
            title: 'Start Date',
            render: (text, record) => {
                return <>{moment(record.start_date).format("MM-DD-YYYY")}</>;
            },
            
        },
        {
            title: 'Cancel Date',
            render: (text, record) => {
                return <>{moment(record.cancel_date).format("MM-DD-YYYY")}</>;
            },
            
        },
        {
            title: 'CIT',
            dataIndex: 'factor_decision',
            key: 'factor_decision',
            
        },
        {
            title: 'SKU',
            dataIndex: 'item_code',
            key: 'item_code',
            
        },
        {
            title: 'Style',
            dataIndex: 'style_name',
            key: 'style_name',
            
        },
        {
            title: 'Wash',
            dataIndex: 'wash_name',
            key: 'wash_name',
            
        },
        {
            title: 'Inseam',
            dataIndex: 'inseam',
            key: 'inseam',
            
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            
        },
        {
            title: 'Total Order Qty',
            render: (text, record) => {
                return <>{parseFloat(record.total_quantity).toFixed(0)}</>;
            },
            
            align: "right",
        },
        {
            title: 'Total Pending Qty',
            render: (text, record) => {
                return <>{parseFloat(record.pending_qty).toFixed(0)}</>;
            },
            
            align: "right",
        },
        {
            title: 'Line Item Qty',
            render: (text, record) => {
                return <>{parseFloat(record.quantity).toFixed(0)}</>;
            },
            
            align: "right",
        },
        {
            title: 'Line Item Pending Qty',
            render: (text, record) => {
                return <>{parseFloat(record.pendingqty).toFixed(0)}</>;
            },
            
            align: "right",
        },
        {
            title: 'Actual Committed',
            render: (text, record) => {
                return <>{parseFloat(record.quantity_committed).toFixed(0)}</>;
            },
            
            align: "right",
        },
        {
            title: 'Backorder',
            render: (text, record) => {
                return <>{parseFloat(record.quantity_backorder).toFixed(0)}</>;
            },
            
            align: "right",
        },
        {
            title: 'Order Amount',
            dataIndex: 'amount',
            key: 'amount',
            
            align: "right",
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.amount).format("0,00")}</>
            },
        },
        {
            title: 'Commit Status',
            dataIndex: 'commit_criteria_name',
            key: 'commit_criteria_name',
            
        },
        {
            title: 'Pending Status',
            dataIndex: 'pengind_status',
            key: 'pengind_status',
            
        },
        {
            title: 'Payment Term',
            dataIndex: 'payment_term',
            key: 'payment_term',
            
        },
        {
            title: 'Hold Status',
            dataIndex: 'status',
            key: 'status',
            
        },
        {
            title: 'Department',
            dataIndex: 'gender_name',
            key: 'gender_name',
            
        },
        {
            title: 'Region',
            dataIndex: 'region_name',
            key: 'region_name',
            
        },
        {
            title: 'Sale Person Name',
            dataIndex: 'salesperson_name',
            key: 'salesperson_name',
            
        },
        {
            title: 'Status',
            dataIndex: 'order_status',
            key: 'order_status',
            
        },
        {
            title: 'Notes',
            dataIndex: 'remarks',
            key: 'remarks',
            
        },
        {
            title: 'Subsidiary',
            dataIndex: 'company_name',
            key: 'company_name',
            
        },
        {
            title: 'Bergen Qty',
            dataIndex: 'bergen_quantity',
            key: 'bergen_quantity',
            align: "right",
            
        },
        {
            title: 'Intransit Qty',
            dataIndex: 'intrasit_quantity',
            key: 'intrasit_quantity',
            align: "right",
            
        },
        {
            title: 'Production Qty',
            dataIndex: 'production_quantity',
            key: 'production_quantity',
            align: "right",
            
        },
        {
            title: 'PK Qty',
            dataIndex: 'pk_quantity',
            key: 'pk_quantity',
            align: "right",
            
        },
    ];


    const onFinish = async () => {
        setLoading(true);
        await form.validateFields().then((values) => {
            dispatch(getPendingAvailability(token, values)).then((res) => {
                setToogle(true);
                setLoading(false);
                setPendingSummaryList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }).catch((error) => {
            setLoading(false);
        })
    }

    const totalQuantity = () => {
        let sumQty = pendingSummaryList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.pendingqty);
        }, 0);
        return sumQty;

        
    }

    const totalSum = () => {
        let sumAmount = pendingSummaryList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.amount);
        }, 0);
        return sumAmount;
    }


    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader
                    title={"Pending Order Summary OTS"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-0">
                            <Form onFinish={onFinish} form={form} name="control-hooks">
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="cutomer_category"
                                                    label="Customer Category"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                         filterSort={(optionA, optionB) =>
                                                            optionB?.value!==null?
                                                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                                        }
                                                        defaultValue={form.getFieldValue().cutomer_category}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            customerTypes?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.customer_type_id}>{val.customer_type_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>

                                        {/* <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date"
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment(("01-01-2023"))}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol> */}
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div >
                                                <div className="relative">
                                                    <Form.Item
                                                        name="to_date"
                                                        label="To Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().endOf('year')}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="order_no"
                                                        label="Order #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <InputNumber
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="customer"
                                                        label="Customer"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="po_no"
                                                        label="PO #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="sale_person"
                                                    label="Sale Person"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value!==null?
                                                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().sale_person}
                                                       
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            salePersonForm?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.sales_person_id}>{val.salesperson_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="region"
                                                    label="Region"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }

                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().region}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            region?.payload?.data.map((val) => {
                                                                return (

                                                                    <Option key={val.region_id} value={val.region_id}>{val.region_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="gender"
                                                    label="Gender"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().gender}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            gender?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="terms"
                                                    label="Terms"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value!==null?
                                                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().terms}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            paymentterm?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.term_id}>{val.term_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="source"
                                                    label="Source"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Input
                                                        size='small'
                                                        className="styleInput"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="store"
                                                        label="Store #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="subsidary"
                                                    label="Subsidiary"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={companyID}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        defaultValue={form.getFieldValue().subsidary}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option value={defaultCompany?.payload?.company_id}>{defaultCompany?.payload?.company_name}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="status"
                                                    label="Order Status"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        size="small"
                                                        autoComplete={"nope"}
                                                        allowClear
                                                        loading={saleOrderFormData.loading}
                                                        
                                                        defaultValue={form.getFieldValue().orderStatus}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value!==null?
                                                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {saleOrderFormData?.payload?.orderStatusDetail.map(
                                                            (item) => {
                                                                return (
                                                                    <Option
                                                                        key={item.order_status_id}
                                                                        value={item.order_status_id}
                                                                    >
                                                                        {item.status_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="sku"
                                                        label="Sku #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="firstSectionRo">
                                                <Form.Item
                                                    name="location"
                                                    label="Location"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        autoComplete={"nope"}
                                                        loading={adminLocation.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value!==null?
                                                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            adminLocation?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.location_id}>
                                                                        {val.location_name}
                                                                    </Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="factor_decision"
                                                        label="CIT"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        {/* <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="style"
                                                    label="Style"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().style}
                                                    >
                                                        {
                                                            style?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.style_id}>{val.style_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol> */}

                                    </Brow>
                                </div>
                            </Form>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                {
                    loading ? (
                        <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin size='large' />
                        </div>
                    ) : (
                        pendingSummaryList.length > 0 ? (
                            <Table
                                title={() =>
                                    <CSVLink
                                        filename={"Pending-Order-Summary-OTS.csv"}
                                        data={excelData}
                                        onClick={() => {

                                        }}
                                    >
                                        Download Excel
                                    </CSVLink>
                                }
                                ref={tableRef}

                                size="small"
                                bordered={false}
                                type="company"
                                
                                
                                
                                
                                scroll={{ x: 'max-content' }}
                                
                                
                                summary={(pageData) => {
                                    let sumQty = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.quantity
                                        ));
                                    },
                                        0);
                                    let PendingQty = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.pendingqty
                                        ));
                                    },
                                        0);
                                    let AccCommited = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.quantity_committed
                                        ));
                                    },
                                        0);
                                    let Backorder = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.quantity_backorder
                                        ));
                                    },
                                        0);
                                    let Amount = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.amount
                                        ));
                                    },
                                        0);
                                    let BergenQty = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.bergen_quantity
                                        ));
                                    },
                                        0);
                                    let IntransitQty = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.intrasit_quantity
                                        ));
                                    },
                                        0);
                                    let ProductionQty = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.production_quantity
                                        ));
                                    },
                                        0);
                                    let PKQty = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.pk_quantity
                                        ));
                                    },
                                        0);
                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={3}>Total Pending Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {sumQty.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {PendingQty.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {AccCommited.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {Backorder.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {currencySymbol} {Amount.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {BergenQty.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {IntransitQty.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {ProductionQty.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {PKQty.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Pending Amount : {currencySymbol}{numeral(totalSum()).format("0,0.00")}
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                                dataSource={pendingSummaryList}
                                columns={columns}
                                loading={PendingOrderAvailability.loading}
                                pagination={{
                                    defaultCurrent: 1,
                                    defaultPageSize: 100,
                                    pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                                }}
                            />
                        ) : <div
                            style={{
                                backgroundColor: "#CCCCCC",
                                borderWidth: "1pt",
                                borderStyle: "Dashed",
                                fontWeight: "bold",
                                height: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            No record(s)
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default PendingOrderAvailabilityBySize;
