import React from "react";
import { Button, Tooltip, Divider, Tag, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory, Link } from "react-router-dom";

export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, name, flag, rights, saleOrderNewID, customerCode, formData, ID, userRights }) {

  const history = useHistory();




  const confirmDelete = () => {
    onDelete()
  };



  const handleFormState = () => {
    if (formType === "view") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view === true ? false : true}
              onClick={() => history.push("/order-shipment-new-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Add" color="#fbfbfb">
            <Button
              disabled={formType === "add" ? true : false || userRights.can_add === true ? false : true}
              onClick={() => {
                if (formType === "add") {
                  formState("add");
                } else {
                  history.push("/sale-order-adjustment-new?mode=add");
                  formState("add");
                }
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<PlusOutlined />}
            />
          </Tooltip>
        </>
      )
    }
    else if (formType === "add") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view === true ? false : true}
              onClick={() => history.push("/sale-order-adjustment-new-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              disabled={
                !(
                  (formType === "add" || formType === "edit") &&
                  (name === "product"
                    ? formType === "edit"
                      ? true
                      : flag
                        ? true
                        : false
                    : !disable)
                )
              }
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>
        </>
      )
    }
    else if (formType === "formView" || formType === "edit") {
      return (
        <>

          {/* {formData?.fulfilled && formType !== "add" ? (
            <Tooltip placement="bottom" title="Fulfillment " color="#fbfbfb">
              <Button
                type="primary"
                disabled
                className="modalButton cursor-pointer"
              >
                Fulfilled
              </Button>
            </Tooltip>
          ) : (
            formType !== "add" &&
            customerCode && (
              <Tooltip placement="bottom" title="Fulfillment " color="#fbfbfb">
                <Button
                  type="primary"

                  onClick={() => {
                    history.push({
                      pathname: `/order-shipment/edit/${saleOrderNewID}`,
                      state: { detail: 'editt' }
                    })
                  }}
                  className="modalButton"
                >
                  Fulfillment
                </Button>
              </Tooltip>
            )
          )} */}

          {
            userRights.can_print ? (
              <Link
                style={{ paddingLeft: "10px", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
                to={{
                  pathname: `/sale-adjustment-report`,
                  search: `?order_header_adj_id=${ID}&brand=${formData?.brand_name}`,
                }}
              >
                View Report
              </Link>
            ) : ""
          }

          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Edit" color="#fbfbfb">
                <Button
                  disabled={
                    disable ||
                    formType === "view" ||
                    formType === "add" ||
                    (userRights?.can_edit === true ? false : true)
                  }
                  onClick={() => formState("edit")}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )
          }
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view === true ? false : true}
              onClick={() => history.push("/sale-order-adjustment-new-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>

          {/*Delete Button*/}
          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Delete" color="#fbfbfb"   >
                {rights?.can_delete === false ?
                  <Button disabled={true} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} /> :
                  <Popconfirm

                    disabled={userRights.can_delete === true ? false : true}
                    placement="topLeft"
                    title={`Are you sure to delete this Item ${formData?.order_header_adj_no}?`}
                    onConfirm={confirmDelete}
                    okText="Yes"

                    cancelText="No">
                    <Button
                      disabled={userRights.can_delete === true ? false : true}

                      className="iconBtn crudBTN"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                }
              </Tooltip>
            )
          }
          {
            (formType === "edit" || formType === "add") && (
              <Tooltip placement="bottom" title="Save" color="#fbfbfb">
                <Button
                  disabled={
                    !(
                      (formType === "add" || formType === "edit") &&
                      (name === "product"
                        ? formType === "edit"
                          ? true
                          : flag
                            ? true
                            : false
                        : !disable)
                    )
                  }
                  onClick={() => save()}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<FileDoneOutlined />}
                />
              </Tooltip>
            )
          }


        </>
      )

    }
  }
  return (
    <>
      <div className="crudContainer">
        <div>
          {formType === "" ? null : (
            <div className="buttons-div">
              <Tag color={"green"} key={1}>
                {formType.toUpperCase()}
              </Tag>
              {formType !== "view" && (
                <Button
                  onClick={() => reset()}
                  className="iconBtn"
                  shape="circle"
                  icon={<UndoOutlined className="fspx-12" />}
                />
              )}
            </div>
          )}
        </div>
        <div>
          {handleFormState()}
        </div>
      </div>
      <Divider />
    </>
  );
}
