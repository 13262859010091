import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  DatePicker,
  Input,
  Tabs,
  Button,
  Form,
  Select,
  Tooltip,
  Table,
  Popconfirm,
  InputNumber,
} from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import Swal from "sweetalert2";
import {
  addBillHeader,
  updateBillHeader,
} from "../../../../redux/actions/bill.action";
import {
  addBillLine,
  updateBillLine,
  getOneBillLineDetail,
  deleteBillLine,
} from "../../../../redux/actions/billLine.action";
import {
  addExpenseLine,
  updateExpenseLine,
  getOneExpenseLineDetail,
  deleteExpenseLine,
} from "../../../../redux/actions/expenseLine.action";
import { getChartofAccount } from "../../../../redux/actions/chartofaccount.action";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import Summary from "../../../../components/OrderSummary/OrderSummary";
import EditableTable from "../../../../components/EditableTable/EditableTable";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getAdminCurrency } from "../../../../redux/actions/adminCurrency.action";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getAdminLocation } from "../../../../redux/actions/adminLocation.action";
import { getShipVia } from "../../../../redux/actions/shipvia.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import numeral from "numeral";
import { getAccountPayable } from "../../../../redux/actions/accountPayable.action";
import { CSVLink } from "react-csv";
import { getTransactionNo } from "../../../../redux/actions/getTransactionNo.action";
import BillEditExpense from "../BillEditExpense";
import { updateTransferRate } from "../../../../redux/actions/updateTransferRate";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
import { getBrands } from "../../../../redux/actions/brand.action";
import { companyID, currencySymbol, defaultCurrency } from "../../../../utils/constants";

const { Option } = Select;
const { TabPane } = Tabs;

export default function BillForm({
  formData,
  val,
  token,
  formState,
  submit,
  saleOrderID,
  loading: deleteLoading,
  changeState,
  setDisable
}) {
  const dispatch = useDispatch();
  const {
    systemLog,
    accountPayable,
    billFormData,
    expenseBill,
    ProductSale,
    invoiceGlPeriod,
    currency,
    companyAll,
    adminLocation,
    ShipVia,
    glImpact,
    brand,
  } = useSelector((state) => state);
  const companyId = ReadCookie("defaultCompany");
  const menuId = ReadCookie("menuId");
  const [form] = Form.useForm();
  const submitButton = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [isExpenseModalVisible, setIsExpenseModalVisible] = useState(false);
  const [visibleExpenseItems, setVisibleExpenseItems] = useState([]);
  const [expenseItems, setExpenseItems] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment().format("MMM YYYY"));
  const [oDate, setOrderDate] = useState(moment());
  const [dDate, setDeliveryDate] = useState(moment());
  const [adjustmentLocation, setAadjustmentLocation] = useState(null);
  const [headerNo] = useState(ReadCookie("defaultCompany"));
  const [deletedItems, setDeletedItems] = useState([]);
  const [deletedExpensesItems, setDeletedExpenseItems] = useState([]);
  const [itemIndex, setItemIndex] = useState(null);
  const [getLineRecord, setGetLineRecord] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [singleExpenseLine, setSingleExpenseLine] = useState({});
  const [mode, setMode] = useState(null);
  const [formDataValues, setFormDataValues] = useState({});
  const [toogle, setToogle] = useState(null);
  const [adjLoc, setadjLoc] = useState(null);
  const [check, setCheck] = useState(false);

  const handleDTransaction = (e) => {
    dispatch(getTransactionNo(token, e.target.value)).then((res) => {
      form.setFieldsValue({ dAmount: res?.payload?.data[0]?.gross_amount });
    });
  };

  const handleFTransaction = (e) => {
    dispatch(getTransactionNo(token, e.target.value)).then((res) => {
      form.setFieldsValue({ fAmount: res?.payload?.data[0]?.gross_amount });
    });
  };

  useLayoutEffect(() => {
    dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
      let result = res?.payload?.find((item) => {
        return item.period_name === currentDate;
      });
      setCurrentDate(result?.period_id);
      form.setFieldsValue({ postingPeriod: result?.period_id });
    });
  }, [token, dispatch]);

  useEffect(() => {
    setLoading(deleteLoading);
  }, [deleteLoading]);

  useEffect(() => {
    dispatch(getChartofAccount(token));
    dispatch(getAdminCurrency(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getShipVia(token));
    dispatch(getAdminLocation(token));
    dispatch(getAccountPayable(token));
    dispatch(getBrands(token));
  }, []);

  useEffect(() => {
    if (formState === "add") {
      const initialValues = {
        billHeaderno: null,
        account: null,
        approveStatus: "A",
        billDate: oDate,
        dileveryDate: dDate,
        memo: null,
        postingPeriod: currentDate,
        referenceNo: null,
        fTransactionNo: null,
        dTransactionNo: null,
        shipVia: 3,
        vendor: null,
        subsidiary: companyID,
        adjustmentLocation: 1,
        currency: defaultCurrency,
        brand_id: null,
      }
      form.setFieldsValue(initialValues);
      setOrderItems([]);
      setExpenseItems([]);
      setFormDataValues(initialValues)
    }
  }, [formState]);

  useEffect(() => {
    if (formState === "formView") {
      setLoading(true);
      dispatch(getOneBillLineDetail(token, saleOrderID)).then((res) => {
        if (res.type === "GET_ONE_BILL_LINE_DETAIL_SUCCESS") {
          setOrderItems(res.payload.data);
          setLoading(false);
        }
      });
      dispatch(getOneExpenseLineDetail(token, saleOrderID)).then((res) => {
        if (res.type === "GET_ONE_EXPENSE_LINE_DETAIL_SUCCESS") {
          setExpenseItems(res.payload.data);
          setLoading(false);
        }
      });
      dispatch(
        getGLImpact(token, {
          transaction_header_id: saleOrderID,
          transaction_type: "BILL",
        })
      );
      dispatch(
        getSystemLog(token, {
          table_name: "pay_purchase_bill_header",
          record_id: saleOrderID,
        })
      );
    }
  }, [formState]);

  useEffect(() => {
    if (formData) {
      setLoading(false);
      const initialValues = {
        approveStatus: formData?.is_approved,
        account: formData.account_id,
        billDate: moment(formData?.bill_date),
        dileveryDate:
          formData?.delivery_date == null
            ? dDate
            : moment(formData?.delivery_date),
        postingPeriod: formData.gl_period_id,
        vendor: formData.vendor_id,
        shipVia: formData.ship_via,
        referenceNo: formData.reference_no,
        fTransactionNo: formData.f_transaction,
        dTransactionNo: formData.d_transaction,
        fAmount: formData?.f_amount,
        dAmount: formData?.d_amount,
        memo: formData.memo,
        billHeaderno: formData.bill_header_no,
        currency: defaultCurrency,
        adjustmentLocation: formData.location_id,
        subsidiary: formData.company_id,
        brand_id: formData.brand_id,
      };
      form.setFieldsValue(initialValues);
      setFormDataValues(initialValues)
      setadjLoc(formData?.location_id);
    }
  }, [formData]);

  useEffect(() => {
    let filtered = orderItems.filter((f) => f.isSelected === true);
    setVisibleItems(filtered);
  }, [orderItems]);

  useEffect(() => {
    let filtered = expenseItems.filter((f) => f.isSelected === true);
    setVisibleExpenseItems(filtered);
  }, [expenseItems]);

  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  const runProcedure = () => {
    dispatch(
      updateTransferRate(
        token,
        formData?.bill_header_id
      )
    ).then(() => {
      notification("success", "Rate Updated Successfully");
    })
  };

  const handleDeleteOrder = (record) => {
    const dataSource = [...orderItems];
    const deleteSource = [...deletedItems];
    const deleteEditableItem = [...filteredItems];
    deleteSource.push(record);
    setDeletedItems(deleteSource);
    setOrderItems(dataSource.filter((item) => item.item_id !== record.item_id));
    setFilteredItems(
      deleteEditableItem.filter((item) => item.item_id !== record.item_id)
    );
    notification("success", "Item Deleted");
  };

  const handleDeleteExpenseOrder = (record, index) => {
    const dataSource = [...expenseItems];
    const deleteDataSource = [...deletedExpensesItems];
    if (record.bill_item_id) {
      deleteDataSource.push(record);
      setDeletedExpenseItems(deleteDataSource);
    }
    setExpenseItems(dataSource.filter((item, i) => i !== index));
  };

  function handleChange(value, type) {
    if (type === "company") {
      companyId(value);
      form.setFieldsValue({ company: value });
    }
  }

  function handleChangeAdjustmentLocation(value, type) {
    setAadjustmentLocation(value);
    if (type === "company") {
      form.setFieldsValue({ company: value });
    }
  }

  const onFinish = async (e) => {
    submit(false);
    setCheck(true);
    await form.validateFields().then((values) => {
      setLoading(true);
      setDisable(true);
      if (formState === "add") {
        if (orderItems.length > 0 || expenseItems.length > 0) {
          dispatch(addBillHeader(token, values, parseInt(companyId))).then(
            (res) => {
              let id = res?.payload?.bill_header_id;
              if (res.type === "ADD_BILL_HEADER_SUCCESS") {
                dispatch(
                  addBillLine(
                    token,
                    orderItems.map((item) => {
                      let amountt = item.amount * item.quantity;
                      delete item.style_name;
                      delete item.cut_name;
                      delete item.fabric_name;
                      delete item.gender_category_name;
                      delete item.gender_name;
                      delete item.inseam;
                      delete item.isSelected;
                      delete item.already;
                      delete item.quantity_available;
                      delete item.quantity_backorder;
                      delete item.quantity_committed;
                      delete item.quantity_on_hand;
                      delete item.size;
                      delete item.wash_name;
                      delete item.amount;
                      delete item.quantity_packed;
                      delete item.quantity_shipped;
                      delete item.quantity_billed;
                      delete item.commit_status;
                      delete item.tax_amount;
                      delete item.discount;
                      delete item.discount_percent;
                      delete item.tax_percent;
                      delete item.net_amount;
                      delete item.brand_name;
                      delete item.brand_id;
                      return {
                        gross_amount: amountt,
                        bill_header_id: res.payload.bill_header_id,
                        ...item,
                      };
                    })
                  )
                ).then((resline) => {
                  if (resline.type === "ADD_BILL_LINE_SUCCESS") {
                    dispatch(
                      addExpenseLine(
                        token,
                        expenseItems.map((item) => {
                          delete item.account_no;
                          delete item.full_name;
                          delete item.quantity;
                          delete item.key;
                          delete item.isSelected;
                          return {
                            ...item,
                            bill_header_id: res.payload.bill_header_id,
                          };
                        })
                      )
                    ).then((res) => {
                      if (res.type === "ADD_EXPENSE_LINE_SUCCESS") {
                        notification(
                          "success",
                          "Bill",
                          "Bill created successfully!"
                        );
                        setLoading(false);
                        setDisable(false);
                        history.push({
                          pathname: `/bill/edit/${id}`,
                          search: `?_id=${id}`,
                        });
                      } else if (res.type === "ADD_EXPENSE_LINE_ERROR") {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: res?.payload?.data?.data,
                        });
                      }
                    });
                  } else if (res.type === "ADD_BILL_LINE_ERROR") {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: res?.payload?.data?.data,
                    });
                  }
                });
              } else if (res.type === "ADD_BILL_HEADER_ERROR") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res?.payload?.data?.data,
                });
              }
            }
          );
        } else {
          setLoading(false);
          setDisable(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "select atleast one Bill line",
          });
          return;
        }
      } else if (formState === "edit" && check) {
        if (deletedItems.length > 0) {
          dispatch(deleteBillLine(token, saleOrderID, deletedItems));
        }
        if (deletedExpensesItems.length > 0) {
          dispatch(deleteExpenseLine(token, saleOrderID, deletedExpensesItems));
        }
        dispatch(
          updateBillHeader(token, values, saleOrderID, parseInt(companyId))
        ).then((res) => {
          if (res.type === "UPDATE_BILL_HEADER_SUCCESS") {
            dispatch(
              updateBillLine(
                token,
                orderItems.map((item) => {
                  delete item.style_name;
                  delete item.cut_name;
                  delete item.fabric_name;
                  delete item.gender_category_name;
                  delete item.gender_name;
                  delete item.inseam;
                  delete item.isSelected;
                  delete item.quantity_available;
                  delete item.quantity_backorder;
                  delete item.quantity_committed;
                  delete item.quantity_on_hand;
                  delete item.size;
                  delete item.commit_status;
                  delete item.tax_amount;
                  delete item.discount;
                  delete item.discount_percent;
                  delete item.tax_percent;
                  delete item.net_amount;
                  delete item.already;
                  delete item.wash_name;
                  delete item.amount;
                  delete item.item_code;
                  delete item.quantity_packed;
                  delete item.quantity_shipped;
                  delete item.quantity_billed;
                  delete item.brand_name;
                  delete item.brand_id;
                  return {
                    ...item,
                    bill_header_id: saleOrderID,
                    gross_amount: Number(item.rate) * Number(item.quantity),
                  };
                }),
                saleOrderID
              )
            ).then((resline) => {
              if (resline.type === "UPDATE_BILL_LINE_SUCCESS") {
                dispatch(
                  updateExpenseLine(
                    token,
                    expenseItems.map((items) => {
                      return { ...items, bill_header_id: saleOrderID };
                    }),
                    saleOrderID
                  )
                ).then((res) => {
                  if (res.type === "UPDATE_EXPENSE_LINE_SUCCESS") {
                    setLoading(false);
                    setDisable(false);
                    notification("success", "Bill Updated");
                    changeState("formView");
                  } else if (res.type === "UPDATE_EXPENSE_LINE_ERROR") {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: res?.payload?.data?.data,
                    });
                  }
                });
              } else if (res.type === "UPDATE_BILL_LINE_ERROR") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res?.payload?.data?.data,
                });
              }
            });
          } else if (res.type === "UPDATE_BILL_HEADER_ERROR") {
            setLoading(false);
            setDisable(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res?.payload?.data?.data,
            });
          }
        });
      }
    });
  };

  const handleTab = (key) => {
    if (key === "header") {
      dispatch(
        getSystemLog(token, {
          table_name: "pay_purchase_bill_header",
          record_id: saleOrderID,
        })
      );
    } else if (key === "line") {
      dispatch(
        getSystemLog(token, {
          table_name: "pay_purchase_bill_lines",
          record_id: saleOrderID,
        })
      );
    }
  };

  const showModal = () => {
    if (form.getFieldValue().adjustmentLocation === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select Location",
      });
    } else if (!formDataValues.brand_id) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select Brand.",
      });
    } else {
      setIsModalVisible(true);
    }
  };

  const showEditModal = (record, index) => {
    setGetLineRecord(record);
  };

  const showExpenseModal = () => {
    setIsExpenseModalVisible(true);
    setMode("Add");
  };

  const addedItems = (value) => {
    const fillArr = [...orderItems];
    const onlyKey = [...filteredItems].map((sItem) => sItem.item_code);
    const indexofArr = fillArr.findIndex((f) => {
      return onlyKey.find((i) => i === f.item_code);
    });
    fillArr.splice(indexofArr, 1);
    if (orderItems.length > 0) {
      let uniqueOrderItems = [...orderItems, ...filteredItems];
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);
    } else {
      let uniqueOrderItems = [...visibleItems, ...filteredItems];
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);
    }
    notification("success", "Item Added Successfully");
  };

  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errors[0]?.errors[0],
    });
    setDisable(false);
  };

  const updateCostPrice = (type, item_code, value, index) => {
    setToogle(true);
  };

  const updateQuantity = (type, itemCode, value, p_qty, index) => {
    setToogle(true);
  };

  const updateCommitt = (
    p_qty,
    p_avail,
    p_shipped,
    p_adjust,
    p_commit_status,
    index
  ) => {
    let copyArr = [...orderItems];
    let balance_qty =
      p_qty -
      (p_shipped === null || p_shipped === undefined ? 0 : p_shipped) -
      (p_adjust === null || p_adjust === undefined ? 0 : p_adjust);
    if (balance_qty > 0) {
      if (p_commit_status === 1) {
        if (p_avail > balance_qty) {
          copyArr[index].quantity_committed =
            balance_qty + copyArr[index].quantity_committed;
          copyArr[index].quantity_backorder = 0;
          copyArr[index].commit_status = p_commit_status;
        } else {
          copyArr[index].quantity_committed = p_avail;
          copyArr[index].commit_status = p_commit_status;
          copyArr[index].quantity_backorder = balance_qty - p_avail;
        }
      } else if (p_commit_status === 3) {
        let packedShipped =
          copyArr[index].quantity_packed - copyArr[index].quantity_shipped;
        let totalCommitQty = copyArr[index].quantity_committed - packedShipped;
        copyArr[index].quantity_committed = packedShipped;
        copyArr[index].quantity_backorder = totalCommitQty;
        copyArr[index].commit_status = p_commit_status;
      }
      setOrderItems(copyArr);
    } else {

    }
  };

  const glImpcatCols = [
    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      render: (text, record, index) => {
        return <>{currencySymbol} {numeral(record.debit).format("0,0.00")}</>;
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      render: (text, record, index) => {
        return <>{currencySymbol} {numeral(record.credit).format("0,0.00")}</>;
      },
    },

    {
      title: "created_date",
      dataIndex: "created_date",
    },
  ];

  const columns = [
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Style",
      dataIndex: "style_name",
    },

    {
      title: "Wash",
      dataIndex: "wash_name",
      editable: true,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      width: 80,
      render: (text, record, index) => {
        return (
          <>
            <InputNumber
              size="small"
              value={record.rate}
              onChange={(value) => {
                updateCostPrice(
                  "up",
                  record.item_code,
                  value,
                  orderItems.findIndex((v) => v.item_code === record.item_code)
                );
                updateCommitt(record.quantity);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 80,
      editable: true,
      render: (text, record, index) => {
        return (
          <>
            <InputNumber
              size="small"
              value={record.quantity}
              onChange={(value) => {
                updateCommitt(record.quantity);
                updateQuantity(
                  "up",
                  record.item_code,
                  value,
                  record.quantity_committed,
                  orderItems.findIndex((v) => v.item_code === record.item_code)
                );
              }}
            />
          </>
        );
      },
    },
    {
      title: "Amount",
      width: 80,
      render: (_, record, index) => {
        return numeral(record.quantity * record.rate).format("0,0.00");
      },
    },
    {
      title: "Operation",
      width: 80,
      dataIndex: "operation",
      render: (_, record, index) => (
        <Tooltip title="Delete" color={"white"}>
          {formState === "formView" ? (
            ""
          ) : (
            <>
              <Popconfirm
                title={`Are you sure to delete the item ${record.item_id}?`}
                onConfirm={() => handleDeleteOrder(record)}
              >
                <DeleteOutlined className={`delete-Button`} />
              </Popconfirm>
            </>
          )}
        </Tooltip>
      ),
    },
  ];

  const expenseColumns = [
    {
      title: "Account Id",
      dataIndex: "account_id",
    },
    {
      title: "Account No",
      dataIndex: "account_no",
    },
    {
      title: "Full Name",
      dataIndex: "full_name",
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: true,
      render: (_, record, index) => {
        return (
          <>
            {numeral(record.amount).format("00.00")}
          </>
        );
      },
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record, index) => (
        <Tooltip title="Delete" color={"white"}>
          {formState === "formView" ? (
            ""
          ) : (
            <Popconfirm
              title={`Are you sure to delete the expense ${record.account_id}?`}
              onConfirm={() => handleDeleteExpenseOrder(record, index)}
            >
              <DeleteOutlined className="delete-Button" />
            </Popconfirm>
          )}
          {formState === "formView" ? (
            ""
          ) : (
            <EditOutlined
              className={`edit-Button`}
              onClick={() => {
                showEditModal(record, index);
                setItemIndex(index);
                setSingleExpenseLine(record);
                setIsExpenseModalVisible(true);
                setMode("Edit");
              }}

            />
          )}
        </Tooltip>
      ),
    },
  ];

  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0]);
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      };
    });
  };

  function checkTransactionDate(e) {
    let transaction_date = moment(e).format("YYYY-MM-DD")
    setLoading(true)
    dispatch(getTransationLock(token, transaction_date)).then((r) => {
      setLoading(false)
      if (r.payload.data.length > 0) {
        setOrderDate(moment())
        form.setFieldsValue({
          billDate: moment()
        })
        Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
      }
      else {
        setOrderDate(moment(e))
      }
    });
  }

  return (
    <>
      <EditableTable
        companyId={companyId}
        token={token}
        headerNo={headerNo}
        modalValue={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setOrderItems={addedItems}
        orderItems={orderItems}
        brandId={formDataValues?.brand_id}
        adjustmentLocation={formDataValues?.adjustmentLocation}
        setFilteredItems={setFilteredItems}
        adjLoc={adjLoc}
        useV2
      />
      <BillEditExpense
        token={token}
        companyId={companyId}
        mode={mode}
        modalValue={isExpenseModalVisible}
        setIsModalVisible={setIsExpenseModalVisible}
        itemIndex={itemIndex}
        orderItems={expenseItems}
        setOrderItems={setExpenseItems}
        singleExpenseLine={singleExpenseLine}
        setSingleExpenseLine={setSingleExpenseLine}
      />
      <Form
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        onValuesChange={(values, fields) => {
          if (formDataValues?.brand_id !== values?.brand_id && formState === 'add') {
            setOrderItems([])
          }
          setFormDataValues({ ...fields })
        }}
        name="basic"
      >
        {billFormData.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))
        }
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
            <Brow>
              <Bcol md={12} xl={12} xxl={10} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="billHeaderno"
                        label="Bill Header No"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          tabIndex={1}
                          size="small"
                          className="customInputs"
                          disabled={!(formState === "edit")}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Bill Date"
                        name="billDate"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        rules={[
                          { required: true, message: "Bill Date is required" },
                        ]}
                      >
                        <DatePicker
                          size="small"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          format="MM-DD-YYYY"

                          allowClear
                          className="dateInput customInputs"
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().billDate
                              : moment(oDate)
                          }
                          onChange={(e) => {
                            setOrderDate(moment(e));
                            let result = invoiceGlPeriod?.payload?.find(
                              (item) => {
                                return (
                                  item.period_name ===
                                  moment(e).format("MMM YYYY")
                                );
                              }
                            );
                            setCurrentDate(result?.period_id);
                            form.setFieldsValue({
                              postingPeriod: result?.period_id,
                            });
                            checkTransactionDate(e);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="account"
                        label="A/P Account"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        rules={[
                          { required: true, message: "Account is required" },
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          size="small"
                          className="styleInput"
                          loading={accountPayable.loading}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          optionFilterProp="children"
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {accountPayable?.payload?.data?.map((acc) => {
                            return (
                              <Option
                                key={acc.account_id}
                                value={acc.account_id}
                              >
                                {acc.account_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Posting Period"
                        name="postingPeriod"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().postingPeriod}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          loading={invoiceGlPeriod.loading}
                          defaultValue={form.getFieldValue().postingPeriod}

                          onChange={(value) => {
                            form.setFieldsValue({
                              postingPeriod: value,
                            });
                          }}
                        >
                          {invoiceGlPeriod?.payload?.map((item) => {
                            return (
                              <Option
                                key={item.period_id}
                                value={item.period_id}
                              >
                                {item.period_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="currency"
                        label="Currency"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Select
                          showSearch
                          allowClear
                          size="small"
                          className="styleInput"
                          disabled={true}
                          optionFilterProp="children"
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {currency &&
                            currency?.payload?.data?.map((acc) => {
                              return (
                                <Option key={acc.currency_id} value={acc.currency_id}>
                                  {acc.currency_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="vendor"
                        label="Vendor"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        rules={[
                          { required: true, message: "Vendor is required" },
                        ]}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          allowClear
                          loading={billFormData?.loading}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form.getFieldValue().vendor}

                          onChange={(value) => handleChange(value)}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {billFormData?.payload?.vendor.map((item) => {
                            return (
                              <Option
                                key={item.vendor_id}
                                value={item.vendor_id}
                              >
                                {item.vendor_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="shipVia"
                        label="Transit Type"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) =>
                            option?.children
                              ?.toLowerCase()
                              .indexOf(input?.toLowerCase()) >= 0
                          }
                          showSearch
                          allowClear
                          loading={ShipVia?.loading}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form?.getFieldValue().shipVia}
                          onChange={(value) => handleChange(value)}
                        >
                          {ShipVia?.payload?.data.map((item) => {
                            return (
                              <Option
                                key={item.ship_via_id}
                                value={item.ship_via_id}
                              >
                                {item.ship_via_description}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="approveStatus"
                        label="Approve Status"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          allowClear
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form.getFieldValue().approveStatus}
                          onChange={(value) => handleChange(value)}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          <Option key={1} value={"A"} id={11}>
                            Approved
                          </Option>
                          <Option key={2} value={"P"}>
                            Pending
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="referenceNo"
                        label="Reference No"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          tabIndex={1}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="memo"
                        label="Memo"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          tabIndex={1}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="adjustmentLocation"
                        label="Location:"
                        labelAlign="left"
                        labelCol={{
                          span: 5,
                        }}

                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          allowClear
                          tabIndex={18}
                          loading={adminLocation.loading}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form.getFieldValue().adjustmentLocation}
                          onChange={(value) =>
                            handleChangeAdjustmentLocation(value)
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          { }
                          {adminLocation?.payload?.data?.map((item) => {
                            return (
                              <Option
                                key={item.location_id}
                                value={item.location_id}
                              >
                                {item.location_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Delivery Date"
                        name="dileveryDate"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        rules={[
                          {
                            required: true,
                            message: "Delivery Date is required",
                          },
                        ]}
                      >
                        <DatePicker
                          size="small"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          format="MM-DD-YYYY"

                          allowClear
                          className="dateInput customInputs"
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().dileveryDate
                              : moment(oDate)
                          }
                          onChange={(e) => setDeliveryDate(moment(e))}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                </Brow>
                <Brow>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="fTransactionNo"
                        label="F Transaction #"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          tabIndex={1}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          onChange={handleFTransaction}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="dTransactionNo"
                        label="D Transaction #"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          tabIndex={1}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          onChange={handleDTransaction}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="fAmount"
                        label="F Amount"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().fAmount}
                      >
                        <Input
                          tabIndex={1}
                          size="small"
                          className="customInputs"
                          disabled={
                            formState === "add" ||
                            formState === "edit" ||
                            formState === "formView"
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="dAmount"
                        label="D Amount"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().dAmount}
                      >
                        <Input
                          tabIndex={1}
                          size="small"
                          className="customInputs"
                          disabled={
                            formState === "add" ||
                            formState === "edit" ||
                            formState === "formView"
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  {/* Brand Field */}
                  <Bcol lg={6} xxl={4}>
                    <div className="customCol slc">
                      <div className="relative">
                        <Form.Item rules={[{ required: true, message: "Brand is required" }]} name="brand_id" label="Brand" labelAlign="left" labelCol={{ span: 5 }} wrapperCol={{ span: 20 }}>
                          <Select size="small" showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())} loading={brand.loading} className="w-100"
                            disabled={!(formState === "add" || formState === "edit")}
                          >
                            {" "}
                            {brand?.payload?.data?.map((res) => (
                              <Option key={res.brand_id} value={res.brand_id}>
                                {res.brand_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Bcol>
                </Brow>
              </Bcol>
              <Bcol md={4} xl={3} xxl={2}>
                <Summary
                  orderItems={orderItems.length > 0 ? orderItems : visibleItems}
                  shippingCost={
                    form.getFieldValue().shippingCost
                      ? form.getFieldValue().shippingCost
                      : 0
                  }
                  expenseItems={expenseItems.length > 0 ? expenseItems : 0}
                />
                <Button
                  type="primary"
                  className="modalButton"
                  disabled={(formState === "add" || formState === "view") || formState === "formView"}
                  onClick={runProcedure}
                >
                  Update Transfer Rate
                </Button>
              </Bcol>
            </Brow>
            <Button
              htmlType="submit"
              style={{ display: "none" }}
              ref={submitButton}
              onClick={onFinish}
            />
          </div>
          <SubTitles name={"Classification"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
            <Bcol md={12} xl={9} xxl={10}>
              <Brow>
                <Bcol lg={6} xxl={4}>
                  <div className="firstSectionRo">
                    <Form.Item
                      name="subsidiary"
                      label="Subsidiary"
                      labelAlign="left"
                      labelCol={{ span: 5 }}

                    >
                      <Select
                        size="small"
                        autoComplete={"nope"}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        allowClear
                        disabled={true}
                        loading={companyAll.loading}

                        defaultValue={form.getFieldValue().subsidiary}

                        onChange={(value) => handleChange(value)}
                      >
                        {companyAll?.payload?.data.map((item) => {
                          return (
                            <Option
                              key={item.company_id}
                              value={item.company_id}
                            >
                              {item.company_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Bcol>
              </Brow>
            </Bcol>
          </div>
          <Tabs
            type="card"
            size="small"
            defaultActiveKey="1"
            centered
            onChange={(key) => handleTab(key)}
          >
            <TabPane tab="Items" key="1">
              <div style={{ display: "flex", gap: 10 }} className="pbpx-20">
                <Button
                  type="primary"
                  className="modalButton"
                  disabled={!(formState === "edit" || formState === "add")}
                  onClick={showModal}
                >
                  Add Item
                </Button>
                {orderItems.length > 0 ? (
                  <CSVLink
                    style={{
                      border: "1px solid black",
                      padding: "3px 15px 3px 15px",
                      borderRadius: 5,
                    }}
                    data={orderItems.map((v, i) => {
                      return {
                        item_code: `${v.item_code} -`,
                        style_name: v.style_name,
                        wash_name: v.wash_name,
                        rate: v.rate,
                        quantity: v.quantity,
                        Amount: v.quantity * v.rate,
                      };
                    })}
                  >
                    Export to Excel
                  </CSVLink>
                ) : null}
              </div>
              {(orderItems.length > 0 || visibleItems.length > 0) && (
                <Table
                  type={"company"}
                  loading={ProductSale.loading}
                  size="small"
                  bordered
                  dataSource={orderItems.length > 0 ? orderItems : []}
                  columns={columns}
                  paginate
                  perPage={15}
                />
              )}
            </TabPane>
            <TabPane tab="Expenses" key="bill">
              <div className="pbpx-20">
                <Button
                  type="primary"
                  className="modalButton"
                  disabled={!(formState === "edit" || formState === "add")}
                  onClick={showExpenseModal}
                >
                  Add Expense
                </Button>
              </div>
              {(expenseItems.length > 0 || visibleExpenseItems.length > 0) && (
                <Table
                  type={"company"}
                  loading={expenseBill.loading}
                  scroll={({ x: 600 }, { y: 400 })}
                  size="small"
                  bordered
                  dataSource={JSON.parse(JSON.stringify(expenseItems))}
                  columns={expenseColumns}
                  pagination={false}
                />
              )}
            </TabPane>
            {(formState === "edit" || formState === "formView") && (
              <TabPane tab="GL Impact" key="gl_impact">
                <div className="tabs">
                  <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={glImpact?.payload?.data}
                    col={glImpcatCols}
                    load={glImpact.loading}
                    paginate
                  />
                </div>
              </TabPane>
            )}
            {(formState === "edit" || formState === "formView") && (
              <TabPane tab="System Information" key="logs">
                <Tabs
                  type="card"
                  size="small"
                  defaultActiveKey="1"
                  centered
                  onChange={(key) => handleTab(key)}
                >
                  <TabPane tab="Header" key="header">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && (
                        <Tables
                          type="company"
                          scrollX={"50rem"}
                          scroll={360}
                          perPage={15}
                          source={systemLog?.payload?.data}
                          dataSource={[]}
                          col={renderColumns()}
                          load={systemLog.loading}
                          paginate
                        />
                      )}
                    </div>
                  </TabPane>
                  <TabPane tab="Line" key="line">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && (
                        <Tables
                          type="company"
                          scrollX={"50rem"}
                          scroll={360}
                          perPage={15}
                          source={systemLog?.payload?.data}
                          dataSource={[]}
                          col={renderColumns()}
                          load={systemLog.loading}
                          paginate
                        />
                      )}
                    </div>
                  </TabPane>
                </Tabs>
              </TabPane>
            )}
          </Tabs>
        </div>
      </Form>
    </>
  );
}
