import {
  GET_ALL_PT_SUMMARY_REQUEST,
  GET_ALL_PT_SUMMARY_SUCCESS,
  GET_ALL_PT_SUMMARY_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getPtSummary = (token, values) => (dispatch) => {
  let cleanedVal = values.customer === undefined ? null : values.customer.includes("'") ? values.customer.replace(/'/g, "''") : values.customer;
  let cleanedVal1 = values.billing_addressee === undefined ? null : values.billing_addressee.includes("'") ? values.billing_addressee.replace(/'/g, "''") : values.billing_addressee;
  dispatch({ type: GET_ALL_PT_SUMMARY_REQUEST });
  const newData = {
    ship_header_no: values.ship_no === undefined || values.ship_no === "" ? null : values.ship_no,
    order_header_no: values.order_no === undefined || values.order_no === "" ? null : values.order_no,
    date_from: moment(values.from_date).format("MM-DD-YYYY"),
    date_to: moment(values.to_date).format("MM-DD-YYYY"),
    reference_no: values.po_no === undefined || values.po_no === "" ? null : values.po_no,
    customer: cleanedVal === undefined || cleanedVal === "" ? null : cleanedVal,
    bill_addressee: cleanedVal1 === undefined || cleanedVal1 === "" ? null : cleanedVal1,
    sp_id: values.sale_person === "All" ? null : values.sale_person,
    region_id: values.region === "All" ? null : values.region,
    gender_id: values.gender === "All" ? null : values.gender,
    brand_id: values.brand_id === "All" ? null : values.brand_id,
    payment_term_id: values.terms === "All" ? null : values.terms,
    company_id: values.subsidary === "All" ? null : values.subsidary,
    pt_status_id: values.status === undefined || values.status === "All" ? null : values.status,
    ship_status_id: values.ship_status === undefined || values.ship_status === "All" ? null : values.ship_status,
    customer_type_id: values.cutomer_category === undefined || values.cutomer_category === "All" ? null : values.cutomer_category,
    location_id: values.location === undefined || values.location === "All" ? null : values.location
  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/PTsummary`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_PT_SUMMARY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_PT_SUMMARY_ERROR,
        payload: [],
      });
    });
};
