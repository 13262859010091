import {
  GET_DL_BULK_REQUEST,
  GET_DL_BULK_SUCCESS,
  GET_DL_BULK_ERROR
} from "../actions/types";

export const dlBulk = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DL_BULK_REQUEST:
      return {
        loading: true,
      };
    case GET_DL_BULK_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_DL_BULK_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};
