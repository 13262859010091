import React, { useState } from 'react'
import logo from "../../assets/images/logo.png"
import btnPrint from "../../assets/images/button_print.gif"
import xlsx from "../../assets/images/ico_xls_sm.gif"
import "./styleSheet.css"
import { Select, DatePicker, Form, Table, Spin, Button, Input } from 'antd';
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { useEffect } from 'react'
import { getRegion } from '../../redux/actions/region.action'
import { getPaymentTerm } from '../../redux/actions/paymentterm.action'
import { getGender } from '../../redux/actions/gender.action'
import { getOmsReport } from '../../redux/actions/omsReport.action'
import { getAllCompany } from '../../redux/actions/companyAccess'
import { Link, useParams, useLocation } from 'react-router-dom'
import Tables from '../../components/Table/Tables'
import { CSVLink } from "react-csv";
import { Excel } from "antd-table-saveas-excel";
import { getPaymentStateReport } from '../../redux/actions/paymentStateReport.action'
import { useLayoutEffect } from 'react'
import { currencySymbol } from '../../utils/constants'

const { Option } = Select;
const PaymentStateReport = ({ token, id, propsData, formData }) => {
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const paramsData = useParams();

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    
    
    const [data, setData] = useState([])
    const { gender, region, companyAll, paymentStateReport } = useSelector((state) => state);
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(null)


    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
 

    let query = useQuery();
    let check = query.get("submited")
    let company_id = query.get("company_id")
    let payment_header_id = query.get("payment_header_id")
    let date_type = query.get("date_type")
    let gender_id = query.get("gender_id")
    let term_id = query.get("term_id")
    let from_date = query.get("from_date")
    let to_date = query.get("to_date")
    let region_id = query.get("region_id")
    let selected_Region = query.get("selected_Region")
    let selected_id = query.get("selected_id")
    let select = query.get("select")
    let state_name = query.get("state_name")
    let region_name = query.get("region_name")

    useLayoutEffect(() => {
        setLoading(true)
        dispatch(getPaymentStateReport(token, id, { payment_header_id })).then((res) => {
            setSubmit(true)
            setLoading(false)
        });

    }, []);

    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId))
        dispatch(getAllCompany(token));
    }, [dispatch, menuId, token])

    const columns = [
        {
            title: "#",
            dataIndex: "serial_no",
            align: 'left',
            key: 'serial_no',
            width: 30,
            render: (text, object, index) => index + 1
        },
        {
            title: 'Header No',
            dataIndex: 'invoice_header_no',
            key: 'invoice_header_no',
            width: 70,
            align: "right"

        },
        {
            title: 'Date',
            dataIndex: 'payment_date',
            key: 'payment_date',
            width: 70,
            align: "right"

        },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: 260,
            render: (record, text) => {
                return <Link style={{ color: "blue", textDecoration: "underline" }}
                    to={{
                        pathname: `/payment`,
                        search: `?submited=${true}&report=${3}&company_id=${company_id}&date_type=${date_type}&gender_id=${gender_id}&region_id=${region_id}&term_id=${term_id}&from_date=${moment(from_date).format("MM-DD-YYYY")}&to_date=${moment(to_date).format("MM-DD-YYYY")}&select=${true}&selected_id=${selected_id}&state_id=${text.state_id}&state_name=${text.state_name}&region_name=${text.region_name}`,

                    }}
                > {record}</ Link >
            }
        },
      
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> {text}</span >,

        },
        
        
        
        
        
        

        
    ];

    const filterGender = gender.payload?.data.find((item) => {
        return item.gender_id === Number(gender_id);
    })

    const filterRegion = region.payload?.data.find((item) => {
        return item.region_id === Number(selected_id);
    })


    return (
        <>

            <div className='row'>
                <div className='col'>
                    <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls">
                        <tbody>
                            <tr>
                                <td width="103">
                                    Featuring:
                                </td>
                                <td>
                                    <b>
                                        {gender_id === "All" ? "All" : filterGender && filterGender.gender_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    Payment Term:
                                </td>
                                <td>
                                    <b>
                                        {term_id === "0" ? "Without FOC" : "FOC"}
                                    </b>
                                </td>
                                <td style={{ width: "20px" }} className="no-border-btm" >
                                    Region:
                                </td>
                                <td>
                                    <b>
                                        {filterRegion && filterRegion.region_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    From:
                                </td>
                                <td>
                                    <b>
                                        {from_date}
                                    </b>
                                </td>
                                <td style={{ width: "60px" }} className="no-border-btm" >
                                    To:
                                </td>
                                <td>
                                    <b>
                                        {to_date}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "80px" }}>
                                    Show Orders By:
                                </td>
                                <td>
                                    <b>
                                        {date_type === "B" ? "Booking Date" : "Start Date"}
                                    </b>
                                </td>
                                <td className="no-border-btm" >
                                    Brand :
                                </td>
                                <td>
                                    <b>
                                        {company_id === "2" ? "DL1961 : DL1961(USA) " : ""}
                                    </b>
                                </td>
                            </tr>
                        </tbody >
                    </table >
                </div>
            </div>
            {loading ?
                <div className='w-100 d-flex align-items-center justify-content-center spin-style mt-3' >
                    <Spin />
                </div> :
                <>{(submit) ?
                    <div className='w-100 mt-3'>
                        <Table
                            scroll={{ x: 200 }}
                            className='w-100 test04'
                            size='small'
                            pagination={{ pageSize: 3000 }}
                            summary={pageData => {
                                let sumQty = pageData.reduce(function (previousValue, currentValue) {
                                    
                                }, 0);
                                let sumAmount = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.amount.replace(/\$|,/g, ''))
                                }, 0);
                                return (
                                    <>
                                        <Table.Summary.Row >
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                {/* <p style={{
                                                    fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black",
                                                    borderBottom: "1px solid black"
                                                }}>{sumQty.toLocaleString("en-US")}</p> */}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{currencySymbol} {sumAmount.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                            bordered
                            columns={columns}
                            dataSource={paymentStateReport?.payload?.data}
                        />
                    </div> : <table className="tablestyle" cellSpacing="0" cellPadding="2" align="Center" rules="all" border="1" id="GridView1" style={{
                        fontSize: "8pt", width: "100%", borderCollapse: "collapse", marginTop: "20px"
                    }} >
                        <tbody>
                            <tr style={{
                                backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold"
                            }} >
                                <td colSpan="5">
                                    No record(s)</td>
                            </tr>
                        </tbody>
                    </table>}
                </>
            }


        </>

    )
}
export default PaymentStateReport;
