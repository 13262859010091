import {
	ADD_PURCHASE_BILL_ERROR,
	ADD_PURCHASE_BILL_REQUEST,
	ADD_PURCHASE_BILL_SUCCESS,
	ADD_CREATE_BILL_REQUEST,
	ADD_CREATE_BILL_SUCCESS,
	ADD_CREATE_BILL_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";
import moment from "moment";
import { companyID } from "../../utils/constants.js";

const axios = require("axios");
export const addPurchaseBill = (token, billNumber, dDate, rate, via, LocationId, brand_id) => (dispatch) => {

	dispatch({ type: ADD_PURCHASE_BILL_REQUEST });
	let newData = {
		bill_no: billNumber,
		delivery_date: moment(dDate).format("MM-DD-YYYY"),
		rateType: rate,
		via: via,
		location: LocationId,
		brand_id: brand_id,

	};
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/getBills`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_PURCHASE_BILL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_PURCHASE_BILL_ERROR,
				payload: error.response,
			});
		});
};
export const addCreateBill = (token, data, brand_id) => (dispatch) => {

	dispatch({ type: ADD_CREATE_BILL_REQUEST });
	const newData = {
		brand_id,
		items: data.map((item) => {
			return {
				Product2: item.Product2, ActualSizeQty: item.ActualSizeQty, Rate: item.Rate, amount: item.AMOUNT, account_id: 273, delivery_date: item.delivery_date, bill_date: item.Invoice_Date, reference_no: item.Invoice_ID.toString(), remarks: item.Invoice_ID.toString(), vendor_id: 1575757, currency: 1, ship_via: item.VIA === "AIR" ? 1 : 2, remarks: item.Invoice_ID, f_transaction: item.Invoice_ID, d_transaction: null, is_approved: "A", company_id: companyID, location_id: item.location_id
			};
		})
	}

	return axios({
		method: "POST",
		url: `${BASEURL}create/createBill`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_CREATE_BILL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_CREATE_BILL_ERROR,
				payload: error.response,
			});
		});
};



















































































































































































