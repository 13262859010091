import React, { useEffect, useMemo, useRef } from "react";
import "./SalesReport.css";
import SalesAdjustmentReportHeader from "./SalesAdjustmentReportHeader/SalesAdjustmentReportHeader";

import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import {
  getSalesReportOrderItem,
  getSalesReportOrderPrint,
  getSalesReportColumnValue,
  getSalesReportColumn,
} from "../../redux/actions/salesReport.action";
import {
  getSalesAdjustmentReportItem,
  getSalesAdjustmentReportPrint,
  getSalesAdjustmentReportColumnValue,
  getSalesAdjustmentReportColumn
} from "../../redux/actions/salesAdjustmentReport.action"
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { currencySymbol } from "../../utils/constants";

const SalesAdjustmentReport = () => {
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const {
    saleAdjustmentItem,
    salesAdjustmentPrint,
    saleAdjustmentColumn,
    saleAdjustmentColumnValue,
  } = useSelector((state) => state);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  let order_header_adj_id = query.get("order_header_adj_id")

  const footerData =
    salesAdjustmentPrint && salesAdjustmentPrint?.payload?.data[0];


  let groupValues =
    saleAdjustmentColumnValue &&
    saleAdjustmentColumnValue?.payload?.data.reduce(function (r, a) {
      r[a.gender_category_id] = r[a.gender_category_id] || [];
      r[a.gender_category_id].push(a);
      return r;
    }, Object.create(null));

  const groupedColumnValue = groupValues && Object.values(groupValues);

  const values = {
    
    order_header_adj_id: order_header_adj_id,
  };

  useEffect(() => {
    dispatch(getSalesAdjustmentReportItem(token, values));
    dispatch(getSalesAdjustmentReportPrint(token, values));
    dispatch(getSalesAdjustmentReportColumnValue(token, values));
    dispatch(getSalesAdjustmentReportColumn(token, values));
  }, []);

  const myData = useMemo(() => {
    return saleAdjustmentColumn?.payload?.data.map((it, i) => {
      return {
        column: it,
        detail: groupedColumnValue && groupedColumnValue[i],
      };
    });
  }, [groupedColumnValue, saleAdjustmentColumn]);



  return (
    <div>
      {
        myData ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <table>
              <thead>
                <tr>
                  <td className="footer">
                    <div>
                      <SalesAdjustmentReportHeader />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="content-block">
                      <div className="sales-report-Table-data">
                        {myData?.map((size, index) => {
                          return (
                            <table className="sales-report-table">
                              <tbody>
                                <tr>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "36px",
                                    }}
                                  >
                                    S.No
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "88px",
                                    }}
                                  >
                                    SKU #
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "240px",
                                    }}
                                  >
                                    Style/Desc
                                    <span style={{ marginLeft: "5px" }}>
                                      {size?.column?.gender_category_name}
                                    </span>
                                  </td>
                                  <td
                                    key={index}
                                    className="sales_table_col font_weight text-center pl-0"
                                  >
                                    {size.column.hq23 && size.column.hq23}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq24 && size.column.hq24}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq25 && size.column.hq25}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq26 && size.column.hq26}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq27 && size.column.hq27}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq28 && size.column.hq28}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq29 && size.column.hq29}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq30 && size.column.hq30}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq31 && size.column.hq31}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq32 && size.column.hq32}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq33 && size.column.hq33}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq34 && size.column.hq34}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq35 && size.column.hq35}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq36 && size.column.hq36}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq37 && size.column.hq37}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq38 && size.column.hq38}
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "50px",
                                    }}
                                  >
                                    Quantity
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "50px",
                                    }}
                                  >
                                    Rate
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "80px",
                                    }}
                                  >
                                    Amount
                                  </td>
                                </tr>
                                {/* dummy data */}

                                {size.detail?.map((it, index) => {

                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td
                                        style={{
                                          marginLeft: "0",
                                        }}
                                      >
                                        {it.item_code}
                                      </td>
                                      <td>
                                        {it.description}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q23 && it.q23}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q24 && it.q24}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q25 && it.q25}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q26 && it.q26}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q27 && it.q27}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q28 && it.q28}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q29 && it.q29}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q30 && it.q30}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q31 && it.q31}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q32 && it.q32}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q33 && it.q33}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q34 && it.q34}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q35 && it.q35}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q36 && it.q36}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q37 && it.q37}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q38 && it.q38}
                                      </td>
                                      <td>
                                        {it.item_quantity && it.item_quantity}
                                      </td>
                                      <td className="text-center pl-0">{currencySymbol} {it.rate && it.rate}</td>
                                      <td>{currencySymbol} {it.amount && it.amount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          );
                        })}
                      </div>
                    </div>
                    <table className="sales-report-table-total">
                      <tbody>
                        <tr className="font_weight">
                          <td
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            className="font_weight total_cell"
                          >
                            Total
                          </td>
                          <td
                            className="sum_cell"
                            style={{
                              width: "48px",
                            }}
                          >
                            {footerData && footerData.sum_quantity}
                          </td>
                          <td
                            className="empty_cell"
                            style={{
                              width: "43px",
                            }}
                          ></td>
                          <td
                            className="total_amount"
                            style={{
                              width: "70px",
                            }}
                          >
                            {currencySymbol} {footerData && footerData.sum_gross_amount}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            className="font_weight"
                          >
                            Disc %
                          </td>
                          <td></td>
                          <td></td>
                          <td>0.00 %</td>
                        </tr>
                        <tr>
                          <td
                            className="font_weight"
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                          >
                            Disc Amount
                          </td>
                          <td></td>
                          <td></td>
                          <td>{currencySymbol} {footerData && footerData.discount}</td>
                        </tr>
                        <tr>
                          <td
                            className="font_weight"
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                          >
                            S.Tax Amount
                          </td>
                          <td></td>
                          <td></td>
                          <td>{currencySymbol} {footerData && footerData?.tax_percent}</td>
                        </tr>
                        <tr>
                          <td
                            className="font_weight"
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                          >
                            Net Amount
                          </td>
                          <td></td>
                          <td></td>
                          <td>{currencySymbol} {footerData && footerData.net_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <div>
                  <div className="signature">
                    Authorized Signature
                  </div>
                </div> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="spin">
            <Spin size="large" />
          </div>
        )
      }

    </div>
  );
};

export default SalesAdjustmentReport;
