import { GET_DETAIL_ERROR, GET_DETAIL_REQUEST, GET_DETAIL_SUCCESS, GET_RELATED_TRANSAACTION_REQUEST, GET_RELATED_TRANSACTION_SUCCESS, GET_RELATED_TRANSACTION_ERROR } from "./types"
import { BASEURL } from "../../constant/config.js"
import { companyID } from "../../utils/constants.js";

const axios = require("axios");
export const getProductForm = (token, menu_id, company_id) => (dispatch, getState) => {
    const { productForm } = getState();
    // if data is present in store return promise resolved
    if (productForm?.payload) {
        return Promise.resolve();
    }
    // if data is not present in store then fetch from server
    dispatch({ type: GET_DETAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getDetail`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: { menu_id, company_id: companyID }
    })
        .then(response => {
            return dispatch({ type: GET_DETAIL_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_DETAIL_ERROR,
                payload: error.response,
            });
        })
}

export const getRelatedTransactions = (token, id) => dispatch => {
    dispatch({ type: GET_RELATED_TRANSAACTION_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/itemTransaction`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: { id }
    })
        .then(response => {
            return dispatch({ type: GET_RELATED_TRANSACTION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_RELATED_TRANSACTION_ERROR,
                payload: error.response,
            });
        })
}