import {
    GET_ECOMMERCE_DAILY_REQUEST,
    GET_ECOMMERCE_DAILY_SUCCESS,
    GET_ECOMMERCE_DAILY_ERROR
} from "../actions/types";

export const ecommerceDaily = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ECOMMERCE_DAILY_REQUEST:
            return {
                loading: true,
            };
        case GET_ECOMMERCE_DAILY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ECOMMERCE_DAILY_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default: {
            return state;
        }
    }
};