import React, { useState, useEffect, useMemo, useRef } from "react";

import { Select, DatePicker, Form, Table, Spin, Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getPaymentReport } from "../../redux/actions/payment.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { useLocation, Link, useHistory, useParams } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";

import PaymentStateReport from "./PaymentStateReport"
import OmsRegionReport from "./OmsRegionReport";
import OmsReportByCustomer from "./OmsReportByCustomer";
import moment from "moment";
import { PrinterOutlined } from "@ant-design/icons";
import { useDownloadExcel } from "react-export-table-to-excel";
import ReactToPrint from "react-to-print";
import printJS from "print-js";
import { Excel } from "antd-table-saveas-excel";
import "./styleSheet.css";
import { type } from "@testing-library/user-event/dist/type";
import { header } from "./index";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { currencySymbol } from "../../utils/constants";

const { Option } = Select;
const dateFormatList = ["D/MM/YYYY"];
const Payment = ({ token }) => {
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    gender,
    region,
    companyAll,
    payment,
    regionStateReport,
    paymentStateReport,
    omsReportByCustomer,
  } = useSelector((state) => state);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [propsData, setPropsData] = useState({});
  const [paginatePrint, setPaginatePrint] = useState(false);
  const [excelToogle, setExcelToogle] = useState(false);
  const { submited } = useParams();

  const tableRef = useRef(null);
  let componentRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let check = query.get("submited");
  let company_id = query.get("company_id");
  let date_type = query.get("date_type");
  let gender_id = query.get("gender_id");
  let term_id = query.get("term_id");
  let from_date = query.get("from_date");
  let to_date = query.get("to_date");
  let region_id = query.get("region_id");
  let selected_id = query.get("selected_id");
  let report = query.get("report");

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (check) {
      form.setFieldsValue({
        company_id: Number(company_id),
        date_type: date_type,
        gender_id: gender_id === "All" ? gender_id : Number(gender_id),
        region_id: region_id === "All" ? region_id : Number(region_id),
        term_id: Number(term_id),
        from_date: moment(from_date),
        to_date: moment(to_date),
      });
      dispatch(
        getPaymentReport(token, {
          company_id,
          date_type,
          
          from_date,
          to_date,
          
          
        })
      );
    }
  }, []);

  const handleChange = (value) => {
    
  };

  const onFinish = async (e) => {
    setSubmit(true);
    setLoading(true);
    await form.validateFields().then((values) => {
      setValues(values);
      setPropsData({
        company_id,
        
        
        
        from_date,
        to_date,
      });
      history.push({
        pathname: "/payment",
        search: `?submited=${true}&report=${1}&company_id=${values.company_id
          }&from_date=${moment(values.from_date).format(
            "MM-DD-YYYY"
          )}&to_date=${moment(values.to_date).format("MM-DD-YYYY")}`,
      });
      dispatch(getPaymentReport(token, values)).then((res) => {
        setLoading(false);
      });
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "serial_no",
      align: "left",
      key: "serial_no",
      width: 50,
    },
    {
      title: "Header No",
      dataIndex: "payment_header_no",
      key: "payment_header_no",
      width: 100,
      align: "right",
    },
    {
      title: "Date",
      dataIndex: "payment_date",
      key: "payment_date",
      width: 100,
      align: "right",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (record, text) => {
        return (
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            to={{
              pathname: `/payment`,
              search: `?submited=${true}&report=${2}&company_id=${values.company_id
                }&from_date=${moment(values.from_date).format(
                  "MM-DD-YYYY"
                )}&to_date=${moment(values.to_date).format(
                  "MM-DD-YYYY"
                )}&payment_header_id=${text.payment_header_id}`,
            }}
          >
            {" "}
            {record}
          </Link>
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 130,
      align: "right",
      render: (text) => <span style={{ textAlign: "right" }}> {text}</span>,
    },

  ];

  const handlePrint = () => {
    if (check && report === "2") {
      if (paymentStateReport?.payload?.data.length > 3000) {
        printJS({
          printable: paymentStateReport?.payload?.data,
          
          type: "json",
          honorColor: true,
          repeatTableHeader: true,
          documentTitle: "Report",
          properties: [
            "serial_no",
            "payment_header_no",
            "payment_date",
            "customer_name",
            "amount",
          ],
          gridHeaderStyle:
            " border: 1px solid grey; text-align:left; padding-left:10px",
          gridStyle: "border: 1px solid grey; padding-left:10px",
          header: header,
          
          
          type: "json",
        });
      } else {
        window.print();
      }
    } else if (check && report === "3") {
      if (regionStateReport?.payload?.data.length > 3000) {
        printJS({
          printable: regionStateReport?.payload?.data,
          
          type: "json",
          honorColor: true,
          repeatTableHeader: true,
          documentTitle: "Report",
          properties: [
            "serial_number",
            "customer_name",
            "state_name",
            "quantity",
            "net_amount",
            "percentage",
          ],
          gridHeaderStyle:
            " border: 1px solid grey; text-align:left; padding-left:10px",
          gridStyle: "border: 1px solid grey; padding-left:10px",
          header: header,
          
          
          type: "json",
        });
      } else {
        window.print();
      }
    } else if (check && report === "4") {
      if (omsReportByCustomer?.payload?.data.length > 3000) {
        printJS({
          printable: omsReportByCustomer?.payload?.data,
          
          type: "json",
          honorColor: true,
          repeatTableHeader: true,
          documentTitle: "Report",
          properties: [
            "serial_number",
            "order_header_no",
            "order_date",
            "start_date",
            "cancel_date",
            "reference_no",
            "salesperson_name",
            "term_name",
            "term_name",
            "quantity",
            "net_amount",
          ],
          gridHeaderStyle:
            " border: 1px solid grey; text-align:left; padding-left:10px",
          gridStyle: "border: 1px solid grey; padding-left:10px",
          header: header,
          
          
          type: "json",
        });
      } else {
        window.print();
      }
    } else {
      if (payment?.payload?.data.length > 3000) {
        printJS({
          printable: payment?.payload?.data,
          
          type: "json",
          honorColor: true,
          repeatTableHeader: true,
          documentTitle: "Report",
          properties: [
            "serial_no",
            "region_name",
            "quantity",
            "net_amount",
            "percentage",
          ],
          gridHeaderStyle:
            " border: 1px solid grey; text-align:left; padding-left:10px",
          gridStyle: "border: 1px solid grey; padding-left:10px",
          header: header,
          
          
          type: "json",
        });
      } else {
        window.print();
      }
    }
  };



  const columsPageOne = [
    {
      title: "Serial No",
      dataIndex: "serial_no",
      key: "serial_no",
    },
    {
      title: "Region Name",
      dataIndex: "region_name",
      key: "region_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },

  ];


  const columsPageTwo = [
    {
      title: "Serial No",
      dataIndex: "serial_number",
      key: "serial_number",
    },
    {
      title: "Region Name",
      dataIndex: "region_name",
      key: "region_name",
    },
    {
      title: "State Name",
      dataIndex: "state_name",
      key: "state_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },

  ];


  const columsPageThree = [
    {
      title: "Serial No",
      dataIndex: "serial_number",
      key: "serial_number",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "State Name",
      dataIndex: "state_name",
      key: "state_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },

  ];

  const columsPageFour = [
    {
      title: "Serial No",
      dataIndex: "serial_number",
      key: "serial_number",
    },
    {
      title: "Order",
      dataIndex: "order_header_no",
      key: "order_header_no",
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Cancel Date",
      dataIndex: "cancel_date",
      key: "cancel_date",
    },
    {
      title: "P0#",
      dataIndex: "reference_no",
      key: "reference_no",
    },
    {
      title: "Sales Person",
      dataIndex: "salesperson_name",
      key: "salesperson_name",
    },
    {
      title: "Term",
      dataIndex: "term_name",
      key: "term_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
    },


  ];

  const excelReport = payment?.payload?.data;

  
  let sumExcelQty =
    excelReport &&
    excelReport.reduce(function (previousValue, currentValue) {
      return (previousValue += parseFloat(
        
      ));
    }, 0);

  
  let sumExcelAmount =
    excelReport &&
    excelReport.reduce(function (previousValue, currentValue) {
      return (previousValue += parseFloat(
        currentValue.amount.replace(/\$|,/g, "")
      ));
    }, 0);

  let sumExcelLocalize =
    sumExcelAmount && sumExcelAmount.toLocaleString("en-US");

  const totalSumExcel = {
    region_name: "Total",
    quantity: sumExcelQty,
    net_amount: `${currencySymbol} ${sumExcelLocalize}`,
  };
  let concatenate = excelReport && excelReport.concat(totalSumExcel);


  const handleExcelExport = () => {
    if (check && report === "1") {
      if (concatenate.length > 3000) {
        const excel = new Excel();
        excel
          .addSheet("test")
          .addColumns(columsPageOne)
          .addDataSource(concatenate, {
            str2Percent: true,
          })
          .setTHeadStyle()
          .saveAs("Excel.xlsx");
      } else {
        setExcelToogle(true);
      }
    }
    if (check && report === "2") {
      if (paymentStateReport?.payload?.data.length > 3000) {
        const excel = new Excel();
        excel
          .addSheet("test")
          .addColumns(columsPageTwo)
          .addDataSource(paymentStateReport?.payload?.data, {
            str2Percent: true,
          })
          .setTHeadStyle()
          .saveAs("Excel.xlsx");
        setExcelToogle(false);
      } else {
        setExcelToogle(true);
      }
    }

    if (check && report === "3") {
      if (regionStateReport?.payload?.data.length > 3000) {
        setExcelToogle(false);
        const excel = new Excel();
        excel
          .addSheet("test")
          .addColumns(columsPageThree)
          .addDataSource(regionStateReport?.payload?.data, {
            str2Percent: true,
          })
          .setTHeadStyle()
          .saveAs("Excel.xlsx");
      } else {
        setExcelToogle(true);
      }
    }

    if (check && report === "4") {
      if (omsReportByCustomer?.payload?.data.length > 3000) {
        setExcelToogle(false);
        const excel = new Excel();
        excel
          .addSheet("test")
          .addColumns(columsPageFour)
          .addDataSource(omsReportByCustomer?.payload?.data, {
            str2Percent: true,
          })
          .setTHeadStyle()
          .saveAs("Excel.xlsx");
      } else {
        setExcelToogle(true);
      }
    }

  };


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row">
                <div className="col-sm-3 d-flex align-items-sm-center  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-sm-6 box-space pl-sm-0">
                  <p className="brand-para">
                    DL1961 Premium Denim Inc.
                    <br />
                    121 Varick Street
                    <br />
                    4th Floor
                    <br />
                    New York, NY 10013
                    <br />
                    (P) 646-745-1524
                    <br />
                    (F) 212-991-9989
                    <br />
                    (E) sales@DL1961.com
                    <br />
                  </p>
                </div>
                <div className="col-sm-3 box-space right-content-header text-sm-right">
                  <span style={{ fontWeight: "bolder" }}>
                    Region-Wise Booking
                    <br />
                    Rpt # 006
                    <br />
                    <br />
                    <div className=" d-flex justify-content-end printing_class">
                      <div className="print_icon">
                        {payment?.payload?.data.length ? (
                          <i
                            onClick={handlePrint}
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            className="fa-solid fa-print printing_class"
                          ></i>
                        ) : (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            className="fa-solid fa-print printing_class"
                          ></i>
                        )}
                      </div>
                      <div
                        className="print_icon printing_class"
                      

                      
                      >
                        {excelToogle ? (
                          <DownloadTableExcel
                            filename="users table"
                            sheet="users"
                            currentTableRef={tableRef.current}
                          >
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              style={{ borderWidth: "0px", height: "20px" }}
                            />
                          </DownloadTableExcel>
                        ) : (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            onClick={handleExcelExport}
                            style={{ borderWidth: "0px", height: "20px" }}
                          />
                        )}
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div className="row printing_class">
                <div className="col text-sm-right pb-1">
                  Login as:
                  <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                    {" "}
                    {userName}
                  </span>
                </div>
              </div>
              {/* <div className="report-wrapper-main header_class" ><div className="row"><div className="col-sm-3 d-flex align-items-sm-center  box-space"><div className="logobrand"><img id="ImgLogo" alt="logo" src={logo} /></div></div></div></div> */}
              {report < 2 && (
                <>
                  <div className="row printing_class">
                    <div className="col">
                      <Form
                        form={form}
                        name="control-hooks"
                        onFinish={onFinish}
                      >
                        <div className="main_form row">
                          <div className=" col-md-6">

                            <div className=" d-flex justify-content-between">
                              <div>
                                <label htmlFor="">From:</label>
                                <div>
                                  <Form.Item
                                    name="from_date"
                                    initialValue={moment("01-01-2022")}
                                  >
                                    <DatePicker
                                      size="large"
                                      defaultValue={moment().format(
                                        "MM-DD-YYYY"
                                      )}
                                      format={dateFormatList}
                                      className={"input-width"}
                                    
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              <div>
                                <label htmlFor="">To:</label>
                                <div>
                                  <Form.Item
                                    name="to_date"
                                    initialValue={moment()}
                                  >
                                    <DatePicker
                                      size="large"
                                      defaultValue={moment().format(
                                        "MM-DD-YYYY"
                                      )}
                                      format={dateFormatList}
                                      className={"input-width"}
                                    
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label htmlFor="">Brand :</label>
                            <div>
                              <Form.Item
                                name="company_id"
                                initialValue={
                                  submited
                                    ? form.getFieldValue().company_id
                                    : 2
                                }
                              >
                                <Select
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  showSearch
                                  
                                  loading={companyAll?.loading}
                                  size="large"
                                  defaultValue={
                                    form.getFieldValue().company_id
                                  }
                                  className={"input-width"}
                                >
                                  {companyAll?.payload?.data.map((item) => {
                                    return (
                                      <Option
                                        defaultValue={"All"}
                                        key={item.company_id}
                                        value={item.company_id}
                                      >
                                        {item.company_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className=" col-md-6">

                            <div>
                              <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>

                      </Form>
                    </div>
                  </div>
                </>
              )}

              <div className="row" ref={tableRef}>
                <div className="col">
                  <div className="w-100 mt-3">
                    {check && report === "1" && (
                      <>
                        {/* Report 1 */}
                        {loading ? (
                          <div className="w-100 d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin />
                          </div>
                        ) : payment?.payload?.data.length ? (
                          <Table
                            scroll={{ x: 200 }}
                            className="w-100 test04 myclass"
                            size="small"
                            pagination={{ pageSize: 3000 }}
                            summary={(pageData) => {
                              let sumQty = pageData.reduce(function (
                                previousValue,
                                currentValue
                              ) {
                                return (previousValue += parseFloat(
                                  
                                ));
                              },
                                0);

                              let sumAmount = pageData.reduce(function (
                                previousValue,
                                currentValue
                              ) {
                                return (previousValue += parseFloat(
                                  currentValue.amount.replace(/\$|,/g, "")
                                ));
                              },
                                0);
                              return (
                                <>
                                  <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                      <p
                                        style={{
                                          paddingTop: "10px",
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        Total
                                      </p>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                      <p
                                        style={{
                                          fontWeight: "bolder",
                                          textAlign: "right",
                                          width: "100%",
                                          borderTop: "1px solid black",
                                          borderBottom: "1px solid black",
                                        }}
                                      >
                                        {sumQty.toLocaleString("en-US")}
                                      </p>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                      <p
                                        style={{
                                          fontWeight: "bolder",
                                          textAlign: "right",
                                          width: "100%",
                                          borderTop: "1px solid black",
                                          borderBottom: "1px solid black",
                                        }}
                                      >
                                        {currencySymbol} {sumAmount.toLocaleString("en-US")}
                                      </p>
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>
                                </>
                              );
                            }}
                            bordered
                            columns={columns}
                            dataSource={
                              payment?.payload?.data
                                ? payment?.payload?.data
                                : []
                            }
                          />
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#CCCCCC",
                              borderWidth: "1pt",
                              borderStyle: "Dashed",
                              fontWeight: "bold",
                              height: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            No record(s)
                          </div>
                        )}
                      </>
                    )}
                    {check && report === "2" && (
                      <>
                        {/* Report 2 */}
                        <PaymentStateReport
                          id={selected_id}
                          propsData={propsData}
                          formData={values}
                          token={token}
                        />
                      </>
                    )}
                    {check && report === "3" && (
                      <>
                        {/* Report 3 */}
                        <OmsRegionReport
                          id={selected_id}
                          propsData={propsData}
                          formData={values}
                          token={token}
                        />
                      </>
                    )}
                    {check && report === "4" && (
                      <>
                        {/* Report 4 */}
                        <OmsReportByCustomer
                          reportTittle="Sale Order Report"
                          reportName="SaleOrderNewReport.rptdesign"
                          id={selected_id}
                          propsData={propsData}
                          formData={values}
                          token={token}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Header = () => {
  return <>hello</>;
};

export default Payment;
