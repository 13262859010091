import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from "../../components/Table/Tables";
import { invoiceHeaderColumns } from "../../utils/cols";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { FilterOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button, Form, Input, DatePicker, Collapse, Row, Col, Select } from "antd";
import { dateChecker } from "../../components/CheckDate/checkDate";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import jsPDF from "jspdf";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import { getBrands } from "../../redux/actions/brand.action";
import { currencySymbol } from "../../utils/constants";
const { Option } = Select;

export default function InvoiceList({ token }) {
	TabTitle("Invoice")
	const { Panel } = Collapse;
	const dateFormat = "MM-DD-YYYY";
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { invoiceHeaderList, brand } = useSelector((state) => state);

	const worker = {
		order: null,
		from_date: moment().startOf("year"),
		to_date: moment().endOf("year"),
		invoiceNo: null,
		customer: null,
		shipHeaderId: null,
		postingPeriod: null,
		po: null,
		orderNumber: null,
		saleperson_name: null,
		region: null,
		gender: null,
		brand_id: null,
		term: null
	};

	useEffect(() => {
		dispatch(getBrands(token));
	}, [])

	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {
			let formDateCopy = document.getElementById("fromDate").value;
			let toDateCopy = document.getElementById("toDate").value;
			dateChecker(formDateCopy, toDateCopy, "invoiceList", token, values, dispatch);
		})
	};

	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
					"Invoice #": v.invoice_header_no,
					"Invoice Date": moment(v.invoice_date).format("MM-DD-YYYY"),
					"PO #": v.po_no,
					"PT #": v.ship_header_no,
					"Sale Order #": v.order_header_no,
					"Brand": v.brand_name,
					"Customer": v.customer_name,
					"Sale Person Name": v.salesperson_name,
					"Region Name": v.region_name,
					"Gender Name": v.gender_name,
					"Term": v.payment_term_name
				};
			})
		}
	}

	function generatePDF() {
		var doc = new jsPDF();

		var columns = ["Invoice # ", "Invoice Date", "PT#", "PO#", "Brand", "Customer", "Term", "Qty", "Amount", "Gender"];
		var rows = [];

		invoiceHeaderList?.payload?.data.forEach(item => {
			var row = [item.invoice_header_no, moment(item.invoice_date).format("MM-DD-YYYY"), item.ship_header_no, item.po_no, item.brand_name, item.customer_name, item.payment_term_name, parseFloat(item.quantity).toFixed(0), currencySymbol + parseFloat(item.net).toFixed(2), item.gender_name];
			rows.push(row);
		});

		const qty = invoiceHeaderList?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.quantity), 0)
		const amount = invoiceHeaderList?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.net), 0)


		rows.push([" ", " ", " ", " ", " ", "Total: ", qty.toLocaleString("en-US"), currencySymbol + amount.toLocaleString("en-US"), " "])

		doc.autoTable({
			head: [columns],
			body: rows,
			styles: { fontSize: 6, align: 'center' },
		})

		doc.save("Invoice.pdf");
	}

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Invoice List"} />
				<div className="bodyStyle">
					<Collapse
						style={{ width: "99%", margin: "10px" }}
						bordered={false}
						defaultActiveKey={['1']}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					>
						<Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<FilterOutlined />}>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item
											name="invoiceNo"
											label="Invoice #"
											rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
										>
											<Input

												allowClear
												size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item
											name="customer"
											label="Customer"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="PT #" name="shipHeaderId" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item
											name="postingPeriod"
											label="Posting Period"
										>
											<Input

												allowClear
												size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item
											name="po"
											label="P.O #"
										>
											<Input

												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="orderNumber"
											label="Sale Order #"
											rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
										>
											<Input

												allowClear
												size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												defaultValue={form.getFieldValue().fromDate} format={dateFormat} className="dateInput customInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												defaultPickerValue={form.getFieldValue().toDate} format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Sale Person Name" name="saleperson_name">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Region" name="region" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Gender" name="gender" rules={[{ pattern: new RegExp(/^[a-zA-Z]*$/), message: "Must be Characters." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Terms" name="term" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="brand_id" label="Brand">
											<Select
												size="small"
												showSearch
												allowClear
												optionFilterProp="children"
												filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())} loading={brand.loading} className="w-100">
												{brand?.payload?.data?.map((res) => (
													<Option key={res.brand_id} value={res.brand_id}>
														{res.brand_name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit">
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

					{invoiceHeaderList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
								{
									invoiceHeaderList?.payload?.data && (
										<>
											<div style={{ display: "flex", justifyContent: "left", marginBottom: "5px" }}>
												<div className="print_icon printing_class" >
													<CSVLink
														filename={"Invoice.csv"}
														data={downloadExcelFileData(invoiceHeaderList?.payload?.data) || []}
														onClick={() => {
														}}
													>
														Download Excel
													</CSVLink>
												</div>
												<div className="print_icon printing_class" >
													<input
														type="image"
														name="ImgPDF"
														alt="Export to PDF"
														id="ImgPDF"
														title="Export to PDF"
														src={pdf}
														onClick={generatePDF}

														style={{ borderWidth: "0px", height: "24px", float: "inline-end", margin: "0px 0px 0px 12px", }}
													/>
												</div>
											</div>
										</>
									)
								}
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									col={invoiceHeaderColumns}
									source={invoiceHeaderList?.payload?.data}
									load={invoiceHeaderList.loading}
									paginate
								/>
							</div>
						</div>
					)}

				</div>
			</div>
		</div>
	);
}