import React, { useCallback, useRef, useState } from "react";
import "./CustomerPaymentLedger.css";
import { Select, DatePicker, Form, Table, Spin, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { loadCustomers } from "../../redux/actions/customerForm";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import moment from "moment";
import { useParams } from "react-router-dom";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { getVendorSummary } from "../../redux/actions/vendorLedger.action";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { currencySymbol } from "../../utils/constants";

const { Option } = Select;
const dateFormatList = ["MM/DD/YYYY"]; 

const VendorSummary = ({ token }) => {
  TabTitle("Vendor Summary")
  const userName = ReadCookie("name");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const focusInput = useRef(null);
  const tableRef = useRef(null);
  const [customerList, setCutomerList] = useState([]);
  const [customerId, setCutomerId] = useState([]);
  const [excelData, setExcelData] = useState([])
  const [loading, setLoading] = useState(false);
  const {
    vendorSummary,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const [customer, setCustomer] = useState("All");
  const { submited } = useParams();



  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);
  let rbalance = 0;

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      dispatch(getVendorSummary(token, values)).then((res) => {
        
        let filterData = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id != "0";
        })
        
        let filterZeroId = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id == "0";
        })
        

        setCutomerId(filterZeroId)
        setCutomerList(filterData)
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);
      })
    });
  };

  
  
  
  
  
  
  
  
  

  const handlePrint = () => {
    window.print();
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Vendor #": v.vendor_id,
          "Vendor Name": v.vendor_name,
          "Balance Amount": `${currencySymbol} ${numeral(v.balance_amount).format("0,00.00")}`
        };
      })
    )
  }


  const column = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Vendor #",
      dataIndex: "vendor_id",
      key: "vendor_id",
      sorter: (a, b) => {
        return a.vendor_id - b.vendor_id
      },
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render:(text, record)=>{
      return   <Link
      style={{ color: "blue", textDecoration: "underline", padding: "10px", textDecoration: "none" }}
      target="_blank"
      rel="noopener noreferrer"
      to={{ pathname: `/vendor-ledger`, search: `?vendor_id=${record?.vendor_id}` }}
    >{record.vendor_name}
    </Link>
      },
      sorter: (a, b) => {
        if ((a.vendor_name !== null) & (b.vendor_name !== null)) {
          return a.vendor_name.localeCompare(b.vendor_name);
        }
      }
    },
    {
      title: "Balance Amount",
      dataIndex: "balance_amount",
      key: "balance_amount",
      align: "right",
      render: (text, record) => {
        return <>{currencySymbol} {numeral(text).format("0,00.00")}</>
      },
      sorter: (a, b) => {
        return a.balance_amount - b.balance_amount
      },
    },
  ];



  const billAmount = customerList.reduce((acc, item, index) => acc + parseFloat(item.balance_amount), 0)

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="report-wrapper-main header_class business_class ledger_print">
            <div className="row business_header justify-content-between pb-2 ledger_print">
              <div className="col-3 d-flex align-items-start  box-space">
                <div className="logobrand">
                  <img id="ImgLogo" alt="logo" src={logo} />
                </div>
              </div>

              <div className="col-4 box-space right-content-header text-right pb-0">
                <div className=" d-flex justify-content-end">
                  <div className=" d-flex printing_class mr-8">
                    <div className="print_icon printing_class">
                      <i
                        onClick={handlePrint}
                        id="Image1"
                        title="Print"
                        alt="Print"
                        src={btnPrint}
                        className="fa-solid fa-print"
                      ></i>
                    </div>
                    <div className="print_icon printing_class" >
                      <CSVLink
                        filename={"Vendor-Summary.csv"}
                        data={excelData}
                        onClick={() => {
                        }}
                      >
                        <input
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={xlsx}
                          style={{ borderWidth: "0px", height: "14px" }}
                        />
                      </CSVLink>
                    </div>
                  </div>
                  <span style={{ fontWeight: "bolder" }}>
                    Vendor Summary
                    <br />

                    <div>
                      Rpt # 489
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {/* Filters Table */}
            <div className="row  business_header ledger_print">
              <div className="col business_header">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                  <div className="main_form row">

                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          From:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="from_date"
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            />
                          </Form.Item>
                        </div>
                      </div>

                    </div>
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          To:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="to_date"
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main_form row">
                    <div className=" col-6">
                    </div>
                    <div className=" col-6">
                      <div>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="submit-btn-btm "
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Render Data Table */}
          </div>
          <div className="table_content customer_ledger_table_content ledger_print" style={{ maxWidth: '900px' }} >
            <div className="">
              {vendorSummary?.payload?.data !== undefined ?
                (
                  vendorSummary?.payload?.data.length > 0 ? (
                    <Table
                      className="
                
                test04 mt-3 sales_report_res_table_intransit ledger_print"
                      ref={tableRef}
                      size="small"
                      bordered={false}
                      type="company"
                      headerClassName='abc'
                      dataSource={customerList && customerList}
                      columns={column}
                      loading={vendorSummary.loading}
                      scroll={{ x: 'max-content' }}
                      pagination={{
                        defaultCurrent: 1,
                        defaultPageSize: 500,
                        pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                      }}
                      summary={(pageData) => {
                        return (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                  {currencySymbol}{billAmount.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>

                            </Table.Summary.Row>
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="no-Data">
                      <h1>No Data</h1>
                    </div>
                  )
                ) : (
                  loading && (
                    <div className="loader" style={{ marginTop: "20px" }}>
                      <Spin size="medium" />
                    </div>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorSummary;
