import React, { useEffect } from "react";
import "./SalesReport.css";
import CreditMemoReportHeader from "./CreditMemoReportHeader/CreditMemoReportHeader";
import CreditMemoReportFooter from "./CreditMemoReportFooter/CreditMemoReportFooter";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { getCreditMemoReportPrint } from "../../redux/actions/creditMemoReport.action";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import numeral from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { currencySymbol } from "../../utils/constants";

const CreditMemoReport = () => {
  TabTitle("Credit Memo Report")
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const { creditMemoReportPrint } = useSelector((state) => state);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  let note_header_id = query.get("note_header_id");

  const data = creditMemoReportPrint && creditMemoReportPrint?.payload?.data;

  const values = {

    note_header_id: note_header_id,
  };

  useEffect(() => {
    dispatch(getCreditMemoReportPrint(token, values));
  }, []);

  let sumAmount = creditMemoReportPrint?.payload?.data.reduce(function (
    previousValue,
    currentValue
  ) {
    return (previousValue += parseFloat(currentValue.amount));
  },
    0);

  const totalTaxAmount = data && data.length ? data.reduce((total, x) => total + Number(x.tax_amount), 0) : 0
  const totalQuantity = data && data.length ? data.reduce((total, x) => total + Number(x.quantity), 0) : 0

  return (
    <div>
      {data ? (
        <div
          className="order_report_view"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <table>
            <thead>
              <tr>
                <td className="footer">
                  <div>
                    <CreditMemoReportHeader />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="content-block">
                    <div className="sales-report-Table-data">
                      <table className="sales-report-table">
                        <tbody>
                          <tr>
                            <td
                              className="font_weight"
                              style={{
                                width: "88px",
                                border: "none",
                                height: "30px",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Quantity
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "110px",
                                border: "none",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Item
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "500px",
                                border: "none",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Description
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "110px",
                                border: "none",
                                textAlign: "right",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Rate
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "110px",
                                border: "none",
                                textAlign: "right",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Discount
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "110px",
                                border: "none",
                                textAlign: "right",
                                paddingRight: "5px",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Amount
                            </td>
                          </tr>
                          {data?.map((size, index) => {
                            return (
                              <tr>
                                <td style={{ border: "none", height: "20px", }}>{numeral(size.quantity).format("0")}</td>
                                <td
                                  style={{
                                    marginLeft: "0",
                                    border: "none",
                                  }}
                                >
                                  {size.sku}
                                </td>
                                <td style={{ border: "none", }}>{size.description}</td>
                                <td style={{ border: "none", textAlign: "right", }}>{currencySymbol}{size.rate && size.rate}</td>
                                <td style={{ border: "none", textAlign: "right", }}>{currencySymbol}{size.discount && size.discount}</td>
                                <td style={{ border: "none", textAlign: "right", paddingRight: "5px", }}>{currencySymbol}{size.amount && size.amount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <hr style={{ marginBottom: 0 }} />
                      <span style={{ paddingLeft: 5, fontSize: 10 }}>{totalQuantity}</span>
                      <hr style={{ marginTop: 2 }} />
                    </div>
                  </div>
                  <table className="sales-report-table-total2">
                    <tbody>
                      <tr className="font_weight">
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: "right",
                            paddingRight: "10px",
                            border: "none",
                            backgroundColor: "rgb(184, 184, 184)"
                          }}
                          className="font_weight total_cell"
                        >
                          Total
                        </td>
                        <td
                          className="sum_cell_credit"
                          style={{
                            whiteSpace: 'nowrap',
                            border: "none",
                            textAlign: "right",
                            paddingRight: "5px",
                            backgroundColor: "rgb(184, 184, 184)"
                          }}
                          colSpan={2}
                        >
                          {currencySymbol}{sumAmount && numeral(sumAmount).format("00.00")}
                        </td>
                      </tr>
                      <tr className="font_weight">
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: "right",
                            paddingRight: "10px",
                            border: "none",
                            backgroundColor: "rgb(184, 184, 184)"
                          }}
                          className="font_weight total_cell"
                        >
                          Re-Stocking fee
                        </td>
                        <td
                          className="sum_cell_credit"
                          style={{
                            whiteSpace: 'nowrap',
                            border: "none",
                            textAlign: "right",
                            paddingRight: "5px",
                            backgroundColor: "rgb(184, 184, 184)"
                          }}
                          colSpan={2}
                        >
                          {currencySymbol}{data && numeral(data[0].re_stock).format("00.00")}
                        </td>
                      </tr>
                      <tr className="font_weight" style={{ borderBottom: "1px solid" }}>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: "right",
                            paddingRight: "10px",
                            border: "none",
                            backgroundColor: "rgb(184, 184, 184)"
                          }}
                          className="font_weight total_cell"
                        >
                          Add Tax
                        </td>
                        <td
                          style={{
                            border: "none",
                            textAlign: "right",
                            paddingRight: "5px",
                            backgroundColor: "rgb(184, 184, 184)"
                          }}
                        >{data && data[0].tax_percent}%</td>
                        <td
                          className="sum_cell_credit"
                          style={{
                            whiteSpace: 'nowrap',
                            border: "none",
                            textAlign: "right",
                            paddingRight: "5px",
                            backgroundColor: "rgb(184, 184, 184)"
                          }}
                        >
                          {currencySymbol}{numeral(totalTaxAmount).format("00.00")}
                        </td>
                      </tr>
                      <tr className="font_weight">
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: "right",
                            paddingRight: "10px",
                            border: "none",
                            backgroundColor: "rgb(184, 184, 184)"
                          }}
                          className="font_weight total_cell"
                        >
                          Net Total
                        </td>
                        <td
                          className="sum_cell_credit"
                          style={{
                            whiteSpace: 'nowrap',
                            border: "none",
                            textAlign: "right",
                            paddingRight: "5px",
                            backgroundColor: "rgb(184, 184, 184)"
                          }}
                          colSpan={2}
                        >
                          {currencySymbol}{data && numeral((sumAmount + totalTaxAmount) - data[0].re_stock).format("00.00")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className="footer2">

                  <div className="sales-report-footer">
                    <CreditMemoReportFooter />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      ) : (
        <div className="spin">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default CreditMemoReport;
