import React, { useEffect, useMemo, useRef } from "react";
import "./SalesReturnReport.css";

import SalesReturnReportHeader from "../SalesReturnReport/SalesReturnReportHeader/SalesReturnReportHeader";
import SalesReturnReportFooter from "./SalesReturnReportFooter/SalesReturnReportFooter";
import SalesReportFooter from "../SalesReport/SalesReportFooter/SalesReportFooter";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";

import {
  getRAReportItem,
  getRAReportPrint,
  getRAReportColumnValue,
  getRAReportColumn,
} from "../../redux/actions/rAReport.action";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { getSaleReturnReport } from "../../redux/actions/saleRrutrnReport.action";
import moment from "moment";
import { currencySymbol } from "../../utils/constants";


const SalesReturnReport = () => {
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const {

    RAPrint,
    RAItem,
    RAColumn,
    RAColumnValue,
  } = useSelector((state) => state);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let return_header_id = query.get("return_header_id");

  const footerData = RAPrint && RAPrint?.payload?.data[0];

  const sum = RAColumnValue?.payload?.data.reduce(
    (acc, item, index) => acc + parseInt(item.item_quantity),
    0
  );
  const totalAmount = RAPrint?.payload?.data[0]?.sum_gross_amount


  let groupValues =
    RAColumnValue &&
    RAColumnValue?.payload?.data.reduce(function (r, a) {
      r[a.gender_category_id] = r[a.gender_category_id] || [];
      r[a.gender_category_id].push(a);
      return r;
    }, Object.create(null));

  const groupedColumnValue = groupValues && Object.values(groupValues);

  const values = {

    return_header_id: return_header_id,
  };

  useEffect(() => {


    dispatch(getRAReportItem(token, values));
    dispatch(getRAReportPrint(token, values));
    dispatch(getRAReportColumnValue(token, values));
    dispatch(getRAReportColumn(token, values));
  }, [dispatch, menuId, token]);

  const myData = useMemo(() => {
    return RAColumn?.payload?.data.map((it, i) => {
      return {
        column: it,
        detail: groupedColumnValue && groupedColumnValue[i],
      };
    });
  }, [groupedColumnValue, RAColumn]);



  return (
    <div>
      {footerData ? (
        <div
          className="sales_return_view"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <table>
            <thead>
              <tr>
                <td className="return-footer">
                  <div>
                    <SalesReturnReportHeader />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="content-block">
                    <div className="sales-report-Table-data mt-0">
                      {myData?.map((size, index) => {
                        return (
                          <table className="sales-report-table">
                            <tbody>
                              <tr>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "36px",
                                  }}
                                >
                                  S.No
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "88px",
                                  }}
                                >
                                  SKU #
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "240px",
                                  }}
                                >
                                  Style/Desc
                                  <span style={{ marginLeft: "5px" }}>
                                    {size?.column?.gender_category_name}
                                  </span>
                                </td>
                                <td
                                  key={index}
                                  className="sales_table_col font_weight text-center pl-0"
                                >
                                  {size.column.hq23 && size.column.hq23}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq24 && size.column.hq24}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq25 && size.column.hq25}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq26 && size.column.hq26}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq27 && size.column.hq27}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq28 && size.column.hq28}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq29 && size.column.hq29}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq30 && size.column.hq30}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq31 && size.column.hq31}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq32 && size.column.hq32}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq33 && size.column.hq33}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq34 && size.column.hq34}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq35 && size.column.hq35}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq36 && size.column.hq36}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq37 && size.column.hq37}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq38 && size.column.hq38}
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "50px",
                                  }}
                                >
                                  Quantity
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "80px",
                                  }}
                                >
                                  Rate
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "80px",
                                  }}
                                >
                                  Amount
                                </td>
                              </tr>
                              {/* dummy data */}

                              {size.detail?.map((it, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td
                                      style={{
                                        marginLeft: "0",
                                      }}
                                    >
                                      {it.item_code}
                                    </td>
                                    <td>{it.description}</td>
                                    <td className="text-center pl-0">
                                      {it.q23 && it.q23}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q24 && it.q24}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q25 && it.q25}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q26 && it.q26}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q27 && it.q27}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q28 && it.q28}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q29 && it.q29}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q30 && it.q30}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q31 && it.q31}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q32 && it.q32}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q33 && it.q33}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q34 && it.q34}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q35 && it.q35}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q36 && it.q36}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q37 && it.q37}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.q38 && it.q38}
                                    </td>
                                    <td>
                                      {it.item_quantity && it.item_quantity}
                                    </td>
                                    <td>
                                      {currencySymbol} {it.rate && it.rate}
                                    </td>
                                    <td>{currencySymbol} {it.amount && it.amount}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        );
                      })}
                      <div style={{ display: "flex", justifyContent: "space-between" }}>

                        <div
                          style={{
                            paddingTop: "5px",
                          }}
                        >
                          * Invoice dates are respectively to invoice No
                        </div>

                        <table className="sales-return-table-total">
                          <tbody>
                            <tr className="font_weight">
                              <td
                                style={{
                                  width: "30px",
                                  textAlign: "right",
                                  paddingRight: "10px",
                                }}
                                className="font_weight total_cell"
                              >
                                Total Unit
                              </td>
                              <td
                                className="sum_cell"
                                style={{
                                  width: "30px",
                                }}
                              >
                                {sum}
                              </td>
                            </tr>
                            <tr className="font_weight">
                              <td
                                style={{
                                  width: "30px",
                                  textAlign: "right",
                                  paddingRight: "10px",
                                }}
                                className="font_weight total_cell"
                              >
                                Total Amount
                              </td>
                              <td
                                className="sum_cell"
                                style={{
                                  width: "30px",
                                }}
                              >
                                {currencySymbol + ' ' + totalAmount}
                              </td>
                            </tr>
                            <tr className="font_weight">
                              <td
                                style={{
                                  width: "30px",
                                  textAlign: "right",
                                  paddingRight: "10px",
                                }}
                                className="font_weight total_cell"
                              >
                                RA Expire Date:
                              </td>
                              <td
                                className="sum_cell"
                                style={{
                                  width: "30px",
                                }}
                              >
                                {footerData && moment(footerData.expiry_date).format("MM-DD-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <table
                        className="return_sales_person_table"

                      >
                        { }
                        <tbody>
                          <tr
                            style={{
                              borderBottom: "1px solid black",
                            }}
                            className="font_weight "
                          >
                            <td className="font_weight total_cell">Reason:</td>
                            <td
                              className="sum_cell"
                              style={{

                              }}
                            >
                              {footerData && footerData.memo}
                            </td>
                          </tr>
                          <tr
                            style={{
                              borderBottom: "1px solid black",
                            }}
                            className="font_weight"
                          >
                            <td

                              style={{
                                width: "15%",
                              }} className="font_weight "
                            >
                              Sale Person:
                            </td>
                            <td

                              style={{
                                width: "100%",
                              }}
                            >
                              {footerData && footerData.salesperson_name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* <div>
                  <div className="signature">
                    Authorized Signature
                  </div>
                </div> */}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className="return-footer2">
                  <div className="sales-report-footer">
                    <SalesReturnReportFooter />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      ) : (
        <div className="spin">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default SalesReturnReport;
