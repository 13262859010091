import React, { useEffect, useState, useRef } from "react";
import "./StyleLedger.css";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getStyleLedger } from "../../redux/actions/styleLedgerReport.action";
import { getProductType } from "../../redux/actions/productType.action";
import { ReadCookie } from "../../utils/readCookie";
import { Spin, Select, DatePicker, Checkbox, Form, Input } from "antd";
import { getStyle } from "../../redux/actions/style";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";
import { getNewSeason } from "../../redux/actions/newSeason.action";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import moment from "moment";
import { useDownloadExcel } from "react-export-table-to-excel";
import numeral, { Numeral } from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";

const dateFormatList = ["MM-DD-YYYY"];

const StyleLedger = ({ token }) => {
  TabTitle("Transaction Ledger");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { style, productStatus, adminLocation } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [BGradechecked, setBGradeChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [allSesion, setAllSesion] = useState(true);
  const [steps, setSteps] = useState([]);
  const stepsArr = [18, 34, 35,1];
  const [pendingChecked, setPendingChecked] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [allStatus, setAllStatus] = useState(false);
  const [isChecked2, setIsChecked2] = useState([
    1, 2, 3, 6, 7, 5, 9, 10, 11, 12,
  ]);
  const [loadding, setLoadding] = useState(false);
  const [inputLoaodding, setInputLoadding] = useState(false);
  const [StockwiseReportData, setStockWiseReport] = useState([]);
  const [stepsChecked, setStepChecked] = useState([]);

  const tableRef = useRef(null);
  const buttonRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Transaction-Ledger",
    sheet: "Transaction-Ledger",
  });
  const customSortOrder = ["BERGEN WS", "BERGEN ECOM", "NORD D2C", "Neiman D2C", "Saks D2C", "VERISHOP D2C", "NRHL D2C", "PK Warehouse", "In Transit", "Production", "International"];
  adminLocation?.payload?.data?.sort((a, b) => {
    const indexA = customSortOrder.indexOf(a.location_name);
    const indexB = customSortOrder.indexOf(b.location_name);
    
    return indexA - indexB;
  })
  useEffect(() => {
    if (adminLocation?.payload?.data?.length > 0) {
      setSteps(
        adminLocation?.payload?.data
          .filter((val) => stepsArr.includes(val.location_id))
          .map((it) => ({ ...it, selected: true }))
      );
    }
  }, [adminLocation]);
  const stepschecked =
    steps && steps?.filter((it) => it.selected)?.map((it) => it.location_id);
  
  const getStepData =
    adminLocation &&
    adminLocation?.payload?.data.filter((val) =>
      stepsArr.includes(val.location_id)
    );

  useEffect(() => {
    StockwiseReportData.filter((item) =>
      stepschecked.includes(item.location_id)
    );
  }, [stepschecked]);

  let result =
    StockwiseReportData &&
    StockwiseReportData.reduce(function (r, a) {
      r[a.item_code + a.inseam + a.description] =
        r[a.item_code + a.inseam + a.description] || [];
      r[a.item_code + a.inseam + a.description].push(a);
      return r;
    }, Object.create(null));

  let wiseStockReport = result && Object.values(result);

  const { Option } = Select;

  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  const isCheckboxChecked2 = (id, value) => {
    
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [...current, id]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter((item) => item !== id);
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };


  
  
  
  
  
  
  const filteredData = wiseStockReport[0]?.filter((item) =>
    stepschecked.includes(item.location_id) && isChecked2.includes(item.t_id)
  );

  if (filteredData !== undefined && filteredData?.length >= 0) { wiseStockReport = []; wiseStockReport.push(filteredData); }
  

  

  const onFinish = async (e) => {
    await form.validateFields().then((values) => {
      
      setInputLoadding(true)
      values.BGradeStock = BGradechecked;
      values.stepsChecked = stepsChecked;
      values.isChecked = isChecked;
      values.isChecked2 = isChecked2;

      if (allStatus) {
        values.allStatus = allStatus;
      }

      if (pendingChecked) {
        stepschecked.push(-1);
      }
      
      const styleId =
        style &&
        style?.payload?.data.find((val) => val.style_name === values.style);
      
      const productStatusId =
        productStatus &&
        productStatus?.payload?.data.find(
          (val) => val.product_status_name === values.product_Status
        );

      
      const distributorParam = {
        
        t_id: "1,2,3,6,7,5,10,11,12,9",
        
        location_id: stepsArr.toString(),
        from_date: moment(values.from_date).format("MM-DD-YYYY"),
        to_date: moment(values.to_date).format("MM-DD-YYYY"),
        sku: values.sku ? values.sku : null,
      };
      setLoading(true);

      dispatch(getStyleLedger(token, distributorParam)).then((res) => {
        setLoading(false);
        setStockWiseReport(res?.payload?.data);
        
        if (isChecked2?.length === 0) {
          wiseStockReport = [];
          setIsChecked2([1, 2, 3, 6, 7, 5, 10, 11, 12, 9]);
        }
        setInputLoadding(false)
      });
    });
    setLoading(true);
  };
  

  

  function downloadExcelFileData() {
    setExcelData(
      wiseStockReport[0].map((v) => {
        return {
          "SKU": v.sku,
          "Inseam": v.inseam,
          "Style": v.style_name,
          "Wash": v.wash_name,
          "Location": v.location_name,
          "Transaction No": v.transaction_no,
          "Transaction Type": v.transaction_type,
          "Transaction Date": moment(v.transaction_date).format("MM-DD-YYYY"),
          " 23 ": v.qty_1,
          " 24 ": v.qty_2,
          " 25 ": v.qty_3,
          " 26 ": v.qty_4,
          " 27 ": v.qty_5,
          " 28 ": v.qty_6,
          " 29 ": v.qty_7,
          " 30 ": v.qty_8,
          " 31 ": v.qty_9,
          " 32 ": v.qty_10,
          " 33 ": v.qty_11,
          " 34 ": v.qty_12,
          " 35 ": v.qty_13,
          " 36 ": v.qty_14,
          " 37 ": v.qty_15,
          " 38 ": v.qty_16,
          "Quantity": v.total_qty,
          "ASN/Memo/PT Status": v.asn_no,
        };
      })
    );
    setLoadding(false);
  }

  let sumQuantity;

  useEffect(() => {
    dispatch(getStyle(token, menuId));
    dispatch(getProductType(token, menuId));
    
    dispatch(getGenderCategory(token, menuId));
    
    dispatch(getNewSeason(token, menuId));
    
    dispatch(getAdminLocation(token, menuId));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (allSesion) {
      setIsChecked([]);
    }
  }, [allSesion]);

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="distributor header_class stock_style_wise"
    
    >
      <div className="row justify-content-between distributr">
        {/**** / Logo *****/}
        <div className="col-3 d-flex align-items-sm-center box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-3 box-space right-content-header text-sm-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8 printing_class">
              <div className="print_icon printing_class">
                <i
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  onClick={handlePrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Transaction-Ledger.csv"}
                  data={excelData}
                  onClick={() => downloadExcelFileData()}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Transaction Ledger
              <br />
              Rpt # 489
              <br />
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="business_class_oms distributr">
        {/* Table Selects */}
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className=" col-12 ">
              {wiseStockReport?.length > 0 ? <hr className="mt-0 mb-2" /> : ""}
              <div>
                {wiseStockReport?.length > 0 ? (
                  <label htmlFor="">Transaction Type</label>
                ) : (
                  ""
                )}
                {wiseStockReport?.length > 0 ? (
                  <div className="checkboxes">
                    {/* <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div> */}
                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(1)}
                          onChange={(e) =>
                            isCheckboxChecked2(1, e.target.checked)
                          }
                        >
                          Inv Adjustment
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(2)}
                          onChange={(e) =>
                            isCheckboxChecked2(2, e.target.checked)
                          }
                        >
                          Inv Transfer From
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(3)}
                          onChange={(e) =>
                            isCheckboxChecked2(3, e.target.checked)
                          }
                        >
                          Inv Transfer To
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(6)}
                          onChange={(e) =>
                            isCheckboxChecked2(6, e.target.checked)
                          }
                        >
                          Item Receipt
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(7)}
                          onChange={(e) =>
                            isCheckboxChecked2(7, e.target.checked)
                          }
                        >
                          Pick Ticket
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(5)}
                          onChange={(e) =>
                            isCheckboxChecked2(5, e.target.checked)
                          }
                        >
                          Bill No
                        </Checkbox>
                      </Form.Item>
                    </div>

                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(9)}
                          onChange={(e) =>
                            isCheckboxChecked2(9, e.target.checked)
                          }
                        >
                          Sale Order
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(10)}
                          onChange={(e) =>
                            isCheckboxChecked2(10, e.target.checked)
                          }
                        >
                          Invoice
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(11)}
                          onChange={(e) =>
                            isCheckboxChecked2(11, e.target.checked)
                          }
                        >
                          Return Auth
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="checkbox_content">
                      <Form.Item name="current_status_id" className=" mb-0">
                        <Checkbox
                          
                          checked={allStatus ? false : isChecked2.includes(12)}
                          onChange={(e) =>
                            isCheckboxChecked2(12, e.target.checked)
                          }
                        >
                          Credit Note
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                ) : (
                  " "
                )}
              </div>
              {wiseStockReport?.length > 0 ? <hr className="my-1" /> : ""}
              <div>
                {wiseStockReport?.length > 0 ? <label>Locations</label> : ""}
                {wiseStockReport?.length > 0 ? (
                  <div className="checkboxes">
                    {getStepData &&
                      getStepData?.map((step, i) => {
                        return (
                          <div className="checkbox_content">
                            <Checkbox
                              checked={steps[i]?.selected}
                              value={12}
                              onChange={(e) => {
                                setSteps(
                                  steps.map((it) => {
                                    if (it.location_id == step.location_id) {
                                      return {
                                        ...it,
                                        selected: e.target.checked,
                                      };
                                    } else {
                                      return it;
                                    }
                                  })
                                );
                              }}
                            >
                              {step.location_name}
                            </Checkbox>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <hr className="my-1" />
              <div className="main_form row">
                {/* Second */}

                {/* Third */}

                {/* Fourth */}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">From</label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment().startOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">To</label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                        
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44">Sku</label>

                    <div className="w-100">
                      <Form.Item
                        name="sku"
                        className="sku_input"
                        rules={[{ required: true, message: "SKU is required" }]}
                      >
                        <Input size="small" disabled={inputLoaodding === true} className={"select_Inputs mb-0"} />
                      </Form.Item>
                    </div>

                    <div
                      style={{
                        
                        margin: "2px",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      {!loading ? (
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="submit-btn-btm m-0"
                        >
                          Generate
                        </Button>
                      ) : (
                        <Spin size="medium" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline"></div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      {/* Table  Data */}

      <div className="business_class_oms tableContainer" ref={tableRef} style={{ height: "60vh" }}>
        {loading ? (
          <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
            {/* <Spin size='large' /> */}
          </div>
        ) : wiseStockReport ? (
          wiseStockReport.length > 0 ? (
            wiseStockReport.map((data, index) => {
              const sizes = data;
              

              let qty1 = null;
              let qty2 = null;
              let qty3 = null;
              let qty4 = null;
              let qty5 = null;
              let qty6 = null;
              let qty7 = null;
              let qty8 = null;
              let qty9 = null;
              let qty10 = null;
              let qty11 = null;
              let qty12 = null;
              let qty13 = null;
              let qty14 = null;
              let qty15 = null;
              let qty16 = null;

              for (let i = 0; i < data.length; i++) {
                qty1 += parseInt(data[i].qty_1);
                qty2 += parseInt(data[i].qty_2);
                qty3 += parseInt(data[i].qty_3);
                qty4 += parseInt(data[i].qty_4);
                qty5 += parseInt(data[i].qty_5);
                qty6 += parseInt(data[i].qty_6);
                qty7 += parseInt(data[i].qty_7);
                qty8 += parseInt(data[i].qty_8);
                qty9 += parseInt(data[i].qty_9);
                qty10 += parseInt(data[i].qty_10);
                qty11 += parseInt(data[i].qty_11);
                qty12 += parseInt(data[i].qty_12);
                qty13 += parseInt(data[i].qty_13);
                qty14 += parseInt(data[i].qty_14);
                qty15 += parseInt(data[i].qty_15);
                qty16 += parseInt(data[i].qty_16);
              }

              let sumQ = 0;
              let sumQty_1 = 0;
              let sumQty_2 = 0;
              let sumQty_3 = 0;
              let sumQty_4 = 0;
              let sumQty_5 = 0;
              let sumQty_6 = 0;
              let sumQty_7 = 0;
              let sumQty_8 = 0;
              let sumQty_9 = 0;
              let sumQty_10 = 0;
              let sumQty_11 = 0;
              let sumQty_12 = 0;
              let sumQty_13 = 0;
              let sumQty_14 = 0;
              let sumQty_15 = 0;
              let sumQty_16 = 0;

              for (let i = 0; i < data.length; i++) {
                sumQ += parseInt(data[i].total_qty);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_1 += parseInt(data[i].qty_1);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_2 += parseInt(data[i].qty_2);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_3 += parseInt(data[i].qty_3);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_4 += parseInt(data[i].qty_4);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_5 += parseInt(data[i].qty_5);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_6 += parseInt(data[i].qty_6);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_7 += parseInt(data[i].qty_7);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_8 += parseInt(data[i].qty_8);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_9 += parseInt(data[i].qty_9);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_10 += parseInt(data[i].qty_10);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_11 += parseInt(data[i].qty_11);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_12 += parseInt(data[i].qty_12);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_13 += parseInt(data[i].qty_13);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_14 += parseInt(data[i].qty_14);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_15 += parseInt(data[i].qty_15);
              }
              for (let i = 0; i < data.length; i++) {
                sumQty_16 += parseInt(data[i].qty_16);
              }

              return (
                <div key={index} style={{width: "1900px"}}>
                  <div className="table_data-Heading distributr">
                    {data[0]?.sku} - {data[0]?.wash_name}
                  </div>
                  <table className="table_distributor-data distributr" 
                  style={{ whiteSpace: "nowrap" }}
                  >
                    <tbody>
                      <tr className=" font_weight" style={{ position: "sticky", top: "-1px", backgroundColor: "#ffffff" }}>
                        <td className="font-weig" style={{width: "50px"}}>#</td>
                        <td
                          style={{ width: "70px", fontWeight: "800" }}
                          className="font-weig"
                        >
                          SKU
                        </td>
                        <td className="font-weig" style={{ width: "80px" }} >Inseam</td>
                        <td className="font-weig" style={{ width: "200px" }}>Style</td>
                        <td className="font-weig" style={{ width: "120px" }}>Wash</td>
                        <td className="font-weig" style={{ width: "120px" }}>Location</td>
                        {/* <td className="font-weig">Bulk Order</td> */}
                        <td className="font-weig" style={{ width: "140px" }}>Transaction No</td>
                        <td className="font-weig" style={{ width: "150px" }}>Transaction Type</td>
                        <td className="font-weig" style={{ width: "150px" }}>Transaction Date</td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_1}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_2}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_3}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_4}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_5}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_6}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_7}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_8}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_9}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_10}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_11}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_12}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_13}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_14}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_15}
                        </td>
                        <td
                          style={{ width: "30px", textAlign: "center" }}
                          className="font-weig"
                        >
                          {sizes[0]?.size_16}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center" }}
                        >
                          Quantity
                        </td>
                        <td
                          className="font-weig"
                          style={{ width: "1200px" }}
                        >
                          ASN/Memo/PT Status
                        </td>
                      </tr>
                      {data?.map((values, index) => {
                        const filterQuantity = data?.filter(
                          (val) => val.location_name === values.location_name
                        );

                        const totalQuantity = filterQuantity.map((qty) => {
                          return (
                            Number(qty.qty_1) +
                            Number(qty.qty_2) +
                            Number(qty.qty_3) +
                            Number(qty.qty_4) +
                            Number(qty.qty_5) +
                            Number(qty.qty_6) +
                            Number(qty.qty_7) +
                            Number(qty.qty_8) +
                            Number(qty.qty_9) +
                            Number(qty.qty_10) +
                            Number(qty.qty_11) +
                            Number(qty.qty_12) +
                            Number(qty.qty_13) +
                            Number(qty.qty_14) +
                            Number(qty.qty_15) +
                            Number(qty.qty_16)
                          );
                        });

                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{values.sku}</td>
                            <td>{values.inseam}</td>
                            <td>{values.style_name}</td>
                            <td>{values.wash_name}</td>
                            <td>{values.location_name}</td>
                            <td>{values.transaction_no}</td>
                            <td>{values.transaction_type}</td>
                            <td>
                              {moment(values.transaction_date).format(
                                "MM-DD-YYYY"
                              )}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {values.qty_1}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_2}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_3}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_4}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_5}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_6}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_7}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_8}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_9}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_10}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_11}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_12}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_13}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_14}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_15}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {values.qty_16}
                            </td>
                            {/* <td>{values.asn_no}</td> */}
                            <td
                              style={{
                                textAlign: "center",
                                paddingRight: "2px",
                              }}
                            >
                              {values.total_qty?.toString()}
                            </td>
                            <td>{values.asn_no}</td>
                          </tr>
                        );
                      })}

                      <tr>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig">Total</td>

                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_1).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_2).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_3).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_4).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_5).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_6).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_7).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_8).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_9).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_10).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_11).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_12).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_13).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_14).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_15).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQty_16).format("0,00,0")}
                        </td>
                        <td
                          className="font-weig"
                          style={{ textAlign: "center", paddingRight: "2px" }}
                        >
                          {numeral(sumQ).format("0,00,0")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })
          ) : (
            <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        ) : (
          loading && (
            <div className="loader" style={{ marginTop: "20px" }}>
              <Spin size="medium" />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default StyleLedger;
