import React, { useLayoutEffect, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CaretRightOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import { Button, Form, Input, DatePicker, Collapse, Row, Col, Select, } from "antd";
import Tables from "../../components/Table/Tables";
import { saleOrder_Adj_Columns } from "../../utils/cols";
import { getRights } from "../../redux/actions/userRights.action";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import PageHeader from "../../components/PageHeader/PageHeader";
import { ReadCookie } from "../../utils/readCookie";
import { dateChecker } from "../../components/CheckDate/checkDate";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { getBrands } from "../../redux/actions/brand.action";
const { Option } = Select;

export default function SaleOrderAdjustmentNewList() {
	TabTitle("Sale Order Adjustment")
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { Panel } = Collapse;
	const { salesOrderList, saleOrderAdjustmentList, brand } = useSelector((state) => state);
	const token = ReadCookie("token");
	const dateFormat = "MM-DD-YYYY";
	const [menuId] = useState(54);

	useEffect(() => {
		dispatch(getBrands(token))
	}, []);

	const worker = {
		order: null,
		orderAdj: null,
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		po_no: null,
		customer_code: null,
		sales_person_name: null,
		region_name: null,
		gender_name: null,
		term_name: null,
		brand_id: null
	};

	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
		});
	}, [dispatch, token, menuId]);

	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {
			dateChecker(values.from_date, values.to_date, "saleOrderAdjustmentList", token, values, dispatch);
		})
	};

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Sales Order Adjustment List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
						>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item label="Order #" name="order" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Order Adj" name="orderAdj">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												defaultValue={form.getFieldValue().fromDate} format={dateFormat} className="dateInput customInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												defaultPickerValue={form.getFieldValue().toDate} format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="PO NO:" name="po_no" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Customer" name="customer_code" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Sale Person Name" name="sales_person_name" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Region" name="region_name">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Gender" name="gender_name" rules={[{ pattern: new RegExp(/^[a-zA-Z]*$/), message: "Must be Characters." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Terms" name="term_name" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="brand_id" label="Brand">
											<Select
												size="small"
												showSearch
												allowClear
												optionFilterProp="children"
												filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())} loading={brand.loading} className="w-100">
												{brand?.payload?.data?.map((res) => (
													<Option key={res.brand_id} value={res.brand_id}>
														{res.brand_name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={salesOrderList.loading}>
										Search
									</Button>
								</div>
								<div >
									{
										saleOrderAdjustmentList?.payload ? (
											<CSVLink
												filename={"sale order-adj-list.csv"}
												data={saleOrderAdjustmentList?.payload ? saleOrderAdjustmentList?.payload[0]?.data || [] : []}
											>
												Download Excel
											</CSVLink>
										) : null
									}
								</div>
							</Form>
						</Panel>
					</Collapse>
					{saleOrderAdjustmentList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div className="productTable">
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									scroll={570}
									perPage={100}
									scrollX={"80rem"}
									col={saleOrder_Adj_Columns}
									source={saleOrderAdjustmentList?.payload && saleOrderAdjustmentList?.payload[0]?.data}
									load={saleOrderAdjustmentList.loading}
									paginate
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
