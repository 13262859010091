import React from "react";
import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd";
import { EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function CrudButtons({ invoiceId, edit, save, disable, formState, formType, onDelete, reset, name, flag, rights, saleOrderID, id, customerCode, formData, reportTittle, reportName, reportIP, orderID, customer_id, order_header_id, invoiceHeaderID, invoice_header_id, userRights, user_type, transactionLock }) {
  const history = useHistory();




  const confirmDelete = () => {
    if (formData?.ra_no) {
      Swal.fire({
        icon: "error",
        text: "Return is Already Created, You need to Delete Return First."
      })
    } else {
      onDelete();
    }
  };













  function disableBtns() {
    let result = false;




    if (!userRights?.can_edit || formState === "add") {
      result = true;
    }
    if (user_type !== "PK") {
      result = true;
    }
    else {
      result = false;
    }
    return result;
  }

  return (
    <>
      <div className="crudContainer">
        <div>
          {formType === "" ? null :
            <div className='buttons-div' >
              <Tag color={"green"} key={1}>{formType.toUpperCase()}</Tag>
              {formType !== "view" &&
                <Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className='fspx-12' />} />
              }
              {
                transactionLock && <Tag color={"red"} key={2}>LOCKED</Tag>
              }
            </div>
          }
        </div>
        <div>

          <Tooltip placement="bottom" title="Accept Payment" color="#fbfbfb">
            <Button
              type="primary"


              disabled={disableBtns()}
              onClick={() => history.push(`/customer-payment/add?mode=add`, { state: formData })}
              className="modalButton"
            >
              Accept Payment
            </Button>
          </Tooltip>
          {
            invoice_header_id && (
              <Tooltip placement="bottom" title="Return " color="#fbfbfb">
                <Button
                  disabled={disableBtns() || (formData?.is_returned === true ? true : false)}


                  type="primary"
                  onClick={() => history.push(`/return-authorization?mode=return&invoice_id=${invoice_header_id}&customer_id=${customer_id}`)}
                  className="modalButton"
                >
                  Return
                </Button>
              </Tooltip>
            )
          }

          {(formType === "formView" || formType === "view") && (

            userRights.can_print ? (
              <Link
                style={{ paddingLeft: "10px", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"

                to={{
                  pathname: `/sales-inovice-report`,
                  search: `?order_header_id=${order_header_id}&invoice_header_id=${invoice_header_id}&brand=${formData?.brand_name}`,
                  orderID
                }}
              >
                View Report
              </Link>
            ) : ""

          )}

          {/*Edit Button*/}
          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Edit" color="#fbfbfb">
                <Button






                  disabled={(userRights.can_edit === true ? false : true) || (disable || formType === "view" || formType === "add") || transactionLock}
                  onClick={() => formState("edit")}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )
          }

          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => {
                history.push("/invoice-list");
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>


          {/*Delete Button*/}
          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Delete" color="#fbfbfb">
                {rights?.can_delete === false ? (
                  <Button
                    className="iconBtn crudBTN"
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                ) : (
                  <Popconfirm
                    placement="topLeft"
                    disabled={(userRights.can_delete === true ? false : true) || transactionLock}
                    title={`Are you sure to delete this ${formData?.invoice_header_no}?`}
                    onConfirm={confirmDelete}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      disabled={(userRights.can_delete === true ? false : true) || transactionLock}
                      className="iconBtn crudBTN"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                )}
              </Tooltip>
            )
          }
          {/*Save Button*/}
          {
            (formType === "add" || formType === "edit") && (
              <Tooltip placement="bottom" title="Save" color="#fbfbfb">
                <Button
                  onClick={() => save()}
                  disabled={disable ? true : false}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<FileDoneOutlined />}
                />
              </Tooltip>
            )
          }
        </div>
      </div>
      <Divider />
    </>
  );
}
