import {
    GET_ECOMMERCE_BY_STYLE_REQUEST,
    GET_ECOMMERCE_BY_STYLE_SUCCESS,
    GET_ECOMMERCE_BY_STYLE_ERROR,
    GET_ECOMMERCE_DAILY_REQUEST,
    GET_ECOMMERCE_DAILY_SUCCESS,
    GET_ECOMMERCE_DAILY_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getEcommerceByStyle = (token, data) => (dispatch) => {
    dispatch({ type: GET_ECOMMERCE_BY_STYLE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/EcomSalesByStyle`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            from_date: moment(data.from_date).format("YYYY-MM-DD"),
            to_date: moment(data.to_date).format("YYYY-MM-DD"),
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ECOMMERCE_BY_STYLE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ECOMMERCE_BY_STYLE_ERROR,
                payload: [],
            });
        });
};

export const getEcommerceDaily = (token, values) => (dispatch) => {
    dispatch({ type: GET_ECOMMERCE_DAILY_REQUEST });
    const newData = {
        cy_start_date: values.currentYearStartDate ? values.currentYearStartDate : values.cy_start_date,
        cy_end_date: values.currentYearEndDate ? values.currentYearEndDate : values.cy_end_date,
        ly_start_date: values.lastYearStartDate ? values.lastYearStartDate : values.ly_start_date,
        ly_end_date: values.lastYearEndDate ? values.lastYearEndDate : values.ly_end_date,
    };
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/EcomSalesDaily`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData
    })
        .then((response) => {
            return dispatch({
                type: GET_ECOMMERCE_DAILY_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ECOMMERCE_DAILY_ERROR,
                payload: [],
            });
        });
}