import React, { useState, useEffect, useRef, } from "react";
import { Select, Form, Spin, Input, Button, DatePicker, Checkbox } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { useDownloadExcel } from "react-export-table-to-excel";
import printJS from "print-js";
import "./styleSheet.css";
import { header } from "./index";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { getGoodRequired } from "../../redux/actions/goodRequired.action";
import moment from "moment";
import { getGender } from "../../redux/actions/gender.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { currencySymbol } from "../../utils/constants";

const { Option } = Select;
const GoodRequired = ({ token }) => {
  TabTitle("Good Required")
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { gender, goodRequired, productStatus } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([])
  const [goodData, setGoodData] = useState([])
  const [allStatus, setAllStatus] = useState(true);
  const [isChecked2, setIsChecked2] = useState([]);
  const tableRef = useRef(null);
  
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });


  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  const isCheckboxChecked2 = (id, value) => {
    
    
    const filterCheckBox =
      productStatus &&
      productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [
        ...current,
        filterCheckBox.product_status_id,
      ]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus]);

  
  
  
  

  useEffect(() => {
    dispatch(getGender(token, menuId));
    dispatch(getProductStatus(token, menuId));
  }, [dispatch, menuId, token]);

  let result =
    goodData &&
    goodData.reduce(function (r, a) {
      r[a.sku + a.inseam] =
        r[a.sku + a.inseam] || [];
      r[a.sku + a.inseam].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);


  const data = wiseStockReport && wiseStockReport.sort((a, b) => {
    const hasRealNetA = a.some((item) => item.transtype === "RealNet");
    const hasRealNetB = b.some((item) => item.transtype === "RealNet");

    if (hasRealNetA && !hasRealNetB) return -1; 
    if (!hasRealNetA && hasRealNetB) return 1; 

    return 0; 
  });


  data && data.sort((a, b) => {
    
    const realNetA = a.find((item) => item && item.transtype === "RealNet");
    const realNetB = b.find((item) => item && item.transtype === "RealNet");
    if (realNetA != undefined && realNetB != undefined) {
      return Number(realNetA.total_qty) - Number(realNetB.total_qty);
    }
  });


  useEffect(() => {
    setLoading(true)
    dispatch(getGoodRequired(token)).then((res) => {
      setGoodData(res?.payload?.data)
      setLoading(false);
      downloadExcelFileData(res?.payload?.data);
    });
  }, [dispatch, menuId, token])


  const onFinish = async (e) => {
    await form.validateFields().then((values) => {
      values.isChecked2 = isChecked2;
      let data = {
        sku: (values.sku === undefined || values.sku === "") ? null : values.sku,
        gender_id: (values.gender_id === undefined || values.gender_id === "All") ? null : values.gender_id,
        to_date: moment(values.to_date).format("MM-DD-YYYY"),
      }
      setLoading(true)
      dispatch(getGoodRequired(token, data,isChecked2)).then((res) => {
        setGoodData(res?.payload?.data)
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);
      });
    });
  };


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "TransType": v.transtype,
          "SKU": v.sku,
          "Inseam": v.inseam,
          "Gender": v.gender_name,
          "Style": v.style_name,
          "Wash": v.wash_name,
          "Fit": v.fit_category_name,
          "Status": v.product_status_name,
          "Season": v.season_name + " " + v.new_season_year,
          "Cost": v.costprice,
          "size1": v.qty_1,
          "size2": v.qty_2,
          "size3": v.qty_3,
          "size4": v.qty_4,
          "size5": v.qty_5,
          "size6": v.qty_6,
          "size7": v.qty_7,
          "size8": v.qty_8,
          "size9": v.qty_9,
          "size10": v.qty_10,
          "size11": v.qty_11,
          "size12": v.qty_12,
          "size13": v.qty_13,
          "size14": v.qty_14,
          "size15": v.qty_15,
          "size16": v.qty_16
        };
      })
    )
  }



  const handlePrint = () => {
    if (goodRequired?.payload?.data.length > 3000) {
      printJS({
        printable: goodRequired?.payload?.data,
        
        type: "json",
        honorColor: true,
        repeatTableHeader: true,
        documentTitle: "Report",
        properties: [
          "serial_no",
          "region_name",
          "quantity",
          "net_amount",
          "percentage",
        ],
        gridHeaderStyle:
          " border: 1px solid grey; text-align:left; padding-left:10px",
        gridStyle: "border: 1px solid grey; padding-left:10px",
        header: header,
        type: "json",
      });
    } else {
      window.print();
    }
  }


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class" style={{ width: "900px" }}>
              <div className="row justify-content-between pb-2">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-4 box-space right-content-header text-right pb-0">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex printing_class mr-8">
                      <div className="print_icon printing_class" >
                        {goodRequired?.payload?.data.length ? (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            onClick={handlePrint}
                            className="fa-solid fa-print printing_class"
                          ></i>
                        ) : (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            className="fa-solid fa-print printing_class"
                          ></i>
                        )}
                      </div>
                      <div className="print_icon printing_class"
                      >
                        <CSVLink
                          filename={"Good Required.csv"}
                          data={excelData}
                          onClick={() => {
                          }}
                        >
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        </CSVLink>
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Good Required
                      <div>
                        Rpt # 006
                        <br />
                        Login as:
                        <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                          {" "}
                          {userName}
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <>
                <div className="row">
                  <div className="col">
                    <Form form={form} name="control-hooks"
                      onFinish={onFinish}
                    >
                      <div className="main_form row">
                        <div className=" col-12 ">
                          <hr className="mt-0 mb-2" />
                          <div>
                            <label htmlFor="">Product Status</label>
                            <div className="checkboxes">
                              <div className="checkbox_content">
                                <Form.Item name="current_status_id" className=" mb-0">
                                  <Checkbox
                                    checked={allStatus}
                                    onChange={statusSelectAll}

                                  >
                                    All
                                  </Checkbox>
                                </Form.Item>
                              </div>
                              <div className="checkbox_content">
                                <Form.Item name="current_status_id" className=" mb-0">
                                  <Checkbox
                                    
                                    checked={
                                      allStatus
                                        ? false
                                        : isChecked2.includes(5)
                                    }
                                    onChange={(e) =>
                                      isCheckboxChecked2(
                                        5,
                                        e.target.checked
                                      )
                                    }>
                                    Promo
                                  </Checkbox>
                                </Form.Item>
                              </div>
                              <div className="checkbox_content">
                                <Form.Item name="current_status_id" className=" mb-0">
                                  <Checkbox
                                    
                                    checked={
                                      allStatus
                                        ? false
                                        : isChecked2.includes(4)
                                    }
                                    onChange={(e) =>
                                      isCheckboxChecked2(
                                        4,
                                        e.target.checked
                                      )
                                    }>
                                    Int.Customer
                                  </Checkbox>
                                </Form.Item>
                              </div>
                              <div className="checkbox_content">
                                <Form.Item name="current_status_id" className=" mb-0">
                                  <Checkbox
                                    
                                    checked={
                                      allStatus
                                        ? false
                                        : isChecked2.includes(3)
                                    }
                                    onChange={(e) =>
                                      isCheckboxChecked2(
                                        3,
                                        e.target.checked
                                      )
                                    }>
                                    Discontinued
                                  </Checkbox>
                                </Form.Item>
                              </div>
                              <div className="checkbox_content">
                                <Form.Item name="current_status_id" className=" mb-0">
                                  <Checkbox
                                    
                                    checked={
                                      allStatus
                                        ? false
                                        : isChecked2.includes(2)
                                    }
                                    onChange={(e) =>
                                      isCheckboxChecked2(
                                        2,
                                        e.target.checked
                                      )
                                    }>
                                    Off Price
                                  </Checkbox>
                                </Form.Item>
                              </div>
                              <div className="checkbox_content">
                                <Form.Item name="current_status_id" className=" mb-0">
                                  <Checkbox
                                    checked={
                                      allStatus
                                        ? false
                                        : isChecked2.includes(1)
                                    }
                                    onChange={(e) =>
                                      isCheckboxChecked2(
                                        1,
                                        e.target.checked
                                      )
                                    }>
                                    Active Line
                                  </Checkbox>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <hr className="mt-0 mb-2" />
                          <div className="row">
                            {/* Second */}
                            <div className="col-4">
                              <div className=" d-flex flex-row align-items-baseline">
                                <label className="labels" style={{ width: "25%" }} >SKU:</label>
                                <div className="w-100">
                                  <Form.Item name="sku" className="sku_input">
                                    <Input size="small" className={"select_Inputs mb-0"} />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className=" d-flex flex-row align-items-baseline">
                                <label className="labels" style={{ width: "35%" }} >Gender:</label>
                                <div className="w-100">
                                  <Form.Item
                                    name="gender_id"
                                    labelAlign="left"
                                    initialValue={"All"}
                                  >
                                    <Select
                                      size='small'
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, options) =>
                                        options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0
                                      }
                                      className="styleInput w-100"
                                      defaultValue={form.getFieldValue().gender}
                                    >
                                      <Option value={null}>All</Option>
                                      {
                                        gender?.payload?.data.map((val) => {
                                          return (
                                            <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                          )
                                        })
                                      }
                                    </Select>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className=" d-flex flex-row align-items-baseline">
                                <label className="labels" style={{ width: "25%" }} >Date:</label>
                                <div className="w-100">
                                  <Form.Item
                                    name="to_date"
                                    className="full_date "
                                    initialValue={moment().endOf("year")}
                                  >
                                    <DatePicker
                                      size="small"
                                      defaultValue={moment().format("MM-DD-YYYY")}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                            {/* Third */}
                            <div>
                              <div className=" d-flex flex-row align-items-baseline" style={{ marginTop: "4px", float: "inline-end" }}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="submit-btn-btm m-0"
                                >
                                  Generate
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            </div>
            <hr className="mt-2 mb-2 good_required_table_heading" style={{ width: "900px", margin: "0 auto" }} />
            <div className="overflow" ref={tableRef} style={{ width: "1200px", margin: "0 auto", paddingBottom: "10px" }}>
              {!loading ? (
                wiseStockReport.length > 0 ? (
                  wiseStockReport.map((data, index) => {
                    const sizes = data;

                    let qty1 = null;
                    let qty2 = null;
                    let qty3 = null;
                    let qty4 = null;
                    let qty5 = null;
                    let qty6 = null;
                    let qty7 = null;
                    let qty8 = null;
                    let qty9 = null;
                    let qty10 = null;
                    let qty11 = null;
                    let qty12 = null;
                    let qty13 = null;
                    let qty14 = null;
                    let qty15 = null;
                    let qty16 = null;

                    for (let i = 0; i < data.length; i++) {
                      qty1 += parseInt(data[i].qty_1);
                      qty2 += parseInt(data[i].qty_2);
                      qty3 += parseInt(data[i].qty_3);
                      qty4 += parseInt(data[i].qty_4);
                      qty5 += parseInt(data[i].qty_5);
                      qty6 += parseInt(data[i].qty_6);
                      qty7 += parseInt(data[i].qty_7);
                      qty8 += parseInt(data[i].qty_8);
                      qty9 += parseInt(data[i].qty_9);
                      qty10 += parseInt(data[i].qty_10);
                      qty11 += parseInt(data[i].qty_11);
                      qty12 += parseInt(data[i].qty_12);
                      qty13 += parseInt(data[i].qty_13);
                      qty14 += parseInt(data[i].qty_14);
                      qty15 += parseInt(data[i].qty_15);
                      qty16 += parseInt(data[i].qty_16);
                    }
                    let sumQ = 0;
                    for (let i = 0; i < data.length; i++) {
                      sumQ += parseInt(data[i].sum_quantity);
                    }

                    let totalQuantity = 0;

                    for (let i = 0; i < data.length; i++) {
                      totalQuantity += parseInt(data[i].totalqty);
                    }

                    

                    return (
                      <div className="" style={{ margin: "0 auto", width: "100%" }} key={index}>
                        <div className="table_data-Heading good_required_table_heading">
                          {data[0].sku}- {data[0].inseam}- {data[0].product_status_name}
                        </div>
                        <table className="table_distributor-data good_required_table_body">
                          <tbody>
                            <tr className=" font_weight">
                              <td className="font-weig">#</td>
                              {/* <td className="font-weig" style={{ fontWeight: "800", width: "40px" }} >sno</td> */}
                              <td className="font-weig increasewidth" style={{ fontWeight: "800", width: "80px" }} >TransType</td>
                              <td className="font-weig increasewidth" style={{ fontWeight: "800", width: "60px" }} >SKU</td>
                              <td className="font-weig" style={{ fontWeight: "800", }}>Inseam</td>
                              <td className="font-weig" style={{ fontWeight: "800", }}>Gender</td>
                              <td className="font-weig" style={{ fontWeight: "800",  }}>Style</td>
                              <td className="font-weig" style={{ fontWeight: "800", }}>Wash</td>
                              <td className="font-weig" style={{ fontWeight: "800", }}>Fit</td>
                              <td className="font-weig" style={{ fontWeight: "800", }}>Status</td>
                              <td className="font-weig" style={{ fontWeight: "800", }}>Season</td>
                              <td className="font-weig" style={{ fontWeight: "800", textAlign: "right", paddingRight: "5px" }}>Cost</td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_1}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_2}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_3}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_4}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_5}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_6}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_7}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_8}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_9}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_10}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_11}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_12}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_13}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_14}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_15}
                              </td>
                              <td className="font-weig" style={{ width: "25px", textAlign: "right", paddingRight: "2px" }}>
                                {sizes[0].size_16}
                              </td>
                              <td className="font-weig" style={{ textAlign: "right", paddingRight: "5px" }} >Quantity</td>
                            </tr>
                            {data.map((values, i) => {
                              const filterQuantity = data.filter(
                                (val) => val.transtype === values.transtype
                              );

                              const totalQuantity = filterQuantity.map((qty) => {
                                return (
                                  Number(qty.qty_1) +
                                  Number(qty.qty_2) +
                                  Number(qty.qty_3) +
                                  Number(qty.qty_4) +
                                  Number(qty.qty_5) +
                                  Number(qty.qty_6) +
                                  Number(qty.qty_7) +
                                  Number(qty.qty_8) +
                                  Number(qty.qty_9) +
                                  Number(qty.qty_10) +
                                  Number(qty.qty_11) +
                                  Number(qty.qty_12) +
                                  Number(qty.qty_13) +
                                  Number(qty.qty_14) +
                                  Number(qty.qty_15) +
                                  Number(qty.qty_16)
                                );
                              });
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  {/* <td>{values.sno}</td> */}
                                  <td>{values.transtype}</td>
                                  <td>{values.sku}</td>
                                  <td style={{ textAlign: "right", paddingRight: "5px" }}>{values.inseam}</td>
                                  <td style={{ textAlign: "left", paddingLeft: "5px" }}>{values.gender_name}</td>
                                  <td style={{ textAlign: "left", paddingLeft: "5px" }}>{values.style_name}</td>
                                  <td style={{ textAlign: "left", paddingLeft: "5px" }}>{values.wash_name}</td>
                                  <td style={{ textAlign: "left", paddingLeft: "5px" }}>{values.fit_category_name}</td>
                                  <td style={{ textAlign: "left", paddingLeft: "5px" }}>{values.product_status_name}</td>
                                  <td style={{ textAlign: "left", paddingLeft: "5px" }}>{values.season_name + " " + values.new_season_year}</td>
                                  <td style={{ textAlign: "right", paddingRight: "5px" }}>{currencySymbol}{values.costprice}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_1}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_2}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_3}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_4}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_5}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_6}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_7}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_8}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_9}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_10}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_11}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_12}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_13}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_14}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_15}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{values.qty_16}</td>
                                  <td style={{ textAlign: "right", paddingRight: "4px" }}>{totalQuantity.toString()}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    );
                  })
                ) : (
                  <div className="no-Data">
                    <h1>No Data</h1>
                  </div>
                )
              ) : (
                loading && (
                  <div className="loader" style={{ marginTop: "20px" }}>
                    <Spin size="medium" />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default GoodRequired;