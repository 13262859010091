import React, { useCallback, useRef, useState } from "react";
import "./CustomerPaymentLedger.css";
import { Select, DatePicker, Form, Table, Spin, Button, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { loadCustomers } from "../../redux/actions/customerForm";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import moment from "moment";
import { useParams } from "react-router-dom";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { getVendorSummary } from "../../redux/actions/vendorLedger.action";
import { getChartOfAccount } from "../../redux/actions/Chart_All_Account.action";
import { useEffect } from "react";
import { getExpense } from "../../redux/actions/expense.action";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { currencySymbol } from "../../utils/constants";

const { Option } = Select;
const dateFormatList = ["MM/DD/YYYY"];

const Expense = ({ token }) => {
  TabTitle("Expense")
  const userName = ReadCookie("name");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const focusInput = useRef(null);
  const tableRef = useRef(null);
  const [customerList, setCutomerList] = useState([]);
  const [customerId, setCutomerId] = useState([]);
  const [excelData, setExcelData] = useState([])
  const [loading, setLoading] = useState(false);
  const {
    expense,
    chartOfAccount
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const [customer, setCustomer] = useState("All");
  const { submited } = useParams();



  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);
  let rbalance = 0;

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      dispatch(getExpense(token, values)).then((res) => {
        
        let filterData = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id != "0";
        })
        
        let filterZeroId = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id == "0";
        })
        

        setCutomerId(filterZeroId)
        setCutomerList(filterData)
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);
      })
    });
  };

  useEffect(() => {
    dispatch(getChartOfAccount(token));
  }, [dispatch, token]);

  const handlePrint = () => {
    window.print();
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Acc Type": v.accttype,
          "Trans Type": v.trans_type,
          "Transaction #": v.transaction_no,
          "Trans Date": v.trans_date,
          "Account Name": v.account_name,
          "Customer": v.vendor_name,
          "Debit": numeral(v.debit_amount).format("0,00.00"),
          "Credit": numeral(v.credit_amount).format("0,00.00"),
          "Balance": numeral(v.balance).format("0,00.00")
        };
      })
    )
  }


  const column = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Acc Type",
      dataIndex: "accttype",
      key: "accttype",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.accttype.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        if ((a.accttype !== null) & (b.accttype !== null)) {
          return a.accttype.localeCompare(b.accttype);
        }
      },
    },
    {
      title: "Trans Type",
      dataIndex: "trans_type",
      key: "trans_type",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.trans_type.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        if ((a.trans_type !== null) & (b.trans_type !== null)) {
          return a.trans_type.localeCompare(b.trans_type);
        }
      }
    },
    {
      title: "Transaction #",
      dataIndex: "transaction_no",
      key: "transaction_no",
      sorter: (a, b) => {
        if ((a.transaction_no !== null) & (b.transaction_no !== null)) {
          return a.transaction_no.localeCompare(b.transaction_no);
        }
      }
    },
    {
      title: "Trans Date",
      dataIndex: "trans_date",
      key: "trans_date",
      sorter: (a, b) => {
        if ((a.trans_date !== null) & (b.trans_date !== null)) {
          return a.trans_date.localeCompare(b.trans_date);
        }
      }
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.account_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        if ((a.account_name !== null) & (b.account_name !== null)) {
          return a.account_name.localeCompare(b.account_name);
        }
      }
    },
    {
      title: "Customer",
      dataIndex: "vendor_name",
      key: "vendor_name",
      sorter: (a, b) => {
        if ((a.vendor_name !== null) & (b.vendor_name !== null)) {
          return a.vendor_name.localeCompare(b.vendor_name);
        }
      }
    },
    {
      title: "Debit",
      dataIndex: "debit_amount",
      key: "debit_amount",
      align: "right",
      render: (text, record) => {
        return <>{currencySymbol} {numeral(text).format("0,00.00")}</>
      },
      sorter: (a, b) => {
        return a.debit_amount - b.debit_amount
      },
    },
    {
      title: "Credit",
      dataIndex: "credit_amount",
      key: "credit_amount",
      align: "right",
      render: (text, record) => {
        return <>{currencySymbol} {numeral(text).format("0,00.00")}</>
      },
      sorter: (a, b) => {
        return a.credit_amount - b.credit_amount
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      align: "right",
      render: (text, record) => {
        return <>{currencySymbol} {numeral(text).format("0,00.00")}</>
      },
      sorter: (a, b) => {
        return a.balance - b.balance
      },
    },
  ];



  const debitAmount = customerList.reduce((acc, item, index) => acc + parseFloat(item.debit_amount), 0)
  const creditAmount = customerList.reduce((acc, item, index) => acc + parseFloat(item.credit_amount), 0)
  const balanceAmount = customerList.reduce((acc, item, index) => acc + parseFloat(item.balance), 0)

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="report-wrapper-main header_class business_class ledger_print">
            <div className="row business_header justify-content-between pb-2 ledger_print">
              <div className="col-3 d-flex align-items-start  box-space">
                <div className="logobrand">
                  <img id="ImgLogo" alt="logo" src={logo} />
                </div>
              </div>

              <div className="col-4 box-space right-content-header text-right pb-0">
                <div className=" d-flex justify-content-end">
                  <div className=" d-flex printing_class mr-8">
                    <div className="print_icon printing_class">
                      <i
                        onClick={handlePrint}
                        id="Image1"
                        title="Print"
                        alt="Print"
                        src={btnPrint}
                        className="fa-solid fa-print"
                      ></i>
                    </div>
                    <div className="print_icon printing_class" >
                      <CSVLink
                        filename={"Expense.csv"}
                        data={excelData}
                        onClick={() => {
                        }}
                      >
                        <input
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={xlsx}
                          style={{ borderWidth: "0px", height: "14px" }}
                        />
                      </CSVLink>
                    </div>
                  </div>
                  <span style={{ fontWeight: "bolder" }}>
                    Expense Report
                    <br />

                    <div>
                      Rpt # 489
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {/* Filters Table */}
            <div className="row  business_header ledger_print">
              <div className="col business_header">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                  <div className="main_form row">
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          From:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="from_date"
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            />
                          </Form.Item>
                        </div>
                      </div>

                    </div>

                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          To:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="to_date"
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main_form row">
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          Account
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            name="account_id"
                            initialValue={"All"}
                          >
                            <Select
                              className={"select_Inputs"}
                              showSearch
                              optionFilterProp="children"
                              size="small"
                            >
                              <Option value={null}>All</Option>
                              {chartOfAccount?.payload?.data.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.account_id}
                                      value={item.account_id}
                                    >
                                      {`${item.account_id} ${item.account_name}`}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className=" col-6">
                      <div>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="submit-btn-btm "
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Render Data Table */}
          </div>
          <div className="table_content customer_ledger_table_content ledger_print" style={{ maxWidth: '1320px' }} >
            <div className="">
              {expense?.payload?.data !== undefined ?
                (
                  expense?.payload?.data.length > 0 ? (
                    <Table
                      className="
                
                test04 mt-3 sales_report_res_table_intransit ledger_print"
                      ref={tableRef}
                      size="small"
                      bordered={false}
                      type="company"
                      headerClassName='abc'
                      dataSource={customerList && customerList}
                      columns={column}
                      loading={expense.loading}
                      scroll={{ x: 'max-content' }}
                      pagination={{
                        defaultCurrent: 1,
                        defaultPageSize: 500,
                        pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                      }}
                      summary={(pageData) => {
                        return (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                  {currencySymbol}{debitAmount.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                  {currencySymbol}{creditAmount.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                  {currencySymbol}{balanceAmount.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="no-Data">
                      <h1>No Data</h1>
                    </div>
                  )
                ) : (
                  loading && (
                    <div className="loader" style={{ marginTop: "20px" }}>
                      <Spin size="medium" />
                    </div>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expense;
