import "../SalesReport.css";
import Logo from "../../../assets/images/logo.png";
import { useSelector } from "react-redux";
import { ReadCookie } from "../../../utils/readCookie";
import { useDispatch } from "react-redux";
import moment from "moment";
import numeral from "numeral";
import { currencySymbol } from "../../../utils/constants";
import Logo2 from "../../../assets/images/logo2.svg";

const dateFormatList = ["MM-DD-YYYY"];
const CreditPrintReportHeader = () => {
  const token = ReadCookie("token");
  const menuId = ReadCookie("menuId");
  const dispatch = useDispatch()
  const { creditPrintReport } = useSelector((state) => state);
  const urlParams = new URLSearchParams(window.location.search);
  const brand = urlParams.get('brand');
  const _logo = brand ? (brand === 'DL1961' ? Logo : Logo2) : Logo

  const headerData = creditPrintReport && creditPrintReport?.payload?.data[0];




  let sumAmount = creditPrintReport?.payload?.data.reduce(function (
    previousValue,
    currentValue
  ) {
    return (previousValue += parseFloat(
      currentValue.net_amount
    ));
  },
    0);

  return (
    <div className='sales-report'>

      <div className="credit-report-header">
        {/* header Left */}
        <div className="header-left-side ">
          {/* Logo & Text */}
          <div className="sales-report-Logo-Container">
            <div>
              <img width={250} height={72} src={_logo} />
            </div>
            {/* Logo Items */}
            <div className="sales-report-Logo_items">
              <div>DLPD CANADA LTD.</div>
              <div>15 Wertheim Court, Suite 409,</div>
              <div>Richmond Hill, ON L4B 3H7 , Canada</div>
              <div>Business # : 763395530RT0001</div>
              <div>Email : orderca@dl1961.com</div>
            </div>
            {/* Logo Items */}
          </div>
          {/* Items details */}
          <div className="items-detail-container">
            {/* Sold To */}
            <div className="sold-to-Container">
              <div className="">
                <div className="font_weight">Customer</div>
                <br />
                {/* <div className="sold-to-data">{headerData && headerData.customer_name}</div> */}
              </div>
              <div

              >
                {/* <div className="font_weight"></div> */}
                <div className="sold-to-data">{headerData && headerData.customer_name}</div>
                <div className="sold-to-data">{headerData && headerData.address_1}</div>
                <div className="sold-to-data">{headerData && headerData.address_2}</div>
                <div className="sold-to-data">{headerData && headerData.city_name + " " + headerData.state_name + " " + headerData.zip_code}</div>
                <div className="sold-to-data">{headerData && headerData.country_name}</div>
              </div>
            </div>
            {/* Sold To */}
            {/* Ship To */}
            {/* <div className="ship-to-Container">
          <div className="sold-to">
                <div className="font_weight">Ship To :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_addressee}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Name :</div>
                <div className="sold-to-data">Attn: {headerData && headerData.customer_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 1 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_1}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Address 2 :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_address_2}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">City :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_city_name}</div>
                <div className="font_weight">State :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_state_name}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Country :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_country_name}</div>
                <div className="font_weight">ZIP :</div>
                <div className="sold-to-data">{headerData && headerData.ship_to_zip}</div>
              </div>
              <div className="sold-to">
                <div className="font_weight">Dept :</div>
                <div className="sold-to-data">{headerData && headerData.department}</div>
                <div className="font_weight">DC #</div>
                <div className="sold-to-data">{headerData && headerData.dc_no}</div>
                <div className="font_weight">Store :</div>
                <div className="sold-to-data">{headerData && headerData.store_name}</div>
              </div>

          </div> */}
            {/* Ship To */}
          </div>
          {/* Items details */}
        </div>
        {/* header Left */}
        {/* header Right */}

        <div className="header-right-side2" style={{ height: "80px" }}>
          <div className="header-right-side3">
            <div className="sales-order-heading">Credit Memo#{headerData && headerData.memo_header_no}</div>
            <div className="sales-order-items-container">
              <div className="sales-order-items-left">


                {/* <div className="font_weight">CN No :</div>
              <div className="font_weight">Date :</div> */}
                {/* <div className="font_weight">Total :</div> */}
                <div className="sales_order_content">
                  {/* <div>{headerData && headerData.note_header_no}</div> */}
                  <br />
                  <div>Date:{headerData && moment(headerData.note_date).format("MM/DD/YYYY")}</div>
                  {/* <div>${sumAmount}</div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="header-right-total" style={{ marginTop: "10px" }}>
            <div className="sales-order-items-container2">
              <div className="sales-order-items-left">
                <div className="sales-order-heading">TOTAL</div>
              </div>
              <div className="sales_order_content2">
                <div>{currencySymbol}{numeral(sumAmount).format("00.00")}</div>
              </div>
            </div>
          </div>

        </div>
        {/* header Right */}
      </div>
    </div>
  );
};

export default CreditPrintReportHeader;
