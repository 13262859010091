import React from "react";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { currencySymbol } from "../../utils/constants";
export default function ({ orderItems, shippingCost }) {
	const summary = () => {
		let orderSummary = orderItems;
		let amount = 0;
		let quantity = 0;
		let discount = 0;
		let tax = 0;
		let net = 0;
		if (orderSummary.length > 0) {
			orderSummary?.forEach(function (item) {
				if (item.hasOwnProperty("rate")) {
					amount += item.rate * item.quantity;
					net += parseFloat(item.rate) * parseFloat(item.quantity);
				}
				if (item.hasOwnProperty("quantity")) {
					quantity += parseFloat(item.quantity);
				}
			});
		}
		return {
			quantity: quantity,
			amount: parseFloat(amount).toFixed(2),
			discount: parseFloat(discount).toFixed(2),
			tax: parseFloat(tax).toFixed(2),
			shippingCost: parseFloat(shippingCost).toFixed(2),
			net: parseFloat(parseFloat(amount) + parseFloat(shippingCost)).toFixed(2),
		};
	};
	return (
		<div className="firstSectionRo">
			<div className="order-summary">
				<div className="summary-title">Adjustment Summary</div>
				<Brow>
					<Bcol className="summary-heading" col={6}>
						<p className="mb-0">Total Quantity:</p>
					</Bcol>
					<Bcol className="col-6">
						<p className="mb-0 summary-value">{summary().quantity}</p>
					</Bcol>
				</Brow>
				<Brow>
					<Bcol className="summary-heading" col={6}>
						<p className="mb-0">Total Amount:</p>
					</Bcol>
					<Bcol className="col-6">
						<p className="mb-0 summary-value">{currencySymbol}{summary().amount}</p>
					</Bcol>

				</Brow>
			</div>
		</div>
	);
}