import React, { useState } from 'react'
import PageHeader from '../../components/PageHeader/PageHeader'
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Input, Select, DatePicker, Table, InputNumber, Spin } from "antd";
import { TabTitle } from '../../utils/GeneralFunction';
import * as XLSX from "xlsx";
import { upcToItemCode } from '../../redux/actions/upcToItemcode.action';
import { useDispatch } from 'react-redux';
import { ReadCookie } from '../../utils/readCookie';
import Tables from '../../components/Table/Tables';
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { CSVLink } from 'react-csv';

const { Option } = Select;
const UpcToItemCode = () => {
    TabTitle("Upc To ItemCode")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);

    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [orderItems, setOrderItems] = useState([]);

    const dispatch = useDispatch();
    const token = ReadCookie("token");

    const onFinish = async () => {}

    const columns = [
        {
            title: '#',
            dataIndex: '',
            key: '',
            width: 40,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "SKU",
            dataIndex: "parent_sku",
            key: "parent_sku",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-170"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) =>
                record.parent_sku.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
                return a.parent_sku - b.parent_sku;
            },
        },
        {
            title: "Inseam",
            dataIndex: "inseam",
            key: "inseam",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-170"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) =>
                record.inseam.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
                return a.inseam - b.inseam;
            },
        },
        {
            title: "Size",
            dataIndex: "size",
            key: "size",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-170"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) =>
                record.size.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
                return a.size - b.size;
            },
        },
        {
            title: "Item Code",
            dataIndex: "item_code",
            key: "item_code",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-170"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) =>
                record.item_code.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
                return a.item_code - b.item_code;
            },
        },
        {
            title: "Style",
            dataIndex: "style_name",
            key: "style_name",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-170"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) =>
                record.style_name.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
                return a.style_name - b.style_name;
            },
        },
        {
            title: "Wash",
            dataIndex: "wash_name",
            key: "wash_name",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-170"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) =>
                record.wash_name.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
                return a.wash_name - b.wash_name;
            },
        },
        {
            title: "Gender",
            dataIndex: "gender_name",
            key: "gender_name",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-170"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) =>
                record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
                return a.gender_name - b.gender_name;
            },
        },
        {
            title: "Gender Category",
            dataIndex: "gender_category_name",
            key: "gender_category_name",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-170"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) =>
                record.gender_category_name.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
                return a.gender_category_name - b.gender_category_name;
            },
        },
        {
            title: "UPC",
            dataIndex: "upc_code",
            key: "upc_code",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-170"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) =>
                record.upc_code.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
                return a.upc_code - b.upc_code;
            },
        },
    ];


    const fileType = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                };
            } else {
                setExcelFile(null);
                setExcelFileError("Please select only excel file types");
            }
        } else {
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();


        if (excelFile !== null) {
            setLoading(true);
            const workbook = XLSX.read(excelFile, { type: "buffer" });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            let upc_codes = [];


            if (data.length === 0) {
                alert("There Is No Item.")
            }

            for (let i = 0; i < data.length; i++) {
                upc_codes.push(data[i].UPCCODE);
            }

            dispatch(upcToItemCode(token, upc_codes.toString())).then((res) => {
                if (res?.type === "GET_UPC_TO_ITEMCODE_SUCCESS") {

                    setOrderItems(res?.payload?.data);
                    setLoading(false);
                    
                }
            })

        } else {
            setLoading(false);
            
            
        }
    };

    const downloadExcelFileData = (data) => {
        if (data.length > 0) {
            return data?.map((v) => {
                return {
                    "parent_sku": v.parent_sku,
                    "inseam": v.inseam,
                    "size": v.size,
                    "item_code": v.item_code,
                    "style_name": v.style_name,
                    "wash_name": v.wash_name,
                    "gender_name": v.gender_name,
                    "gender_category_name": v.gender_category_name,
                    "upc_code": v.upc_code,
                };
            })
        }
    }



    return (
        <div>
            <PageHeader
                title={"Upc To ItemCode"}
                minimize={setMinimize}
                check={minimize}
            />
            {!minimize && (
                <div className="bodyStyle">
                    <div className="formProduct p-0">
                        <Form onFinish={onFinish} form={form} name="control-hooks">
                            <div className="container-fluid pbpx-5 ptpx-5">
                                <Brow>
                                    <Bcol lg={6} xl={4} xxl={3}>
                                        <div className="slc">
                                            <Form.Item>
                                                <Input
                                                    type="file"
                                                    ClassName="form-control"
                                                    onChange={handleFile}
                                                
                                                
                                                
                                                />
                                                {excelFileError && (
                                                    <div className="text-danger">{excelFileError}</div>
                                                )}
                                            </Form.Item>
                                        </div>
                                    </Bcol>


                                </Brow>
                            </div>
                        </Form>


                        <Bcol lg={6} xxl={1}>
                            <div>
                                <Button
                                    type="submit"
                                    className="btn btn-success"
                                    style={{ marginTop: 5 + "px" }}
                                    onClick={handleSubmit}
                                    disabled={excelFile === null}
                                >
                                    File Submit
                                </Button>
                            </div>
                        </Bcol>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            {
                                orderItems.length > 0 ? (
                                    <CSVLink
                                        style={{ border: "1px solid black", padding: 5 }}
                                        filename={"Upc-To-ItemCode.csv"}
                                        data={downloadExcelFileData(orderItems) || []}
                                        onClick={() => {
                                        }}
                                    >
                                        Download Excel
                                    </CSVLink>
                                ) : null
                            }

                        </div>
                        <div style={{ marginTop: 20 }}>

                            <Tables
                                type="upc"
                                scrollX={"50rem"}
                                scroll={800}
                                perPage={100}
                                source={orderItems}
                                col={columns}
                                load={loading}
                                paginate
                            />
                        </div>
                    </div>


                </div>
            )}
        </div>
    )
}

export default UpcToItemCode