import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Button, Table } from "antd";
import { Row as Brow, Col as Bcol, TabPane } from "reactstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import Swal from "sweetalert2";
import { ReadCookie } from "../../utils/readCookie";
import { TabTitle } from "../../utils/GeneralFunction";
import { getBrands } from "../../redux/actions/brand.action";
import axios from "axios";
import { BASEURL, MIGRATION_URL } from "../../constant/config";
import notification from "../../utils/notification";

export default function ProductMigration({ token }) {
    TabTitle("Product Migration")
    const menuId = ReadCookie("menuId");
    const [form] = Form.useForm();
    const { Option } = Select;
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { brand } = useSelector((state) => state);
    const [loading, setLoading] = useState(false);
    const [migrationLoading, setMigrationLoading] = useState(false);

    useEffect(() => {
        dispatch(getBrands(token));
    }, [menuId, token]);

    const moveToCanada = async () => {
        setMigrationLoading(true)
        axios.post(BASEURL + `create/migrate-sku`,
            data,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            .then(function (response) {
                if (response?.status === 200) {
                    notification('success', 'Product Migration', 'Migration Successful')
                    setData([])
                    form.setFieldsValue({ sku: '', brand_id: '' })
                }
            })
            .catch(function (error) {
                console.log("🚀 ~ moveToCanada ~ error:", error)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error?.response?.data?.message || "Server Error.",
                });
            })
            .finally(function () {
                setMigrationLoading(false)
            });
    }

    const LinesColumns = [
        {
            title: "#",
            dataIndex: "index",
            width: 10,
            render: (text, object, index) => index + 1
        },
        {
            title: "Item Code",
            width: 50,
            dataIndex: "item_code",
        },
        {
            title: "Gender",
            width: 50,
            dataIndex: "gender_name",
        },
        {
            title: "Gender Category",
            width: 50,
            dataIndex: "gender_category_name",
        },
        {
            title: "Size",
            width: 50,
            dataIndex: "size",
        },
        {
            title: "Inseam",
            width: 50,
            dataIndex: "inseam",
        },
        {
            title: "Style",
            width: 50,
            dataIndex: "style_name",
        },
        {
            title: "Wash",
            width: 50,
            dataIndex: "wash_name",
        },
    ];

    const onFinish = async (e) => {
        form.validateFields().then(async values => {
            setLoading(true)
            axios.get(MIGRATION_URL + `mssql/get-sku-details?sku=${values.sku}&brand=${values.brand_id}`)
                .then(function (response) {
                    if (response?.data?.length) {
                        setData(response.data)
                    }
                })
                .catch(function (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error?.response?.status === 404 ? error?.response?.data?.message : "Failed to load data.",
                    });
                })
                .finally(function () {
                    setLoading(false)
                });
        })
    };

    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader title={"Product Migration"} />
                <div className="bodyStyle" style={{ paddingBottom: 15 }}>
                    <Form onFinish={onFinish} form={form}>
                        <Brow>
                            <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                                <Brow>
                                    <Bcol lg={6} xxl={4}>
                                        <Form.Item
                                            name="sku"
                                            label="SKU"
                                            labelCol={{ span: 7 }}
                                            rules={[{ required: true, message: "SKU is required" }]}
                                        >
                                            <Input
                                                tabIndex={18}
                                                size="small"
                                                className="customInputs"
                                            />
                                        </Form.Item>
                                    </Bcol>
                                    <Bcol lg={6} xl={4}>
                                        <div className="firstSectionRo">
                                            <Form.Item
                                                name="brand_id"
                                                label="Brand"
                                                labelAlign="left"
                                                labelCol={{ span: 7 }}
                                                rules={[{ required: true, message: "Brand ID is required" }]}
                                            >
                                                <Select
                                                    size="small"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    autoComplete={"nope"}
                                                    loading={brand?.loading}
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {brand?.payload?.data.map((val, index) => {
                                                        return (
                                                            <Option key={index} value={val.brand_id}>
                                                                {val.brand_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Bcol>
                                </Brow>
                                <Bcol lg={6} xxl={1}>
                                    <Button
                                        htmlType="submit"
                                        className="btn btn-success"
                                        style={{ marginLeft: "13px" }}
                                    >
                                        Submit
                                    </Button>
                                </Bcol>
                            </Bcol>
                        </Brow>
                    </Form>
                    {data && data.length > 0 ? (
                        <>
                            <div style={{ display: "flex", justifyContent: "right", paddingRight: 10, marginBottom: 10 }}>
                                <Button
                                    htmlType="submit"
                                    className="btn btn-success"
                                    style={{ marginLeft: 300 }}
                                    disabled={migrationLoading || !data.length}
                                    onClick={moveToCanada}
                                >
                                    Migrate
                                </Button>
                            </div>
                            <TabPane tab="Data">
                                <div className="overflow"
                                    style={{
                                        overflowY: "scroll",
                                        height: "57vh",
                                        paddingLeft: 12
                                    }}>
                                    <Table
                                        loading={loading}
                                        scroll={{ x: "max-content" }}
                                        size="small"
                                        rowKey={'index'}
                                        dataSource={data}
                                        bordered
                                        columns={LinesColumns}
                                        pagination={false}
                                    />
                                </div>
                            </TabPane>
                        </>
                    ) :
                        null}
                </div>
            </div>
        </div>
    );
}
