import { ADD_PAY_CUSTOMER_PAYMENT_LINE_REQUEST, ADD_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS, ADD_PAY_CUSTOMER_PAYMENT_LINE_ERROR, GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_ERROR, GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS, GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST, GET_CUSTOMER_PAYMENT_DETAIL_REQUEST, GET_CUSTOMER_PAYMENT_DETAIL_SUCCESS, GET_CUSTOMER_PAYMENT_DETAIL_ERROR, UPDATE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST, UPDATE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS, UPDATE_PAY_CUSTOMER_PAYMENT_LINE_ERROR, DELETE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST, DELETE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS, DELETE_PAY_CUSTOMER_PAYMENT_LINE_ERROR, } from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addCustomerPaymentLine = (token, object) => (dispatch) => {
    dispatch({ type: ADD_PAY_CUSTOMER_PAYMENT_LINE_REQUEST });
    
    
    
    
    return axios({
        method: "POST",
        url: `${BASEURL}create/payCustomerPaymentInvoice`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data: object },
    })
        .then((response) => {
            return dispatch({
                type: ADD_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_PAY_CUSTOMER_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getOneCustomerPaymentLine = (token, id) => (dispatch) => {
    dispatch({ type: GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/payCustomerPaymentInvoice/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getCustomerPaymentDetails = (token, customer_id) => (dispatch) => {
    dispatch({ type: GET_CUSTOMER_PAYMENT_DETAIL_REQUEST });
    let newData = {
        customer_id: customer_id,
    };
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/customerPaymentLines`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: GET_CUSTOMER_PAYMENT_DETAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_CUSTOMER_PAYMENT_DETAIL_ERROR,
                payload: error.response,
            });
        });
};


export const updateCustomerPaymentLine = (token, object, itemTransferId) => (dispatch) => {

    object.map((item) => {
        delete item.invoice_header_no;
        delete item.invoice_date;
        delete item.balance_amount;
        delete item.invoice_amount;
        delete item.last_updated_by;
        delete item.last_updated_date;
        delete item.new_line_add;
        delete item.edi;
        delete item.gender_name;
        delete item.brand_name;
        delete item.term_name;
        delete item.reference_no;
        return item;
    });

    const newData = object.map(v => ({ ...v, payment_header_id: Number(itemTransferId) }))

    dispatch({ type: UPDATE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/payCustomerPaymentInvoice/${itemTransferId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,

    })
        .then((response) => {
            return dispatch({
                type: UPDATE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS,
                payload: response?.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_PAY_CUSTOMER_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};


export const deleteCustomerPaymentLine = (token, data, id) => (dispatch) => {
    dispatch({ type: DELETE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST });

    data.map((val) => {
        delete val.amount_paid;
        delete val.balance_amount;
        delete val.created_by;
        delete val.created_date;
        delete val.discount_taken;
        delete val.invoice_amount;
        delete val.invoice_date;
        delete val.invoice_header_no;
        delete val.is_deleted;
        delete val.is_selected;
        delete val.last_updated_by;
        delete val.last_updated_date;
        delete val.payment;
        delete val.payment_header_id;
        delete val.invoice_header_id;
        delete val.transaction_type;
    })
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/payCustomerPaymentInvoice/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data: data }
    })
        .then((response) => {
            return dispatch({
                type: DELETE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_PAY_CUSTOMER_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};