import { useState, useRef, useCallback } from "react";
import { Select, Form, Table, Spin, Input, Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { useDownloadExcel } from "react-export-table-to-excel";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import Loader from "react-spinners/BarLoader";
import { getCustomerBalances } from "../../redux/actions/customerBalances";
import { TabTitle } from "../../utils/GeneralFunction";
import { currencySymbol } from "../../utils/constants";
const { Option } = Select;
const CustomerBalances = ({ token }) => {
  TabTitle("Customer-Balance")
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const company_id = ReadCookie("defaultCompany");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    saleOrderFormData,
    OmsReprot,
    customerBalances
  } = useSelector((state) => state);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [excelToogle, setExcelToogle] = useState(false);
  const [data, setData] = useState([])

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const handlePrint = () => {
    window.print();
  }

  const handleChanges = (val) => {
    
    dispatch(getSaleOrderFormData(token, company_id, menuId, val, ""));
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const optimizedFn = useCallback(debounce(handleChanges), []);

  const onFinish = async (e) => {
    setSubmit(true);
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getCustomerBalances(token, values)).then((res) => {
        setData(res?.payload?.data)
        setLoading(false);
      });
    });
  };

  const columns = [
    {
      title: "#",
      width: 70,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Customer #",
      dataIndex: "customer_id",
      key: "customer_id",
      width: 90,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.customer_id !== null) {
          return record.customer_id.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        
        return a.customer_id, b.customer_id
        
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 50,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.customer_name !== null) {
          return record.customer_name.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.customer_name !== null & b.customer_name !== null) {
          return a.customer_name.localeCompare(b.customer_name)
        }
      },
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoice_amount",
      key: "invoice_amount",
      width: 60,
      align: "right",
      render:(text, record)=>{
        return currencySymbol + (record.invoice_amount)
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.invoice_amount !== null) {
          return record.invoice_amount.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        
        return a.invoice_amount, b.invoice_amount
        
      },
    },
    {
      title: "Note Amount",
      dataIndex: "note_amount",
      key: "note_amount",
      align: "right",
      width: 90,
      render:(text, record)=>{
        return currencySymbol + (record.note_amount)
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.note_amount !== null) {
          return record.note_amount.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        
        return a.note_amount, b.note_amount
        
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "amount_paid",
      key: "amount_paid",
      width: 90,
      align: "right",
      render:(text, record)=>{
        return currencySymbol + (record.amount_paid)
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.amount_paid !== null) {
          return record.amount_paid.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        
        return a.amount_paid, b.amount_paid
        
      },
    },
    {
      title: "Balance",
      dataIndex: "balances",
      key: "balances",
      width: 90,
      align: "right",
      render:(text, record)=>{
        return currencySymbol + (record.balances)
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.balances !== null) {
          return record.balances.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        
        return a.balances, b.balances
        
      },
    },
  ];

  const columsPageOne = [
    {
      title: "#",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Order #",
      dataIndex: "order_header_no",
      key: "order_header_no",
      width: 75,
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      width: 90,
    },
    {
      title: "PO #",
      dataIndex: "po_no",
      key: "po_no",
      width: 50,
    },
    {
      title: "City",
      dataIndex: "city_name",
      key: "city_name",
      width: 50,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 60,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 80,
      align: "right",
    },
    {
      title: "POD Qty",
      dataIndex: "pod_quantity",
      key: "pod_quantity",
      width: 90,
    },
    {
      title: "Pending Qty",
      dataIndex: "pending_qty",
      key: "pending_qty",
      width: 90,
      align: "right",
    },
    {
      title: "Ship Qty",
      dataIndex: "ship_qty",
      key: "ship_qty",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-2">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-sm-4 box-space right-content-header text-right pb-0">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end printing_class mr-8">
                      <div className="print_icon printing_class">

                        {OmsReprot?.payload?.data.length ? (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            className="fa-solid fa-print printing_class"

                          ></i>
                        ) : (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            className="fa-solid fa-print printing_class"
                            onClick={handlePrint}
                          ></i>
                        )}
                      </div>
                      <div className="print_icon printing_class">
                        {excelToogle ? (
                          <DownloadTableExcel
                            filename="users table"
                            sheet="users"
                            currentTableRef={tableRef.current}
                          >
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              style={{ borderWidth: "0px", height: "14px" }}

                            />
                          </DownloadTableExcel>
                        ) : (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Customer Balance
                      <br />
                      Rpt # 006
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row printing_class">
                <div className="col text-sm-right pb-1">
                </div>
              </div>
              <>
                <div className="row">
                  <div className="col">
                    <Form
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <div className="main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className="labels width30">
                              Customer:
                            </label>
                            <div className=" w-100">
                              <Form.Item name="customer" initialValue={"All"}>
                                <Select
                                  notFoundContent={
                                    saleOrderFormData.loading ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Loader
                                          type="Oval"
                                          color="#3c4b58"
                                          height={20}
                                          width={20}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          textAlign: "center",
                                        }}
                                      >
                                        No Data
                                      </div>
                                    )
                                  }
                                  className={"select_Inputs"}
                                  autoComplete={"nope"}
                                  allowClear
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  loading={saleOrderFormData.loading}
                                  
                                  defaultValue={form.getFieldValue().customer}
                                  placeholder="Select Customer"
                                  onSearch={(value) => {
                                    optimizedFn(value);
                                  }}
                                >
                                  <Option value="All">All</Option>
                                  {saleOrderFormData?.payload?.customerDetail.map(
                                    (item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.customer_name}
                                          value={item.customer_name}
                                        >
                                          {" "}
                                          {`${item.customer_code} - ${item.customer_name}`}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div
                            style={{
                              marginTop: "4px",
                            }}
                          >
                            <Button
                              htmlType="submit"
                              type="primary"
                              className="submit-btn-btm ant-btn ant-btn-primary m-0"
                            >
                              Submit
                            </Button>{" "}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            </div>
            <div className="row" ref={tableRef}>
              <div className="col">
                <div className="maxWidth mt-3">
                  <>
                    {/* Report 1 */}
                    {loading ? (
                      <div className="maxWidth d-flex align-items-center justify-content-center spin-style mt-3">
                        <Spin />
                      </div>
                    ) : customerBalances?.payload?.data.length ? (
                      <Table
                        showSorterTooltip={false}
                        
                        className="maxWidth test04 myclass"
                        size="small"
                        pagination={{
                          current: 1,
                          defaultPageSize: 100,
                          pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                      }}
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        

                        
                        
                        
                        
                        
                        
                        
                        
                        

                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        bordered
                        columns={columns}
                        dataSource={
                          customerBalances?.payload?.data
                            ? customerBalances?.payload?.data
                            : []
                        }
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: "#CCCCCC",
                          borderWidth: "1pt",
                          borderStyle: "Dashed",
                          fontWeight: "bold",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        No record(s)
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerBalances;
