import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined, DeleteOutlined } from "@ant-design/icons";
import {
    DatePicker,
    Input,
    Checkbox,
    Tabs,
    Button,
    Form,
    Select,
    Tooltip,
    Card,
    Row,
    Col,
    Table,
    Popconfirm,
    InputNumber,
    Collapse,
    Skeleton,
    Spin,
    Divider,
} from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import moment from "moment";
import numeral from "numeral";
import {
    addSaleOrderAdjustmentHeader,
    updateSaleOrderAdjustmentHeader,
    getSaleOrderAdjustmentFormData,
    getOneSalesOrderAdjLine,
    deleteSalesOrderAdjLine,
    pendingOrderAdjLine,
} from "../../../../redux/actions/saleOrderAdjustment.action";
import { getSaleOrderGenderHeader } from "../../../../redux/actions/saleOrderGenderHeader.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getOneCustomerAddress } from "../../../../redux/actions/customerAddress.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { currencySymbol } from "../../../../utils/constants";

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { TextArea } = Input;

export default function SaleOrderAdjustmentNewForm({
    formData,
    val,
    token,
    formState,
    submit,
    saleOrderID,
    checkFlag,
    setFlag,
    mode,
    changeState,
    setDisable
}) {
    const focusInput = useRef(null);
    const submitButton = useRef(null);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { saleOrderFormData, ProductSale, saleOrderAdjustmentFormData, customerAddress, companyAll, systemLog, brand } =
        useSelector((state) => state);
    const companyId = ReadCookie("defaultCompany");
    const [customer, setCustomer] = useState(null);
    const [resaleExpDate, setResaleExpDate] = useState(moment());
    const [oDate, setOrderDate] = useState(moment());
    const [sDate, setStartDate] = useState(moment());
    const [visibleItems, setVisibleItems] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [isShipComplete, setIsShipComplete] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [enableEDI, setEnableeEDI] = useState(true);
    const [loading, setLoading] = useState(false);
    const [discountBasis, setDiscountBasis] = useState("");
    const [deletedItems, setDeletedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(true);
    const history = useHistory();
    const [menuId] = useState(54);

    const handleDeleteOrder = (record) => {
        const dataSource = [...orderItems];
        const deleteSource = [...deletedItems];
        if (orderItems.length > 1) {
            if (formState === "edit") {
                deleteSource.push(record)
                setDeletedItems(deleteSource);
            }
            setOrderItems(
                dataSource.filter((item) => item.item_id !== record.item_id)
            );
            notification("success", "Item Deleted");
        } else {
            return Swal.fire({
                icon: "error",
                title: "There is only 1 Line.",
                text: "Last line of the document cannot be deleted",
            });
        }
    };

    const updateQuantity = (up, itemCode, value, index) => {
        let arrCopy = [...orderItems];
        if (value > arrCopy[index].balance) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Quantity Should be Less Than Balance",
            });
            arrCopy[index].balance = arrCopy[index].balance;
        } else {
            arrCopy[index].gross_amount = numeral(value * arrCopy[index].rate).format("00.00");
            arrCopy[index].quantity = value;
        }
        setOrderItems(arrCopy);
    }

    const updateNetAmount = (value, index) => {
        let arrCopy = [...orderItems];
        arrCopy[index].net_amount = ((arrCopy[index].gross_amount - arrCopy[index].discount) + arrCopy[index].tax_amount);
        setOrderItems(arrCopy);
    }

    const updateDiscount = (p_qty, p_rate, dis_perc, index) => {
        let arrCopy = [...orderItems];
        arrCopy[index].discount = (((p_qty * p_rate) * dis_perc / 100));
        setOrderItems(arrCopy);
    };

    const updateTaxPercent = (p_qty, p_rate, tax_perc, index) => {
        let arrCopy = [...orderItems];
        arrCopy[index].tax_percent = tax_perc;
        arrCopy[index].tax_amount = (((p_qty * p_rate) * tax_perc / 100));
        setOrderItems(arrCopy);
    }

    const renderColumns = () => {
        const keys = Object.keys(systemLog?.payload?.data[0])
        return keys.map((it, i) => {
            return {
                title: it,
                dataIndex: it,
                key: i.toString(),
            }
        })
    }

    const columns = [
        {
            title: (text, record, index) => {
                return (
                    <>
                        {
                            formState === "formView" ? (
                                null
                            ) : (
                                <Checkbox
                                    checked={selectAll} onChange={(e) => selectAllHandler(e)}
                                ></Checkbox>
                            )
                        }
                    </>
                );
            },
            width: 100,
            fixed: "left",
            render: (text, record, index) => {
                return (
                    <>
                        {
                            formState === "formView" ? (
                                null
                            ) : (
                                <Checkbox
                                    checked={record?.isSelected ? true : false} onChange={(e) => selectHandler(e, orderItems.findIndex((v) => v.item_id === record.item_id))}
                                ></Checkbox>
                            )
                        }
                    </>
                );
            },
        },
        {
            title: "SKU",
            dataIndex: "item_code",
        },
        {
            title: "Style",
            dataIndex: "style_name",
        },
        {
            title: "Wash",
            dataIndex: "wash_name",
        },
        {
            title: "Cost Price",
            dataIndex: "rate",
            editable: true,
            render: (text, record) => {
                return <>{numeral(record.rate).format("0.00")}</>;
            },
        },
        {
            title: "Balance",
            editable: false,
            render: (text, record) => {
                return <>{numeral(record.balance).format("0")}</>;
            },
        },
        {
            title: "Available Quantity",
            editable: false,
            render: (text, record) => {
                return <>{numeral(record.available_quantity).format("0")}</>;
            },
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            editable: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        size="small"
                        min="0"
                        disabled={formState === "formView"}
                        value={record.quantity}
                        onChange={(value) => {
                            updateQuantity("up", record.item_code, value, index)
                            updateDiscount(value, orderItems[index].rate, orderItems[index].discount_percent, index)
                            updateTaxPercent(value, orderItems[index].rate, orderItems[index].tax_percent, index)
                            handleDiscountBasis(form.getFieldValue().discountBasis, index)
                            updateNetAmount(value, index)
                        }}
                    />
                );
            },
        },
        {
            title: "Amount",
            render: (_, record) =>
                parseFloat(record.quantity * record.rate || 0).toFixed(2),
        },
        {
            title: "Discount Percent",
            render: (_, record) =>
                parseFloat(record.discount_percent || 0).toFixed(2) + "%",
        },
        {
            title: "Discount Amount",
            render: (_, record) => parseFloat(record.discount || 0).toFixed(2),
        },
        {
            title: "Tax Percent",
            render: (_, record) => parseFloat(record.tax_percent || 0).toFixed(2) + "%",
            width: 70,
        },
        {
            title: "Tax Amount",
            render: (_, record) => parseFloat(record.tax_amount || 0).toFixed(2),
            width: 70,
        },
        {
            title: "Net Amount",
            render: (_, record) => parseFloat(record.net_amount || 0).toFixed(2),
            width: 70,
        },
        {
            title: "Operation",
            dataIndex: "operation",
            render: (_, record) => {
                return (
                    <>
                        {formState === "add" || formState === "edit" ? (
                            <>
                                <Tooltip title="Delete" color={"white"}>
                                    <Popconfirm
                                        title={`Are you sure to delete the item ${record.item_id}?`}
                                        onConfirm={() => handleDeleteOrder(record)}
                                    >
                                        <DeleteOutlined className="delete-Button" />
                                    </Popconfirm>
                                </Tooltip>
                            </>
                        ) : null}
                    </>
                )
            }
            ,
        },
    ];

    function handleChange(value, type) {
        if (type === "company") {
            companyId(value);
            form.setFieldsValue({ company: value });
        }
        if (type === "factor") {
            form.setFieldsValue({ factor: value });
        }
        if (type === "paymentTermId") {
            const Item = saleOrderFormData?.payload?.customerTerm.filter(
                (item) => item.term_id === value
            );
            const factorId = Item[0]?.factor_id;
            form.setFieldsValue({ factor: factorId });
        }
        if (type === "gender") {
            form.setFieldsValue({
                category: null,
                region: null,
                salesPerson: null,
            });
            if (customer) {
                dispatch(getSaleOrderGenderHeader(token, value, customer)).then(
                    (res) => {
                        if (
                            res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
                            res?.payload.length > 0
                        ) {
                            form.setFieldsValue({
                                category: res.payload[0].sp_region_category_id,
                                region: res.payload[0].region_id,
                                salesPerson: res.payload[0].salesperson_id,
                            });
                        }
                    }
                );
            }
        }
    }

    function handleCheckBox(e) {
        form.setFieldsValue({ [e.target.name]: e.target.checked });
        if (e.target.name === "isShipComplete") {
            setIsShipComplete(e.target.checked);
        } else if (e.target.name === "isClosed") {
            setIsClosed(e.target.checked);
        }
    }

    function handleGenderChange(value) {
        if (customer) {
            dispatch(getSaleOrderGenderHeader(token, value, customer)).then((res) => {
                if (
                    res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
                    res?.payload.length > 0
                ) {
                    form.setFieldsValue({
                        category: res.payload[0].sp_region_category_id,
                        region: res.payload[0].region_id,
                        salesPerson: res.payload[0].salesperson_id,
                    });
                } else if (
                    res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
                    res?.payload?.length === 0
                ) {
                    form.setFieldsValue({
                        category: null,
                        region: null,
                        salesPerson: null,
                    });
                }
            });
        }
    }

    useEffect(() => {
        if (mode) {
            dispatch(getSaleOrderAdjustmentFormData(token, companyId, menuId, null, null));
        }
        if (mode === "edit") {
            dispatch(getOneSalesOrderAdjLine(token, saleOrderID)).then((res) => {
                if (res.type === "GET_ONE_SALEORDERADJUSTMENT_SUCCESS") {
                    res?.payload.map((v) => {
                        v.isSelected = true;
                        return v;
                    })
                    setVisibleItems(res?.payload);
                    setOrderItems(res?.payload);
                }
                else if (res.type === "GET_ONE_SALEORDERADJUSTMENT_ERROR") {
                    notification("warning", "No Items Found", res?.payload?.data);
                }
            })
        } else if (mode === "add") {
            dispatch(pendingOrderAdjLine(token, saleOrderID)).then((res) => {
                if (res.type === "GET_ONE_PENDINGORDERADJLINE_SUCCESS") {
                    res.payload?.data.map((v) => {
                        v.isSelected = true;
                        return v;
                    })
                    setVisibleItems(res.payload?.data);
                    setOrderItems(res.payload?.data);
                } else if (res.type === "GET_ONE_PENDINGORDERADJLINE_ERROR") {
                    notification("warning", "No Items Found", res?.payload?.data.data);
                }
            });
        }
    }, [mode]);

    useEffect(() => {
        if (formData) {
            dispatch(getOneCustomerAddress(token, formData.customer_id, formData.order_header_id));
            form.setFieldsValue({
                berganBilling: formData.bergan_billing_id,
                berganBillingId: formData.bergan_billing_id,
                berganPaymentTermId: formData.bergan_payment_term_id,
                cancelDate: moment(formData.cancel_date),
                category: formData.sp_region_category_id,
                company: formData.company_id,
                customer: formData.customer_id,
                dc: formData.dc_no,
                deptDescription: formData.department_description,
                divisionCode: formData.division_code,
                divisionDescription: formData.division_description,
                discountBasis: formData.discount_basis,
                discountId: formData.discount_id,
                discountCode: formData.discount_code,
                discount: formData.discount_amount,
                discountPercent: formData.discount_percent,
                externalRefID: formData.reference_no,
                factor: formData.factor_id,
                freightAmount: formData.freight_amount,
                freightCharges: formData.freight_charges,
                freightTermId: formData.freight_term_id,
                gender: formData.gender_id,
                isClosed: formData.is_closed,
                isShipComplete: formData.is_ship_complete,
                location: formData.location_id,
                markForAddress1: formData.mark_for_address_1,
                markForAddress2: formData.mark_for_address_2,
                markForCity: formData.mark_for_city,
                markForCode: formData.mark_for_code,
                markForCompanyName: formData.mark_for_company_name,
                markForCountry: formData.mark_for_country,
                markForEmail: formData.mark_email,
                markForPhone: formData.mark_for_phone,
                markForState: formData.mark_for_state,
                markForZip: formData.mark_for_zip,
                memo: formData.remarks,
                order_adj_date: formState === "add" ? oDate : moment(formData.order_adj_date),
                orderAdjNumber: formData.order_header_adj_no,
                orderNumber: formData.order_header_no,
                orderStatus: formData.order_status_id,
                paymentTermId: formData.payment_term_id,
                po: formData.reference_no,
                region: formData.region_id,
                resaleCertificateDate: moment(resaleExpDate),
                resaleCertificateNo: formData.release_certificate_no,
                salesPerson: formData.sales_person_id,
                brand_id: formData.brand_id,
                shippingAccountNo: formData.ship_account_no,
                shippingAddressID: formData.shipping_address_id,
                shippingCost: 0,
                shippingMethodId: formData.shipping_method_id,
                shippingTaxCode: formData.shipping_tax_code == null
                    ? formData.shipping_tax_code
                    : Number(formData.shipping_tax_code),
                shippingTaxRate: formData.shipping_tax_rate,
                shipViaId: formData.ship_via_id,
                sourceType: formData.source_type,
                subsidary: formData.subsidiary_name,
                startDate: moment(formData.start_date),
                store: formData.customer_store_no,
                termForPrint: formData.term_for_print,
                bill_to_non_us: formData.bill_to_non_us,
                ship_to_non_us: formData.ship_to_non_us,
                order_type: formData.order_type_id,
                tax_duties: formData.tax_duties,
                billingCustomer: formData.billing_address_id,
                billing_firstname: formData.billing_firstname ? formData.billing_firstname : "N/A",
                billing_lastname: formData.billing_lastname ? formData.billing_lastname : "N/A",
                addressOneBilling: formData.bill_to_address_1 ? formData.bill_to_address_1 : "N/A",
                addressTwoBilling: formData.bill_to_address_2 ? formData.bill_to_address_2 : "N/A",
                cityBilling: formData.bill_to_city_name ? formData.bill_to_city_name : "N/A",
                stateBilling: formData.bill_to_state_name ? formData.bill_to_state_name : "N/A",
                zipBilling: formData.bill_to_zip_code ? formData.bill_to_zip_code : "N/A",
                countryBilling: formData.bill_to_country_name ? formData.bill_to_country_name : "N/A",
                telBilling: formData.bill_to_phone ? formData.bill_to_phone : "N/A",
                billingEmail: formData.bill_to_email ? formData.bill_to_email : "N/A",
                shippingCustomer: formData.shipping_address_id,
                shipping_firstname: formData.shipping_firstname ? formData.shipping_firstname : "N/A",
                shipping_lastname: formData.shipping_lastname ? formData.shipping_lastname : "N/A",
                addressOneShipping: formData.ship_to_address_1 ? formData.ship_to_address_1 : "N/A",
                addressTwoShipping: formData.ship_to_address_2 ? formData.ship_to_address_2 : "N/A",
                shippingEmail: formData.ship_to_email ? formData.ship_to_email : "N/A",
                cityShipping: formData.ship_to_city_name ? formData.ship_to_city_name : "N/A",
                telShipping: formData.ship_to_phone ? formData.ship_to_phone : "N/A",
                stateShipping: formData.ship_to_state_name ? formData.ship_to_state_name : "N/A",
                zipShipping: formData.ship_to_zip_code ? formData.ship_to_zip_code : "N/A",
                countryShipping: formData.ship_to_country_name ? formData.ship_to_country_name : "N/A",
            });
        }
    }, [formData])

    const onFinish = async (e) => {
        submit(false);
        if (orderItems.length === 0) {
            setDisable(false);
            return Swal.fire({
                icon: "error",
                title: "Add Line Items",
                text: "The line item shouldn't be empty.",
            });
        }
        await form.validateFields().then((values) => {
            setLoading(true);
            setDisable(true);
            values.order_header_id = formData.order_header_id;
            if (formState === "add") {
                dispatch(
                    addSaleOrderAdjustmentHeader(
                        token,
                        values,
                        companyId,
                        orderItems.filter((v) => v.isSelected === true).map((item) => {
                            delete item.isSelected;
                            item.discount = item.discount;
                            item.tax_amount = item.tax_amount;
                            item.tax_percent = item.tax_percent;
                            item.order_header_id = formData.order_header_id;
                            return item;
                        })
                    )
                ).then((res) => {
                    if (res.type === "ADD_SALEORDERADJUSTMENT_HEADER_SUCCESS") {
                        notification("success", "sale Order Adjustment", res?.payload?.data);
                        setLoading(false);
                        setDisable(false);
                        form.setFieldsValue({ orderAdjNumber: res?.payload?.order_header_adj_no });
                        history.push(`/sale-order-adjustment-new?mode=edit&order_id=${res?.payload?.order_header_adj_id}&customer_id=${formData.customer_id}`);
                    } else if (res.type === "ADD_SALEORDERADJUSTMENT_HEADER_ERROR") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res?.payload?.data?.data,
                        });
                    }
                });
            } else if (formState === "edit") {
                dispatch(
                    updateSaleOrderAdjustmentHeader(
                        token,
                        values,
                        saleOrderID,
                        companyId,
                        orderItems.filter((v) => v.isSelected === true).map((item) => {
                            item.net_amount = item.net_amount;
                            item.order_header_adj_id = formData.order_header_adj_id;
                            return item;
                        })
                    )
                ).then((res) => {
                    if (res.type === "UPDATE_SALEORDERADJUSTMENT_HEADER_SUCCESS") {
                        if (deletedItems.length > 0) {
                            dispatch(deleteSalesOrderAdjLine(token, formData?.order_header_adj_id, deletedItems));
                        }
                        setLoading(false);
                        setDisable(false);
                        notification("success", "Sale Order Update", res?.payload);
                        changeState("formView");
                    } else if (res.type === "UPDATE_SALEORDERADJUSTMENT_HEADER_ERROR") {
                        setLoading(false);
                        setDisable(false);
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res?.payload?.data?.data,
                        });
                    }
                });
            }
        });
    };

    useEffect(() => {
        if (val) {
            submitButton.current.click();
        }
    }, [val]);

    const handleTab = (key) => {
        if (key === "header") {
            dispatch(getSystemLog(token, { table_name: "scm_sale_order_adj_header", record_id: saleOrderID }));
        }
        if (key === "line") {
            dispatch(getSystemLog(token, { table_name: "scm_sale_order_adj_lines", record_id: saleOrderID }));
        }
    };

    const discountAmount = (amount, orderItem = []) => {
        let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
        if (orderItem.length > 0) {
            orderIt = orderItem;
        }
        const sum = orderIt
            .map((item) => item.amount)
            .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
        if (amount !== null || amount !== undefined) {
            if (orderIt.length > 0) {
                let calculateDiscount = orderIt.map((item) => {
                    item.discount =
                        (item.amount / 100) * parseFloat((amount / sum) * 100).toFixed(2);
                    item.discount_percent = parseFloat((amount / sum) * 100).toFixed(2);
                    item.net_amount =
                        item.amount -
                        (item.amount / 100) * parseFloat((amount / sum) * 100).toFixed(2);
                    return item;
                });
                setOrderItems(calculateDiscount);
            }
        }
    };

    const discountPercent = (percent, orderItem = []) => {
        let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
        if (orderItem.length > 0) {
            orderIt = orderItem;
        }
        if (percent !== null || percent !== undefined) {
            if (orderIt.length > 0) {
                let calculateDiscount = orderIt.map((item) => {
                    item.discount_percent = parseFloat(percent).toFixed(2) + "%";
                    item.discount = parseFloat((percent / 100) * item.amount).toFixed(2);
                    item.net_amount =
                        item.amount - parseFloat((percent / 100) * item.amount).toFixed(2);
                    return item;
                });
                setOrderItems(calculateDiscount);
            }
        }
    };

    const handleDiscountBasis = (discountBasis) => {
        const discount = form?.getFieldValue()?.discount
            ? form.getFieldValue().discount
            : 0;
        const discountPercentage = form?.getFieldValue()?.discountPercent
            ? form.getFieldValue().discountPercent
            : 0;
        let orderItem = orderItems.length > 0 ? orderItems : visibleItems;
        if (discountBasis === "A") {
            if (discount !== null || discount !== undefined) {
                discountAmount(parseFloat(discount).toFixed(0), orderItem);
            }
            setDiscountBasis("A");
        } else if (discountBasis === "P") {
            if (discountPercentage !== null || discountPercentage !== undefined) {
                discountPercent(parseFloat(discountPercentage).toFixed(0), orderItem);
            }
            setDiscountBasis("P");
        } else {
            setDiscountBasis("");
        }
    };

    const onSearch = (val) => {
        setCustomer(val);
        if (val.length > 2) {
            dispatch(getSaleOrderAdjustmentFormData(token, companyId, menuId, val, "")).then(
                (res) => {
                    if (
                        res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
                        res?.payload.length === 0
                    ) {
                        notification("warning", "empty List");
                    }
                    if (res?.payload?.customerDetail?.length === 0) {
                        notification("warning", "Customer Not Found");
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (checkFlag === true) {
            form.setFieldsValue({
                addressOneBilling: null,
                berganBilling: null,
                billingAddressID: null,
                billingCustomer: null,
                billingEmail: null,
                category: null,
                cityBilling: null,
                cityShipping: null,
                company: null,
                countryBilling: null,
                countryShipping: null,
                customer: null,
                dc: null,
                deptDescription: null,
                divisionCode: null,
                divisionDescription: null,
                endDate: null,
                externalRefID: null,
                freightAmount: null,
                freightCharges: null,
                freightTermId: null,
                gender: null,
                isClosed: false,
                isShipComplete: false,
                location: null,
                memo: null,
                order_adj_date: null,
                orderStatus: null,
                paymentTermId: null,
                subsidary: null,
                po: null,
                region: null,
                resaleCertificateNo: null,
                salesPerson: null,
                brand_id: null,
                shippingAccountNo: null,
                shippingAddressID: null,
                shippingCost: null,
                shippingCustomer: null,
                shippingEmail: null,
                shippingMethodId: null,
                shippingTaxCode: null,
                shippingTaxRate: null,
                shipViaId: null,
                sourceType: null,
                startDate: null,
                stateBilling: null,
                stateShipping: null,
                store: null,
                termForPrint: null,
                zipBilling: null,
                zipShipping: null,
            });
            setVisibleItems([]);
            setFlag(false);
        }
    }, [checkFlag]);

    const onFinishFailed = () => {
        let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors[0]?.errors[0],
        });
    };

    const sourceTypeChange = (e) => {
        if (e.target.value === "bergan-edi") setEnableeEDI(false);
        else setEnableeEDI(true);
    };

    const summary = () => {
        let orderSummary = orderItems.length > 0 ? orderItems : visibleItems;
        let amount = 0;
        let quantity = 0;
        let discount = 0;
        let tax = 0;
        let taxAmount = 0;
        let shippingCost = form.getFieldValue().shippingCost
            ? form.getFieldValue().shippingCost
            : 0;
        let net = 0;
        if (orderSummary.length > 0) {
            orderSummary?.forEach(function (item) {
                if (item.hasOwnProperty("rate")) {
                    net += parseFloat(item.rate) * parseFloat(item.quantity);
                }
                if (item.hasOwnProperty("quantity")) {
                    quantity += parseFloat(item.quantity);
                    amount += item.rate * item.quantity;
                    taxAmount += parseFloat(item.tax_amount);
                }
                if (item.hasOwnProperty("discount")) {
                    discount += parseFloat(item.discount);
                }
            });
        }
        return {
            quantity: quantity || 0,
            amount: numeral(amount).format("0,0.00"),
            discount: numeral(discount || 0).format("0,0.00"),
            tax: numeral(tax).format("0,0.00"),
            taxAmount: numeral(taxAmount || 0).format("0,0.00"),
            shippingCost: numeral(shippingCost).format("0,0.00"),
            net: numeral(
                numeral(amount).value() +
                numeral(shippingCost).value() -
                numeral(discount).value() +
                numeral(taxAmount).value()
            ).format("0,0.00"),
        };
    };

    const selectAllHandler = (e) => {
        let copyArr = [...orderItems];
        setSelectAll(!selectAll);
        if (e.target.checked) {
            copyArr.map((v) => v.isSelected = true);
        }
        else {
            copyArr.map((v) => v.isSelected = false);
        };
        setOrderItems(copyArr);
    }

    const selectHandler = (e, index) => {
        let copyArr = [...orderItems];
        let deleteItem = [...deletedItems];
        if (e.target.checked) {
            copyArr[index].isSelected = true;
        }
        else {
            copyArr[index].isSelected = false;
        };
        let findSelect = copyArr.find((v) => !v?.isSelected);
        if (findSelect) {
            setSelectAll(false);
        } else {
            setSelectAll(true);
        }
        setOrderItems(copyArr);
        setDeletedItems(deleteItem);
    }

    return (
        <>
            <Form
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
                name="basic"
            >
                {
                    saleOrderAdjustmentFormData.loading ||
                    (loading && (
                        <div className="loaderDiv">
                            <Loader type="Grid" color="#212121" height={40} width={40} />
                        </div>
                    ))}
                <div className="saleOrderForm">
                    <SubTitles name={"Primary Information"} />
                    <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
                        <Brow>
                            <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                                <Brow>
                                    <Bcol lg={6} xl={4} xxl={3}>
                                        <div className="firstSectionRo">
                                            <Form.Item
                                                name="orderAdjNumber"
                                                label="Order Adj #"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                            >
                                                <Input
                                                    size="small"
                                                    disabled
                                                    className={`customInputs`}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Bcol>
                                    <Bcol lg={6} xl={4} xxl={3}>
                                        <div className="firstSectionRo">
                                            <Form.Item
                                                name="orderNumber"
                                                label="Order #"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                            >
                                                <a target="_blank" href={`/sale-order?mode=edit&order_id=${formData?.order_header_id}&customer_id=${formData?.customer_id}`}>
                                                    {formData?.order_header_no}
                                                </a>
                                            </Form.Item>
                                        </div>
                                    </Bcol>
                                    <Bcol lg={6} xl={4} xxl={3}>
                                        <div className="firstSectionRo">
                                            <Form.Item
                                                label="Adj Date"
                                                name="order_adj_date"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    { required: true, message: "Adjustment Date is required" },
                                                ]}
                                            >
                                                <DatePicker
                                                    size="small"
                                                    disabled
                                                    defaultValue={
                                                        formState === "edit"
                                                            ? form.getFieldValue().order_adj_date
                                                            : moment(oDate)
                                                    }
                                                    format="MM-DD-YYYY"
                                                    allowClear
                                                    className={`dateInput customInputs`}
                                                    onChange={(e) => setOrderDate(moment(e))}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Bcol>
                                    <Bcol lg={6} xl={4} xxl={3}>
                                        <div className="firstSectionRo">
                                            <Form.Item
                                                name="customer"
                                                label="Customer"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    { required: true, message: "Please Select Customer" },
                                                ]}
                                            >
                                                <Select
                                                    size="small"
                                                    tabIndex={1}
                                                    autoComplete={"nope"}
                                                    allowClear
                                                    showSearch
                                                    optionFilterProp="children"
                                                    loading={saleOrderAdjustmentFormData.loading}
                                                    disabled
                                                    defaultValue={form.getFieldValue().customer}
                                                    onSearch={onSearch}
                                                    ref={focusInput}
                                                >
                                                    {saleOrderAdjustmentFormData?.payload?.customerDetail.map(
                                                        (item) => {
                                                            return (
                                                                <Option
                                                                    key={item.customer_id}
                                                                    value={item.customer_id}
                                                                >
                                                                    {" "}
                                                                    {`${item.customer_code} - ${item.customer_name}`}
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Bcol>
                                    <Bcol lg={6} xl={4} xxl={3}>
                                        <div className="firstSectionRo">
                                            <Form.Item
                                                name="po"
                                                label="P.O #"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                            >
                                                <Input
                                                    tabIndex={1}
                                                    disabled
                                                    size="small"
                                                    className={`customInputs`}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Bcol>
                                    <Bcol lg={6} xl={4} xxl={3}>
                                        <div className="firstSectionRo">
                                            <Form.Item
                                                name="sourceType"
                                                label="Source Type"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                            >
                                                <Input
                                                    size="small"
                                                    disabled
                                                    onChange={sourceTypeChange}
                                                    className={`customInputs`}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Bcol>
                                    <Bcol lg={6} xl={4} xxl={3}>
                                        <div className="slc">
                                            <Form.Item name="subsidary"
                                                label="Subsidary"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                wrapperCol={{ span: 16 }}>
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    size="small"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    disabled
                                                >
                                                    {companyAll?.payload?.data.map((item) => {
                                                        return (
                                                            <Option
                                                                key={item.company_id}
                                                                value={item.company_name}
                                                            >
                                                                {item.company_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Bcol>
                                </Brow>
                            </Bcol>
                            <Bcol md={4} xl={3} xxl={2}>
                                <div className="firstSectionRo">
                                    <div className="order-summary">
                                        <div className="summary-title">Adjustment Summary</div>
                                        <Brow>
                                            <Bcol className="summary-heading" col={6}>
                                                <p className="mb-0">Total Quantity:</p>
                                            </Bcol>
                                            <Bcol className="col-6">
                                                <p className="mb-0 summary-value">
                                                    {summary().quantity}
                                                </p>
                                            </Bcol>
                                        </Brow>
                                        <Brow>
                                            <Bcol className="summary-heading" col={6}>
                                                <p className="mb-0">Sub Total:</p>
                                            </Bcol>
                                            <Bcol className="col-6">
                                                <p className="mb-0 summary-value">
                                                    {currencySymbol}{summary().amount}
                                                </p>
                                            </Bcol>
                                        </Brow>
                                        <Brow>
                                            <Bcol className="summary-heading" col={6}>
                                                <p className="mb-0">Less Discount:</p>
                                            </Bcol>
                                            <Bcol className="col-6">
                                                <p className="mb-0 summary-value">
                                                    {currencySymbol}{summary().discount}
                                                </p>
                                            </Bcol>
                                        </Brow>
                                        <Brow>
                                            <Bcol className="summary-heading" col={6}>
                                                <p className="mb-0">Add Tax:</p>
                                            </Bcol>
                                            <Bcol className="col-6">
                                                <p className="mb-0 summary-value">
                                                    {currencySymbol}{summary().taxAmount}
                                                </p>
                                            </Bcol>
                                        </Brow>
                                        <Brow>
                                            <Bcol className="summary-heading" col={6}>
                                                <p className="mb-0">Add Shipping Cost:</p>
                                            </Bcol>
                                            <Bcol className="col-6">
                                                <p className="mb-0 summary-value">
                                                    {currencySymbol}{summary().shippingCost}
                                                </p>
                                            </Bcol>
                                        </Brow>
                                        <Divider className="my-1" />
                                        <Brow>
                                            <Bcol className="summary-heading" col={6}>
                                                <p className=" mb-0">Total:</p>
                                            </Bcol>
                                            <Bcol className="col-6">
                                                <p className="mb-0 summary-value">{currencySymbol}{summary().net}</p>
                                            </Bcol>
                                        </Brow>
                                    </div>
                                </div>
                            </Bcol>
                        </Brow>
                        <Brow className="secondSectio">
                            <Bcol xl={6} xxl={6}>
                                <Card
                                    size="large"
                                    className="cardTitle cardDesignNew"
                                    title="Billing Information"
                                >
                                    <div className="secondSectionRow">
                                        {customerAddress?.loading ? <Skeleton
                                            paragraph={{ rows: 6 }}
                                        /> :
                                            <Row justify="space-between">
                                                <Col xs={24} sm={24} md={24} lg={24}>
                                                    <Form.Item
                                                        name="billingCustomer"
                                                        label="Name"
                                                        className="name-field-form selectNamee"
                                                        labelAlign="left"
                                                        labelCol={{
                                                            xs: { span: 24 },
                                                            sm: { span: 4 },
                                                            md: { span: 4 },
                                                            lg: { span: 2 },
                                                        }}
                                                    >
                                                        <Select
                                                            size="small"
                                                            tabIndex={4}
                                                            autoComplete={"nope"}
                                                            disabled
                                                            showSearch={true}
                                                            className="customInputs fw-400"
                                                            loading={customerAddress?.loading}
                                                        >
                                                            {customerAddress.payload?.data?.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item.customer_address_id}>{" "}
                                                                        {`${item.address_addressee}(${item.address_zip_code})`}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="billing_firstname"
                                                        label={`First Name`}
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="billing_lastname"
                                                        label="Last Name"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="addressOneBilling"
                                                        label="Address 1"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <TextArea
                                                            autoSize={true}
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="addressTwoBilling"
                                                        label="Address 2"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <TextArea
                                                            autoSize={true}
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="cityBilling"
                                                        label="City"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="stateBilling"
                                                        label="State"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="zipBilling"
                                                        label="Zip"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="countryBilling"
                                                        label="Country"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="telBilling"
                                                        label="Tel"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="billingEmail"
                                                        label="Email"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </Card>
                            </Bcol>
                            <Bcol xl={6} xxl={6}>
                                <Card
                                    size="large"
                                    className="cardTitle cardDesignNew"
                                    title="Shipping Information"
                                >
                                    {customerAddress?.loading ? <Skeleton
                                        paragraph={{ rows: 6 }}
                                    /> :
                                        <div className="secondSectionRow">
                                            <Row justify="space-between">
                                                <Col xs={24} sm={24} md={24} lg={24}>
                                                    <Form.Item
                                                        name="shippingCustomer"
                                                        label="Name"
                                                        labelAlign="left"
                                                        className="name-field-form"
                                                        labelCol={{
                                                            xs: { span: 24 },
                                                            sm: { span: 4 },
                                                            md: { span: 4 },
                                                            lg: { span: 2 },
                                                        }}
                                                    >
                                                        <Select
                                                            size="small"
                                                            tabIndex={5}
                                                            autoComplete={"nope"}
                                                            showSearch={true}
                                                            className="customInputs fw-400"
                                                            disabled
                                                            loading={customerAddress?.loading}
                                                        >
                                                            {customerAddress.payload?.data?.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item.customer_address_id}>{" "}
                                                                        {`${item.address_addressee}(${item.address_zip_code})`}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="shipping_firstname"
                                                        label="First Name"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="shipping_lastname"
                                                        label="Last Name"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="addressOneShipping"
                                                        label="Address 1"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <TextArea
                                                            autoSize={true}
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="addressTwoShipping"
                                                        label="Address 2"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <TextArea
                                                            autoSize={true}
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="cityShipping"
                                                        label="City"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="stateShipping"
                                                        label="State"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="zipShipping"
                                                        label="Zip"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="countryShipping"
                                                        label="Country"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="telShipping"
                                                        label="Tel"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12}>
                                                    <Form.Item
                                                        name="shippingEmail"
                                                        label="Email"
                                                        labelAlign="left"
                                                        labelCol={{ span: 4 }}
                                                    >
                                                        <Input
                                                            bordered={false}
                                                            disabled
                                                            className={`customInputs without_bg fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Card>
                            </Bcol>
                        </Brow>
                        <Button
                            style={{ display: "none" }}
                            ref={submitButton}
                            onClick={onFinish}
                        />
                    </div>
                    <SubTitles name={"Date"} />
                    <div className="container-fluid pbpx-5 ptpx-5">
                        <Brow>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        label="Start Date"
                                        name="startDate"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                        rules={[
                                            { required: true, message: "Start Date is required" },
                                        ]}
                                    >
                                        <DatePicker
                                            size="small"
                                            tabIndex={6}
                                            format="MM-DD-YYYY"
                                            disabled
                                            allowClear
                                            className={`dateInput customInputs fw-400 `}
                                            onChange={(e) =>
                                                setStartDate(moment(e).format("MM-DD-YYYY"))
                                            }
                                            defaultValue={moment(sDate)}
                                        />
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="cancelDate"
                                        label="Cancel Date"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Cancel Date is required",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            size="small"
                                            tabIndex={8}
                                            disabledDate={(d) => !d || d.isBefore(moment())}
                                            format="MM-DD-YYYY"
                                            allowClear
                                            className={`dateInput customInputs fw-400  `}
                                            disabled
                                        />
                                    </Form.Item>
                                </div>
                            </Bcol>
                        </Brow>
                    </div>
                    <SubTitles name={"Sales Information"} />
                    <div className="container-fluid pbpx-5 ptpx-5">
                        <Brow>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="gender"
                                        label="Gender"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                        rules={[{ required: true, message: "Gender is required" }]}
                                    >
                                        <Select
                                            size="small"
                                            tabIndex={9}
                                            autoComplete={"nope"}
                                            filterOption={(input, option) =>
                                                option?.children
                                                    ?.toLowerCase()
                                                    .indexOf(input?.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            allowClear
                                            loading={saleOrderAdjustmentFormData.loading}
                                            disabled
                                            defaultValue={
                                                formState === "edit"
                                                    ? form.getFieldValue().gender
                                                    : null
                                            }
                                            onChange={(value) => handleGenderChange(value)}
                                        >
                                            {saleOrderAdjustmentFormData?.payload?.gender.map((item) => {
                                                return (
                                                    <Option
                                                        key={item.gender_id}
                                                        disabled={!item.is_active}
                                                        value={item.gender_id}
                                                    >
                                                        {item.gender_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="region"
                                        label="Region"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                        rules={[{ required: true, message: "Region is required" }]}
                                    >
                                        <Select
                                            size="small"
                                            tabIndex={10}
                                            autoComplete={"nope"}
                                            filterOption={(input, option) =>
                                                option?.children
                                                    ?.toLowerCase()
                                                    .indexOf(input?.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            allowClear
                                            loading={saleOrderAdjustmentFormData.loading}
                                            disabled
                                            defaultValue={form.getFieldValue().region}
                                        >
                                            {saleOrderAdjustmentFormData?.payload?.adminRegionDetail.map(
                                                (item) => {
                                                    return (
                                                        <Option key={item.region_id} value={item.region_id}>
                                                            {item.region_name}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="category"
                                        label="Category"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                    >
                                        <Input
                                            size="small"
                                            tabIndex={11}
                                            className={`customInputs fw-400  `}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="location"
                                        label="Location"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                        rules={[
                                            { required: true, message: "Location is required" },
                                        ]}
                                    >
                                        <Select
                                            size="small"
                                            autoComplete={"nope"}
                                            filterOption={(input, option) =>
                                                option?.children
                                                    ?.toLowerCase()
                                                    .indexOf(input?.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            allowClear
                                            loading={saleOrderAdjustmentFormData.loading}
                                            disabled
                                            defaultValue={form.getFieldValue().location}
                                            onChange={(value) => handleChange(value)}
                                        >
                                            {saleOrderAdjustmentFormData?.payload?.locationDetail.map(
                                                (item) => {
                                                    return (
                                                        <Option
                                                            key={item.location_id}
                                                            value={item.location_id}
                                                        >
                                                            {item.location_name}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="salesPerson"
                                        label="Sales Person"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                        rules={[
                                            { required: true, message: "Sales Person is required" },
                                        ]}
                                    >
                                        <Select
                                            size="small"
                                            tabIndex={12}
                                            autoComplete={"nope"}
                                            filterOption={(input, option) =>
                                                option?.children
                                                    ?.toLowerCase()
                                                    .indexOf(input?.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            allowClear
                                            loading={saleOrderAdjustmentFormData.loading}
                                            disabled
                                        >
                                            {saleOrderAdjustmentFormData?.payload?.salesPersonDetail.map(
                                                (item) => {
                                                    return (
                                                        <Option
                                                            key={item.sales_person_id}
                                                            value={item.sales_person_id}
                                                        >
                                                            {item.salesperson_name}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="brand_id"
                                        label="Brand"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                        rules={[
                                            { required: true, message: "Brand is required" },
                                        ]}
                                    >
                                        <Select
                                            size="small"
                                            tabIndex={12}
                                            autoComplete={"nope"}
                                            filterOption={(input, option) =>
                                                option?.children
                                                    ?.toLowerCase()
                                                    .indexOf(input?.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            allowClear
                                            loading={brand.loading}
                                            disabled
                                        >
                                            {brand?.payload?.data.map(
                                                (item) => {
                                                    return (
                                                        <Option
                                                            key={item.brand_id}
                                                            value={item.brand_id}
                                                        >
                                                            {item.brand_name}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Bcol>
                        </Brow>
                    </div>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={1}
                        className="pbpx-10 bg-white"
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                    >
                        <Panel header="Classification" key="1">
                            <Tabs
                                size="small"
                                type="card"
                                tabBarGutter={1}
                                defaultActiveKey="shipping"
                                centered
                                onChange={(key) => handleTab(key)}
                            >
                                <TabPane tab="Shipping" key="shipping">
                                    <div className="container-fluid pbpx-0 ptpx-5">
                                        <Brow>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="shippingAccountNo"
                                                        label="Shipping Account No"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            defaultValue={0}
                                                            disabled
                                                            size="small"
                                                            tabIndex={14}
                                                            className={`customInputs fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="shipViaId"
                                                        label="Ship Via"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Ship Via is required",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            size="small"
                                                            tabIndex={15}
                                                            autoComplete={"nope"}
                                                            disabled
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            defaultValue={form.getFieldValue().shipViaId}
                                                            onChange={(value) => handleChange(value)}
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.shipVia.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.courier_service_id}
                                                                            disabled={!item.is_active}
                                                                            value={item.courier_service_id}
                                                                        >
                                                                            {item.service_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="shippingMethodId"
                                                        label="Shipping Method"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select Shipping Method",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className={``}
                                                            size="small"
                                                            tabIndex={16}
                                                            autoComplete={"nope"}
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled
                                                            defaultValue={
                                                                form.getFieldValue().shippingMethodId
                                                            }
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.adminShipMethod.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.shipping_method_id}
                                                                            disabled={!item.is_active}
                                                                            value={item.shipping_method_id}
                                                                        >
                                                                            {item.shipping_method_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="shippingCost"
                                                        label="Shipping Cost"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Shipping Cost is required",
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            defaultValue={0}
                                                            size="small"
                                                            onInput={(val) => {
                                                                form.setFieldsValue({ shippingCost: val });
                                                            }}
                                                            tabIndex={17}
                                                            disabled
                                                            className={`customInputs fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="shippingTaxCode"
                                                        label="Shipping Tax Code"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Select
                                                            size="small"
                                                            tabIndex={18}
                                                            autoComplete={"nope"}
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled
                                                            defaultValue={
                                                                form.getFieldValue().shippingTaxCode
                                                            }
                                                            onChange={(value) => handleChange(value)}
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.adminShipTaxCode.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.ship_tax_id}
                                                                            disabled={!item.is_active}
                                                                            value={item.ship_tax_id}
                                                                        >
                                                                            {item.ship_tax_description}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="shippingTaxRate"
                                                        label="Shipping Tax Rate"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <InputNumber
                                                            size="small"
                                                            tabIndex={19}
                                                            disabled
                                                            className={`w-100 customInputs fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="freightTermId"
                                                        label="Freight Term"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please Select Freight Term",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            size="small"
                                                            tabIndex={20}
                                                            autoComplete={"nope"}
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled
                                                            defaultValue={form.getFieldValue().freightTermId}
                                                            onChange={(value) => handleChange(value)}
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.freightTerm.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.freight_term_id}
                                                                            disabled={!item.is_active}
                                                                            value={item.freight_term_id}
                                                                        >
                                                                            {item.freight_term_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="freightAmount"
                                                        label="Freight Amount"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <InputNumber
                                                            defaultValue={0}
                                                            size="small"
                                                            tabIndex={21}
                                                            disabled
                                                            className={`customInputs w-100 `}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="freightCharges"
                                                        label="Freight Charges"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <InputNumber
                                                            defaultValue={0}
                                                            size="small"
                                                            tabIndex={22}
                                                            className={`customInputs w-100 `}
                                                            disabled
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="berganBillingId"
                                                        label="Bergan Billing"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please Select Bergan Billing",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            size="small"
                                                            name="berganBillingIad"
                                                            autoComplete={"nope"}
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled
                                                            defaultValue={
                                                                form.getFieldValue().berganBillingId
                                                            }
                                                            onChange={(value) =>
                                                                handleChange(value, "berganBillingId")
                                                            }
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.berganBilling.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.bergan_billing_id}
                                                                            disabled={!item.is_active}
                                                                            value={item.bergan_billing_id}
                                                                        >
                                                                            {item.bergan_billing_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="berganPaymentTermId"
                                                        label="Bergan Payment Term"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please Select Bergan Payment Term",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            size="small"
                                                            name="py"
                                                            autoComplete={"nope"}
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled
                                                            defaultValue={form.getFieldValue().paymentTermId}
                                                            onChange={(value) =>
                                                                handleChange(value, "paymentTermId")
                                                            }
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.berganPaymentTerm.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.bergen_payment_term_id}
                                                                            disabled={!item.is_active}
                                                                            value={item.bergen_payment_term_id}
                                                                        >
                                                                            {item.term_description}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="isShipComplete"
                                                        label="Ship Complete"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Checkbox
                                                            tabIndex={23}
                                                            name="isShipComplete"
                                                            checked={isShipComplete}
                                                            onChange={(e) => handleCheckBox(e)}
                                                            disabled={
                                                                !(formState === "add" || formState === "edit")
                                                            }
                                                        ></Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}></Bcol>
                                        </Brow>
                                    </div>
                                </TabPane>
                                <TabPane tab="Billing" key="billing">
                                    <div className="container-fluid pbpx-0 ptpx-15">
                                        <Brow>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="termForPrint"
                                                        label="Term For Print"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                    >
                                                        <Select
                                                            size="small"
                                                            autoComplete={"nope"}
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled
                                                            defaultValue={form.getFieldValue().termForPrint}
                                                            onChange={(value) => handleChange(value)}
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.termForPrint.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.term_id}
                                                                            disabled={!item.is_active}
                                                                            value={item.term_id}
                                                                        >
                                                                            {item.term_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="paymentTermId"
                                                        label="Payment Term"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please Select Payment Term",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            size="small"
                                                            name="paymentTermId"
                                                            autoComplete={"nope"}
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled
                                                            defaultValue={form.getFieldValue().paymentTermId}
                                                            onChange={(value) =>
                                                                handleChange(value, "paymentTermId")
                                                            }
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.customerTerm.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.term_id}
                                                                            disabled={!item.is_active}
                                                                            value={item.term_id}
                                                                        >
                                                                            {item.term_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                        </Brow>
                                    </div>
                                </TabPane>
                                <TabPane disabled={enableEDI} tab="EDI" key="edi">
                                    <div className="firstSection">
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForCode"
                                                label="Code"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    { required: !enableEDI, message: "Code is required" },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    readOnly
                                                    className={`customInputs `}
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForCompanyName"
                                                label="Company Name"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    {
                                                        required: !enableEDI,
                                                        message: "Company Name is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    readOnly
                                                    className={`customInputs`}
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForAddress1"
                                                label="Address 1"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    {
                                                        required: !enableEDI,
                                                        message: "Address 1 is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    readOnly
                                                    className={`customInputs`}
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForAddress2"
                                                label="Address 2"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    {
                                                        required: !enableEDI,
                                                        message: "Address 2 is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    className={`customInputs`}
                                                    readOnly
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForCity"
                                                label="City"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    { required: !enableEDI, message: "City is required" },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    readOnly
                                                    className={`customInputs`}
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForState"
                                                label="State"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    {
                                                        required: !enableEDI,
                                                        message: "State is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    readOnly
                                                    className={`customInputs`}
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForZip"
                                                label="Zip"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    { required: !enableEDI, message: "Zip is required" },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    readOnly
                                                    className={`customInputs`}
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForCountry"
                                                label="Country"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    {
                                                        required: !enableEDI,
                                                        message: "Country is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    className={`customInputs`}
                                                    readOnly
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForEmail"
                                                label="Email"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    {
                                                        required: !enableEDI,
                                                        message: "Email is required",
                                                    },
                                                    {
                                                        type: "email",
                                                        message: "Only email allowed",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    type="email"
                                                    className={`customInputs`}
                                                    readOnly
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="firstSectionRow">
                                            <Form.Item
                                                name="markForPhone"
                                                label="Phone"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                rules={[
                                                    {
                                                        required: !enableEDI,
                                                        message: "Phone is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    size="small"
                                                    readOnly
                                                    className={`customInputs`}
                                                    disabled={
                                                        !(formState === "add" || formState === "edit") ||
                                                        enableEDI
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Other" key="other">
                                    <div className="container-fluid pbpx-5 ptpx-15">
                                        <Brow>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="orderStatus"
                                                        label="Order Status"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please Select Order Status",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            size="small"
                                                            autoComplete={"nope"}
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled
                                                            defaultValue={form.getFieldValue().orderStatus}
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.orderStatusDetail.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.order_status_id}
                                                                            value={item.order_status_id}
                                                                        >
                                                                            {item.status_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="memo"
                                                        label="Remarks"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            size="small"
                                                            disabled
                                                            className={`customInputs`}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="factor"
                                                        label="Factor"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Select
                                                            size="small"
                                                            autoComplete={"nope"}
                                                            filterOption={(input, option) =>
                                                                option?.children
                                                                    ?.toLowerCase()
                                                                    .indexOf(input?.toLowerCase()) >= 0
                                                            }
                                                            showSearch
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled
                                                            defaultValue={
                                                                formState === "edit"
                                                                    ? form.getFieldValue().factor
                                                                    : null
                                                            }
                                                            onChange={(value) => handleChange(value)}
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.factorDetail.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.factor_id}
                                                                            disabled={!item.is_active}
                                                                            value={item.factor_id}
                                                                        >
                                                                            {item.factor_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="dc"
                                                        label="DC#"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <InputNumber
                                                            size="small"
                                                            className={`customInputs fw-400 `}
                                                            disabled
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="resaleCertificateNo"
                                                        label="Resale Certificate"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            size="small"
                                                            className={`customInputs fw-400 `}
                                                            disabled
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        label="Resale Expiry Date"
                                                        name="resaleCertificateDate"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <DatePicker
                                                            size="small"
                                                            disabled
                                                            defaultValue={
                                                                formState === "edit"
                                                                    ? form.getFieldValue().resaleCertificateDate
                                                                    : null
                                                            }
                                                            format="MM-DD-YYYY"
                                                            allowClear
                                                            className="dateInput customInputs"
                                                            onChange={(e) => setResaleExpDate(moment(e))}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="store"
                                                        label="Store"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            size="small"
                                                            disabled
                                                            className={`customInputs fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="deptDescription"
                                                        label="Department Desc"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            size="small"
                                                            disabled
                                                            className={`customInputs`}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="divisionDescription"
                                                        label="Division Desc"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            size="small"
                                                            disabled
                                                            className={`customInputs fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="divisionCode"
                                                        label="Division Code"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            size="small"
                                                            disabled
                                                            className={`customInputs fw-400 `}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="bill_to_non_us"
                                                        label="Bill To Non-US"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            size="small"
                                                            className={`customInputs`}
                                                            disabled={formState === "formView"}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="ship_to_non_us"
                                                        label="Ship To Non-US"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            size="small"
                                                            className={`customInputs`}
                                                            disabled={formState === "formView"}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="order_type"
                                                        label="Order Type"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Select
                                                            size="small"
                                                            autoComplete={"nope"}
                                                            allowClear
                                                            loading={saleOrderAdjustmentFormData.loading}
                                                            disabled={!(formState === "add" || formState === "edit")}
                                                            defaultValue={form.getFieldValue().order_type}
                                                        >
                                                            {saleOrderAdjustmentFormData?.payload?.scmOrderType?.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={item.order_type_id}
                                                                            value={item.order_type_id}
                                                                        >
                                                                            {item.order_type_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="tax_duties"
                                                        label="Tax & Duties"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Input
                                                            size="small"
                                                            className={`customInputs`}
                                                            disabled={formState === "formView"}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="isClosed"
                                                        label="Closed"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                    >
                                                        <Checkbox
                                                            checked={isClosed}
                                                            name="isClosed"
                                                            onChange={(e) => handleCheckBox(e)}
                                                            disabled
                                                        ></Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                        </Brow>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Panel>
                    </Collapse>
                    <SubTitles name={"Discount"} />
                    <div className="container-fluid pbpx-5 ptpx-5">
                        <Brow>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="discountId"
                                        label="Discount"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                    >
                                        <Select
                                            size="small"
                                            tabIndex={12}
                                            autoComplete={"nope"}
                                            filterOption={(input, option) =>
                                                option?.children
                                                    ?.toLowerCase()
                                                    .indexOf(input?.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            allowClear
                                            disabled
                                            loading={saleOrderAdjustmentFormData.loading}
                                            defaultValue={form.getFieldValue().discountId}
                                        >
                                            {saleOrderAdjustmentFormData?.payload?.adminDiscount &&
                                                saleOrderAdjustmentFormData?.payload?.adminDiscount.map(
                                                    (item) => {
                                                        return (
                                                            <Option
                                                                key={item.discount_id}
                                                                value={item.discount_id}
                                                            >
                                                                {item.discount_name}
                                                            </Option>
                                                        );
                                                    }
                                                )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="discountCode"
                                        label="Discount Code"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                    >
                                        <Input
                                            size="small"
                                            disabled
                                            className={`customInputs `}
                                        />
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="discountBasis"
                                        label="Discount Type"
                                        labelAlign="left"
                                        labelCol={{ span: 10 }}
                                    >
                                        <Select
                                            size="small"
                                            tabIndex={9}
                                            autoComplete={"nope"}
                                            filterOption={(input, option) =>
                                                option?.children
                                                    ?.toLowerCase()
                                                    .indexOf(input?.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            allowClear
                                            loading={saleOrderAdjustmentFormData.loading}
                                            disabled
                                            defaultValue={
                                                formState === "edit"
                                                    ? form.getFieldValue().discountBasis
                                                    : null
                                            }
                                            onChange={(value) => handleDiscountBasis(value)}
                                        >
                                            <Option key={1} value={"A"}>
                                                Amount
                                            </Option>
                                            <Option key={2} value={"P"}>
                                                Percent
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol
                                className={discountBasis === "A" ? "d-block" : "d-none"}
                                lg={6}
                                xxl={3}
                            >
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="discount"
                                        label="Discount Amount"
                                        labelAlign="left"
                                        labelCol={{ span: 6 }}
                                    >
                                        <InputNumber
                                            min={0}
                                            defaultValue={0}
                                            size="small"
                                            onInput={(val) => {
                                                form.setFieldsValue({ discount: val });
                                            }}
                                            disabled
                                            onChange={discountAmount}
                                            className={`customInputs fw-400  `}
                                        />
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol
                                className={discountBasis === "P" ? "d-block" : "d-none"}
                                lg={6}
                                xxl={3}
                            >
                                <div className="firstSectionRo">
                                    <Form.Item
                                        name="discountPercent"
                                        label="Discount Percent"
                                        labelAlign="left"
                                        labelCol={{ span: 6 }}
                                    >
                                        <InputNumber
                                            min={0}
                                            defaultValue={0}
                                            size="small"
                                            onInput={(val) => {
                                                form.setFieldsValue({ discountPercent: val });
                                            }}
                                            disabled
                                            onChange={discountPercent}
                                            className={`customInputs fw-400  `}
                                        />
                                    </Form.Item>
                                </div>
                            </Bcol>
                        </Brow>
                    </div>
                    <Tabs
                        size="small"
                        type="card"
                        tabBarGutter={2}
                        defaultActiveKey="productInfo"
                        centered
                        onChange={(key) => handleTab(key)}
                    >
                        <TabPane tab="Product Information" key="productInfo">
                            <div className="pbpx-20">
                            </div>
                            {(orderItems.length > 0 || visibleItems.length > 0) ? (
                                <Table
                                    type={"company"}
                                    loading={ProductSale.loading}
                                    scroll={({ x: 600 }, { y: 400 })}
                                    size="small"
                                    bordered
                                    dataSource={orderItems}
                                    columns={columns}
                                    pagination={false}
                                />
                            ) : (<Spin />)}
                        </TabPane>
                        {(formState === "edit" || formState === "formView") &&
                            <TabPane tab="System Information" key="logs" >
                                <Tabs
                                    type="card"
                                    size="small"
                                    defaultActiveKey="1"
                                    centered
                                    onChange={(key) => handleTab(key)}
                                >
                                    <TabPane tab="Header" key="header">
                                        <div className="tabs">
                                            {systemLog?.payload?.data?.length > 0 && <Tables
                                                type="company"
                                                scrollX={"50rem"}
                                                scroll={360}
                                                perPage={15}
                                                source={systemLog?.payload?.data}
                                                dataSource={[]}
                                                col={renderColumns()}
                                                load={systemLog.loading}
                                                paginate
                                            />}
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Line" key="line">
                                        <div className="tabs">
                                            {systemLog?.payload?.data?.length > 0 && <Tables
                                                type="company"
                                                scrollX={"50rem"}
                                                scroll={360}
                                                perPage={15}
                                                source={systemLog?.payload?.data}
                                                dataSource={[]}
                                                col={renderColumns()}
                                                load={systemLog.loading}
                                                paginate
                                            />}
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </TabPane>}
                    </Tabs>
                </div>
            </Form>
        </>
    );
}
