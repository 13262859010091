import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import numeral from "numeral";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DatePicker, Input, Tabs, Button, Form, Select } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getARAccounts } from "../../../../redux/actions/AR_Account.action";
import { addFundTransfer, updateFundTransfer } from "../../../../redux/actions/fundTransfer.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { getCustomerPayment } from "../../../../redux/actions/AR_Account.action";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
import { companyID, currencySymbol } from "../../../../utils/constants";
const { Option } = Select;
const { TabPane } = Tabs;

export default function FundTransferForm({ formData, val, token, formState, submit, saleOrderID, setDisable }) {

	const dispatch = useDispatch();
	const submitButton = useRef(null);
	const [form] = Form.useForm();
	const { itemTransferFormData, invoiceGlPeriod, companyAll, customrPayment, systemLog, glImpact } = useSelector((state) => state);
	const [companyId] = useState(ReadCookie("defaultCompany"));
	const [oDate, setOrderDate] = useState(moment());
	const [loading, setLoading] = useState(false);
	const [HeaderNo, setHeaderNo] = useState(false);
	const history = useHistory();
	const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));

	useLayoutEffect(() => {
		dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
			let result = res?.payload?.find((item) => {
				return item.period_name === currentDate
			});
			setCurrentDate(result?.period_id);
			form.setFieldsValue({ postingPeriod: result?.period_id });
		});
	}, [token, dispatch]);

	useEffect(() => {
		dispatch(getAllCompany(token));
		dispatch(getARAccounts(token));
		dispatch(getCustomerPayment(token));
	}, []);

	useEffect(() => {
		if (formState === "formView") {
			dispatch(getGLImpact(token, { "transaction_header_id": saleOrderID, "transaction_type": "TRANSFER" }));
		}
	}, [formState]);

	useEffect(() => {
		if (formState === "add") {
			form.setFieldsValue({
				transferNo: null,
				date: oDate,
				fromAccount: null,
				ToAccount: null,
				postingPeriod: currentDate,
				amount: null,
				memo: null,
				subsidiary: companyID,
			});
		}
	}, [formState]);

	useEffect(() => {
		if (formData) {
			setHeaderNo(formData?.transfer_header_no)
			form.setFieldsValue({
				transferNo: Number(formData?.transfer_header_no) && Number(formData?.transfer_header_no),
				date: moment(formData?.transfer_date),
				fromAccount: formData.from_account_id,
				ToAccount: formData.to_account_id,
				postingPeriod: formData.gl_period_id,
				amount: formData.transfer_amount,
				memo: formData.memo,
				subsidiary: formData.company_id
			});
		}
	}, [formData]);

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	function handleChange(value, type) {
		if (type === "company") {
			companyId(value);
			form.setFieldsValue({ company: value });
		}
	}

	const onFinish = async (e) => {
		submit(false);
		await form.validateFields().then((values) => {
			setLoading(true);
			setDisable(true);
			if (formState === "add") {
				dispatch(addFundTransfer(token, values, parseInt(companyId))).then((res) => {
					let HeaderNumber = res?.payload?.transfer_header_id;
					setHeaderNo(HeaderNumber)
					if (res.type === "ADD_FUND_TRANSFER_SUCCESS") {
						notification("success", "Fund Transfer Created", "Fund Transfer created successfully!");
						setLoading(false);
						setDisable(false);
						history.push({
							pathname: `/transfer/edit/${HeaderNumber}`,
							search: `?_id=${HeaderNumber}`
						})
					}
					else if (res.type === "ADD_FUND_TRANSFER_ERROR") {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					} else if (res.type === "ADD_FUND_TRANSFER_ERROR") {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});

			} else if (formState === "edit") {
				dispatch(updateFundTransfer(token, values, saleOrderID)).then((res) => {
					if (res.type === "UPDATE_FUND_TRANSFER_SUCCESS") {
						setLoading(false);
						setDisable(false);
						notification("success", "Fund Transfer Updated", "Fund Transfer Updated Successfully");
					} else if (res.type === "UPDATE_FUND_TRANSFER_ERROR") {
						setLoading(false);
						setDisable(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			}
		});
	};

	const handleTab = (key) => {
		if (key === "logs") {
			dispatch(getSystemLog(token, { table_name: "gl_fund_transfer_header", record_id: saleOrderID }));
		}
	}

	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
		setDisable(false)
	};

	const glImpcatCols = [

		{
			title: "Account Name",
			dataIndex: "account_name",
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record, index) => {
				return (
					<>
						{currencySymbol} {numeral(record.debit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record, index) => {
				return (
					<>
						{currencySymbol} {numeral(record.credit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]

	const renderColumns = () => {
		const keys = Object.keys(systemLog?.payload?.data[0])
		return keys.map((it, i) => {
			return {
				title: it,
				dataIndex: it,
				key: i.toString(),
			}
		})
	}

	function checkTransactionDate(e) {
		let transaction_date = moment(e).format("YYYY-MM-DD")
		setLoading(true)
		dispatch(getTransationLock(token, transaction_date)).then((r) => {
			setLoading(false)
			if (r.payload.data.length > 0) {
				setOrderDate(moment())
				form.setFieldsValue({
					date: moment()
				})
				Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
			}
			else {
				setOrderDate(moment(e))
			}
		});
	}

	return (
		<>
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				{itemTransferFormData.loading ||
					(loading && (
						<div className="loaderDiv">
							<Loader type="Grid" color="#212121" height={40} width={40} />
						</div>
					))}
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Brow>
							<Bcol md={12} xl={9} xxl={10} className="pbpx-10">
								<Brow>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="transferNo"
												label="Transfer Number"
												labelAlign="left"
												labelCol={{ span: 6 }}
												defaultValue={form.getFieldValue().transferNo}
											>
												<Input tabIndex={1} size="small" className="customInputs" disabled={true} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Date" name="date" labelAlign="left" labelCol={{ span: 6 }} rules={[{ required: true, message: "Bill Date is required" }]}>
												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"

													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().date
															: moment(oDate)
													}
													onChange={(e) => {
														setOrderDate(moment(e))
														let result = invoiceGlPeriod?.payload?.find((item) => {
															return item.period_name === moment(e).format("MMM YYYY")
														});
														setCurrentDate(result?.period_id);
														form.setFieldsValue({ postingPeriod: result?.period_id });
														checkTransactionDate(e);
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="From Account" name="fromAccount" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().fromAccount} rules={[{ required: true, message: "From Account is required" }]}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={customrPayment.loading}
													defaultValue={form.getFieldValue().fromAccount}
													onChange={(value) => {
														form.setFieldsValue({
															fromAccount: value,
														});
													}}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{customrPayment?.payload?.data.map((item) => {
														return (
															<Option key={item.account_id} value={item.account_id}>
																{item.account_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="To Account" name="ToAccount" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().ToAccount} rules={[{ required: true, message: "To Account required" }]}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={customrPayment.loading}
													defaultValue={form.getFieldValue().ToAccount}
													onChange={(value) => {
														form.setFieldsValue({
															ToAccount: value,
														});
													}}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{customrPayment?.payload?.data.map((item) => {
														return (
															<Option key={item.account_id} value={item.account_id}>
																{item.account_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().postingPeriod} rules={[{ required: true, message: "PostingPeriod is required" }]}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="amount" label="Amount" labelAlign="left" labelCol={{ span: 6 }} rules={[{ required: true, pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
												<Input tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 6 }}>
												<Input tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
							</Bcol>
						</Brow>
						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
					<SubTitles name={"Classification"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Bcol md={12} xl={9} xxl={10} className="pbpx-10">
							<Brow>
								<Bcol lg={6} xxl={4}>
									<div className="firstSectionRo">
										<Form.Item
											name="subsidiary"
											label="Subsidiary"
											labelAlign="left"
											labelCol={{ span: 6 }}
											rules={[{ required: true, message: "Subsidiary is required" }]}
										>
											<Select
												size="small"
												tabIndex={18}
												autoComplete={"nope"}
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												showSearch
												allowClear
												loading={companyAll.loading}
												disabled={!(formState === "add" || formState === "edit")}
												defaultValue={form.getFieldValue().subsidiary}
												onChange={(value) => handleChange(value)}
											>
												{companyAll?.payload?.data.map((item) => {
													return (
														<Option key={item.company_id} value={item.company_id}>
															{item.company_name}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</div>
								</Bcol>
							</Brow>
						</Bcol>
					</div>
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="GL Impact" key="gl_impact">
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={glImpact?.payload?.data}
										col={glImpcatCols}
										load={glImpact.loading}
										paginate
									/>
								</div>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="System Information" key="logs" >
								<div className="tabs">
									{systemLog?.payload?.data?.length > 0 && <Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={systemLog?.payload?.data}
										dataSource={[]}
										col={renderColumns()}
										load={systemLog?.loading}
										paginate
									/>}
								</div>
							</TabPane>}
					</Tabs>
				</div>
			</Form>
		</>
	);
}
