import React, { useEffect, useState } from "react";
import { Spin, Select, DatePicker, Form, Button, Table, Input } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import csv from "../../assets/images/csv.png";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { getChartOfAccount } from "../../redux/actions/Chart_All_Account.action";
import { getJournalLedger } from "../../redux/actions/journalLedger.action";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from "exceljs";
import { currencySymbol } from "../../utils/constants";
const { Option } = Select;


const JournalLedger = ({ token }) => {
  TabTitle("General Ledger")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    chartOfAccount,
    journalLedger
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [excelData, setExcelData] = useState([])

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let account_id = query.get("account_id");
  let to_date = query.get("date_to");

  

  useEffect(() => {
    if (account_id) {
      let data = {
        account_id: account_id,
        to_date: moment(JSON.parse(to_date).to_date).format("MM-DD-YYYY"),
        from_date: "01-01-2023"
      };
      
      form.setFieldsValue({
        account_id: data.account_id,
        to_date: moment(data.to_date),
        from_date: moment(data.from_date),
      });
      setLoading(true)
      dispatch(getJournalLedger(token, data)).then((res) => {
        setLoading(false)
        setInventoryList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }
  }, [account_id])

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((data) => {
      dispatch(getJournalLedger(token, data)).then((res => {
        setLoading(false)
        setInventoryList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      }));
    });
  };

  useEffect(() => {
    dispatch(getChartOfAccount(token));
  }, [dispatch, menuId, token]);


  const handlePrint = () => {
    window.print();
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Account": v.account_search_display_name,
          "Transaction Type": v.transaction_type,
          "Transaction No": v.transaction_no,
          "Transaction Date": moment(v.transaction_date).format("MM-DD-YYYY"),
          "Customer": v.customer_name,
          "Debit": `${currencySymbol} ${numeral(v.debit).format("0,00.00")}`,
          "Credit": `${currencySymbol} ${numeral(v.credit).format("0,00.00")}`,
        };
      })
    )
  }

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.columns = [
      { header: 'Account', key: 'account_search_display_name', width: 30 },
      { header: 'Transaction Type', key: 'transaction_type', width: 30 },
      { header: 'Transaction No', key: 'transaction_no', width: 30 },
      { header: 'Transaction Date', key: 'transaction_date', width: 15 },
      { header: 'Customer', key: 'customer_name', width: 30 },
      { header: 'Debit', key: 'debit', width: 15 },
      { header: 'Credit', key: 'credit', width: 15 },
    ];


    let totalDebit = 0;
    let totalCredit = 0;
    
    journalLedger?.payload?.data.forEach((item) => {
      worksheet.addRow({ account_search_display_name: item.account_search_display_name, transaction_type: item.transaction_type, transaction_no: item.transaction_no, transaction_date: item.transaction_date, customer_name: item.customer_name, debit: `${currencySymbol} ${numeral(item.debit).format("0,00.00")}`, credit: `${currencySymbol} ${numeral(item.credit).format("0,00.00")}` });
      totalDebit += Number(item.debit);
      totalCredit += Number(item.credit);
    });
    worksheet.addRow({ debit: numeral(totalDebit).format("0,00.00"), credit: numeral(totalCredit).format("0,00.00")});

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      
      const filename = 'Journal-Ledger.xlsx';

      
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();

      
    } catch (error) {
      console.error(error);
    }
  };

  function generatePDF() {
    var doc = new jsPDF();

    
    var columns = ["Account", "Transaction Type", "Transaction No", "Transaction Date", "Customer", "Debit", "Credit"];
    var rows = [];

    
    journalLedger?.payload?.data.forEach(item => {
      var row = [item.account_search_display_name, item.transaction_type, item.transaction_no, item.transaction_date, item.customer_name, currencySymbol + numeral(item.debit).format("0,00.00"), currencySymbol + numeral(item.credit).format("0,00.00")];
      rows.push(row);
    });

    const debit = journalLedger?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.debit), 0)
    const credit = journalLedger?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.credit), 0)

    
    doc.autoTable({
      head: [columns],
      body: rows,
      styles: { fontSize: 6 },
    })

    const startY = doc.lastAutoTable.finalY || 10; 
    const footerRow = ["", "", "", "", "", "", "", "", "", "","", "", "", "", "", "","", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "Total:", currencySymbol + numeral(debit).format("0,00.00"), currencySymbol + numeral(credit).format("0,00.00")];
    doc.autoTable({
      body: [footerRow],
      startY: startY + 2,
      styles: { fontSize: 6 },
    });

    
    doc.save("Journal-Ledger.pdf");
  }


  const debitTotal = journalLedger?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.debit), 0)
  const creditTotal = journalLedger?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.credit), 0)


  const columns = [
    {
      title: "#",
      render: (text, record, index) => {
        return index + 1
      }
    },
    {
      title: "Account",
      dataIndex: "account_search_display_name",
      key: "account_search_display_name",
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transaction_type !== null) {
          return record.transaction_type
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transaction_type !== null) & (b.transaction_type !== null)) {
          return a.transaction_type.localeCompare(b.transaction_type);
        }
      },
    },
    {
      title: "Transaction No",
      dataIndex: "transaction_no",
      key: "transaction_no",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transaction_no !== null) {
          return record.transaction_no
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transaction_no !== null) & (b.transaction_no !== null)) {
          return a.transaction_no.localeCompare(b.transaction_no);
        }
      },
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transaction_date !== null) {
          return record.transaction_date
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transaction_date !== null) & (b.transaction_date !== null)) {
          return a.transaction_date.localeCompare(b.transaction_date);
        }
      },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (text, record) => {
        return <>{currencySymbol} {numeral(text).format("0,00.00")}</>
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.debit !== null) {
          return record.debit
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.debit !== null) & (b.debit !== null)) {
          return a.debit.localeCompare(b.debit);
        }
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (text, record) => {
        return <>{currencySymbol} {numeral(text).format("0,00.00")}</>
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.credit !== null) {
          return record.credit
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.credit !== null) & (b.credit !== null)) {
          return a.credit.localeCompare(b.credit);
        }
      },
    },
  ];

  return (
    <div className="header_class business_header_oms intransit">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class" >
                <CSVLink
                  filename={"General-Ledger.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgCSV"
                    alt="Export to CSV"
                    id="ImgCSV"
                    title="Export to CSV"
                    src={csv}
                    
                    
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
              <div className="print_icon printing_class" >
                <input
                  type="image"
                  name="ImgExcel"
                  alt="Export to Excel"
                  id="ImgExcel"
                  title="Export to Excel"
                  src={xlsx}
                  onClick={exportToExcel}
                  
                  style={{ borderWidth: "0px", height: "14px" }}
                />
              </div>
              <div className="print_icon printing_class" >
                <input
                  type="image"
                  name="ImgPDF"
                  alt="Export to PDF"
                  id="ImgPDF"
                  title="Export to PDF"
                  src={pdf}
                  onClick={generatePDF}
                  
                  style={{ borderWidth: "0px", height: "18px" }}
                />
                {/* <button onClick={() => generatePDF()}>Generate PDF</button> */}
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              General Ledger
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label htmlFor="" className="labels width30">
                      Account
                    </label>
                    <div style={{ width: "200px" }}>
                      <Form.Item
                        name="account_id"
                        initialValue={"All"}
                        className="w-100"
                      >
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {chartOfAccount?.payload?.data.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.account_id}
                                >
                                  {`${item.account_id} ${item.account_name}`}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">From</label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment().startOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">To</label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                        
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div style={{ marginTop: "4px", float: "left" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
              <hr className="my-1" />
            </div>
          </div>
        </Form>
      </div>
      <div
        
        className="table_content international_order"
        style={{ maxWidth: '1250px' }}
      >
        <div>
          {journalLedger?.payload?.data !== undefined ?
            (
              journalLedger?.payload?.data.length > 0 ? (
                <Table
                  className="
                
                test04 mt-3 sales_report_res_table_intransit"
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName='abc'
                  dataSource={inventoryList}
                  columns={columns}
                  loading={journalLedger.loading}
                  pagination={{ pageSize: 500 }}
                  scroll={{ x: 'max-content' }}
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                              {currencySymbol}{debitTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                              {currencySymbol}{creditTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              ) : (
                <div className="no-Data">
                  {/* {} */}
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default JournalLedger;
