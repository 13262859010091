import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
    Button,
    Form,
    Table,
    Spin,
    DatePicker,
    Input,
} from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import moment from "moment";
import csv from "../../assets/images/csv.png";
import "jspdf-autotable";
import { getOnlineOrdersSummary } from "../../redux/actions/bookSummary.action";

const OnlineOrdersSummary = () => {
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const dispatch = useDispatch();
    const token = ReadCookie("token");
    const [dscoInvoiceList, setDscoInvoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);
    const { commissionReport } = useSelector((state) => state);
    const [excelData, setExcelData] = useState([]);

    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (text, record, index) => {
                return index + 1;
            },
            width: 40,
        },
        {
            title: "P.O #",
            dataIndex: "po_no",
            key: "po_no",
        },
        {
            title: "Sub Total",
            dataIndex: "sub_total",
            key: "sub_total",
            render: (text, record) => {
                return numeral(record.sub_total).format("0,0.00");
            },
            align: "right",
        },
        {
            title: "Shipping Cost",
            dataIndex: "shipping_cost",
            key: "shipping_cost",
            render: (text, record) => {
                return numeral(record.shipping_cost).format("0,0.00");
            },
            align: "right",
        },
        {
            title: "Tax Amount",
            dataIndex: "add_tax",
            key: "add_tax",
            align: "right",
        },
        {
            title: "Discount Code",
            dataIndex: "discount_code",
            key: "discount_code",
        },
        {
            title: "Discount Amount",
            dataIndex: "discount_amount",
            key: "discount_amount",
        },
        {
            title: "Total",
            dataIndex: "total",
            key: "total",
            render: (text, record) => {
                return numeral(record.total).format("0,0.00");
            },
            align: "right",
        },
        {
            title: "Ship Via",
            dataIndex: "ship_via_name",
            key: "ship_via_name",
        },
        {
            title: "Wash Style",
            dataIndex: "wash_style_name",
            key: "wash_style_name",
        },
        {
            title: "SKU Inseam Size",
            dataIndex: "sku",
            key: "sku",
        },
        {
            title: "Net Amount",
            dataIndex: "net_amount",
            key: "net_amount",
            render: (text, record) => {
                return numeral(record.net_amount).format("0,0.00");
            },
            align: "right",
        },
        {
            title: "First Last Name",
            dataIndex: "bill_addressee",
            key: "bill_addressee",
        },
        {
            title: "Address 1",
            dataIndex: "bill_address_1",
            key: "bill_address_1",
        },
        {
            title: "Address 2",
            dataIndex: "bill_address_2",
            key: "bill_address_2",
        },
        {
            title: "City",
            dataIndex: "bill_city_name",
            key: "bill_city_name",
        },
        {
            title: "Zip Code",
            dataIndex: "bill_zip_code",
            key: "bill_zip_code",
        },
        {
            title: "State",
            dataIndex: "bill_state_name",
            key: "bill_state_name",
        },
        {
            title: "Country",
            dataIndex: "bill_country_name",
            key: "bill_country_name",
        },
        {
            title: "First Last Name",
            dataIndex: "ship_addressee",
            key: "ship_addressee",
        },
        {
            title: "Address 1",
            dataIndex: "ship_address_1",
            key: "ship_address_1",
        },
        {
            title: "Address 2",
            dataIndex: "ship_address_2",
            key: "ship_address_2",
        },
        {
            title: "City",
            dataIndex: "ship_city_name",
            key: "ship_city_name",
        },
        {
            title: "Zip Code",
            dataIndex: "ship_zip_code",
            key: "ship_zip_code",
        },
        {
            title: "State",
            dataIndex: "ship_state_name",
            key: "ship_state_name",
        },
        {
            title: "Country",
            dataIndex: "ship_country_name",
            key: "ship_country_name",
        },
        {
            title: "BackOrder",
            dataIndex: "quantity_backorder",
            key: "quantity_backorder",
            align: "right",
        },
        {
            title: "Commited",
            dataIndex: "quantity_committed",
            key: "quantity_committed",
            align: "right",
        },
        {
            title: "Packed",
            dataIndex: "quantity_packed",
            key: "quantity_packed",
            align: "right",
        },
        {
            title: "Available",
            align: "right",
        },
    ];

    const onFinish = async () => {
        setLoading(true);
        await form
            .validateFields()
            .then((values) => {
                dispatch(getOnlineOrdersSummary(token, values)).then((res) => {
                    setLoading(false);
                    setDscoInvoiceList(res?.payload?.data);
                    downloadExcelFileData(res?.payload?.data);
                });
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "P.O #": v.po_no,
                    "Sub Total": numeral(v.sub_total).format("0,0.00"),
                    "Shipping Cost": numeral(v.shipping_cost).format("0,0.00"),
                    "Tax Amount": v.add_tax,
                    "Discount Code": v.discount_code,
                    "Discount Amount": v.discount_amount,
                    "Total": numeral(v.total).format("0,0.00"),
                    "Ship Via": v.ship_via_name,
                    "Wash Style": v.wash_style_name,
                    "SKU Inseam Size": v.sku,
                    "Net Amount": numeral(v.net_amount).format("0,0.00"),
                    "First Last Name": v.bill_addressee,
                    "Address 1": v.bill_address_1,
                    "Address 2": v.bill_address_2,
                    "City": v.bill_city_name,
                    "Zip Code": v.bill_zip_code,
                    "State": v.bill_state_name,
                    "Country": v.bill_country_name,
                    "First Last Name": v.ship_addressee,
                    "Address 1": v.ship_address_1,
                    "Address 2": v.ship_address_2,
                    "City": v.ship_city_name,
                    "Zip Code": v.ship_zip_code,
                    "State": v.ship_state_name,
                    "Country": v.ship_country_name,
                    "BackOrder": v.quantity_backorder,
                    "Commited": v.quantity_committed,
                    "Packed": v.quantity_packed,
                    "Available": v.ship_country_name,
                };
            })
        );
    }

    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader
                    title={"Online Orders Summary"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-0">
                            <Form onFinish={onFinish} form={form} name="control-hooks">
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date"
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().startOf("year")}
                                                    >
                                                        <DatePicker
                                                            size="small"
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div>
                                                <div className="relative">
                                                    <Form.Item
                                                        name="to_date"
                                                        label="To Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().endOf("year")}
                                                    >
                                                        <DatePicker
                                                            size="small"
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div>
                                                    <Form.Item
                                                        name="order_no"
                                                        label="Order #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input size="small" className="styleInput" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div>
                                                    <Form.Item
                                                        name="po_no"
                                                        label="PO #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input size="small" className="styleInput" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                    </Brow>
                                </div>
                            </Form>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                {loading ? (
                    <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                        <Spin size="large" />
                    </div>
                ) : dscoInvoiceList.length > 0 ? (
                    <Table
                        title={() => (
                            <>
                                <div style={{ display: "flex", justifyContent: "left" }}>
                                    <div className="print_icon printing_class">
                                        <CSVLink
                                            filename={"Online-Orders-Summary.csv"}
                                            data={excelData}
                                            onClick={() => { }}
                                        >
                                            <input
                                                type="image"
                                                name="ImgCSV"
                                                alt="Export to CSV"
                                                id="ImgCSV"
                                                title="Export to CSV"
                                                src={csv}
                                                style={{
                                                    borderWidth: "0px",
                                                    height: "24px",
                                                    float: "inline-end",
                                                    margin: "12px",
                                                }}
                                            />
                                        </CSVLink>
                                    </div>
                                </div>
                            </>
                        )}
                        ref={tableRef}
                        size="small"
                        bordered={false}
                        type="company"
                        perPage={15}
                        scroll={{
                            x: "max-content",
                        }}
                        dataSource={dscoInvoiceList}
                        columns={columns}
                        loading={commissionReport.loading}
                        pagination={{
                            defaultCurrent: 1,
                            defaultPageSize: 100,
                            pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
                        }}
                    />
                ) : (
                    <div
                        style={{
                            backgroundColor: "#CCCCCC",
                            borderWidth: "1pt",
                            borderStyle: "Dashed",
                            fontWeight: "bold",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        No record(s)
                    </div>
                )}
            </div>
        </div>
    );
};

export default OnlineOrdersSummary;