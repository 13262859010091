import { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  Button,
  Form,
  Select,
  DatePicker,
  Table, Spin
} from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { loadCustomers } from "../../redux/actions/customerForm";
import { getChargeBackDetail } from "../../redux/actions/chargeBackDetail.action";
import { currencySymbol } from "../../utils/constants";
const { Option } = Select;

const ChargeBackDetail = () => {
  TabTitle("Charge Back Detail")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [citList, setCitSummaryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const focusInput = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const customerID = queryParams.get("customer_id");
  const companyId = ReadCookie("defaultCompany");
  const {
    chargeBackDetail,
    customerForms,
  } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])
  const [customer, setCustomer] = useState("All");












  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
      width: 40,
    },
    {
      title: "Memo #",
      dataIndex: "memo_header_no",
      key: "memo_header_no",

    },
    {
      title: "Memo Date",
      dataIndex: "memo_date",
      key: "memo_date",

    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",

    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",

    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",

    },
    {
      title: "SalesPerson",
      dataIndex: "salesperson_name",
      key: "salesperson_name",

    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_name",

    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",

      render: (text, record) => {
        return <>{currencySymbol} {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Customer Type",
      dataIndex: "customer_type_name",
      key: "customer_type_name",

    },
    {
      title: "Payment Term",
      dataIndex: "term_name",
      key: "term_name",

    },
  ];

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {

      dispatch(getChargeBackDetail(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setCitSummaryList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Memo #": v.memo_header_no,
          "Memo Date": v.memo_date,
          "Customer": v.customer_name,
          "Description": v.description,
          "Gender": v.gender_name,
          "SalesPerson": v.salesperson_name,
          "Region": v.region_name,
          "Amount": `${currencySymbol} ${numeral(v.net_amount).format("0,00.00")}`,
          "Customer Type": v.customer_type_name,
          "Payment Term": v.term_name,
        };
      })
    )
  }

  const totalSum = () => {
    let sumAmount = citList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.net_amount);
    }, 0);
    return sumAmount;
  }

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);


  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Charge Back Detail"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            label="Customer"
                            name="customer_id"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}






                          >
                            <Select
                              mode="multiple"
                              showSearch
                              allowClear
                              optionFilterProp="children"

                              className={"select_Inputs w-100"}
                              loading={customerForms.loading}

                              size="small"
                              onSearch={(value) => {
                                optimizedFn(value);
                              }}
                              ref={focusInput}
                              value={customer}
                            >
                              {customerForms?.payload?.data.map((item) => {
                                return (
                                  <Option
                                    key={item.customer_id}
                                    value={item.customer_id}
                                  >
                                    {" "}
                                    {`${item.customer_code} ${item.customer_name}`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            citList.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                    filename={"ChargeBack Detail.csv"}
                    data={excelData}
                    onClick={() => {

                    }}
                  >
                    Download Excel
                  </CSVLink>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}




                scroll={{ x: "max-content" }}

                dataSource={citList}
                columns={columns}
                loading={chargeBackDetail.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  let Amount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.net_amount
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : {currencySymbol}{numeral(totalSum()).format("0,0.00")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {currencySymbol} {Amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ChargeBackDetail;
