import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { TabTitle } from "../../utils/GeneralFunction";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { Button, Form, Input, Select, DatePicker, Table, Spin } from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import { getPendingSummary } from "../../redux/actions/pendingSummary.action";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import numeral from "numeral";
import { loadCustomers } from "../../redux/actions/customerForm";
import Loader from "react-spinners/BarLoader";
import { getARAccounts } from "../../redux/actions/AR_Account.action";
import { currencySymbol } from "../../utils/constants";
const { Option } = Select;

const CustomerRefundReport = () => {
  TabTitle("Customer-Refund-Report")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [pendingSummaryList, setPendingSummaryList] = useState([]);
  const [customer, setCustomer] = useState([])
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const customerID = queryParams.get("customer_id");
  const companyId = ReadCookie("defaultCompany");
  const {
    getPendingSummaryReducer,
    customerForms,
    arAccount
  } = useSelector((state) => state);



  useEffect(() => {
    dispatch(getARAccounts(token));
    dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
  }, [dispatch, menuId, token]);



  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Order #",
      dataIndex: "order_header_no",
      key: "order_header_no",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.order_header_no.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.order_header_no - b.order_header_no;
      },
    },
    {
      title: "Order Date",
      render: (text, record) => {
        return <>{moment(record.order_date).format("MM-DD-YY")}</>
      },
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.order_date.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.order_date - b.order_date;
      },

    },
    {
      title: "PO #",
      dataIndex: "reference_no",
      key: "reference_no",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.reference_no.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.reference_no - b.reference_no;
      },
    },
    {
      title: "Customer",
      dataIndex: "billingcustomer",
      key: "billingcustomer",
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.billingcustomer.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.billingcustomer - b.billingcustomer;
      },
    },
    {
      title: "Start Date",
      width: 100,
      render: (text, record) => {
        return <>{moment(record.start_date).format("MM-DD-YY")}</>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.start_date.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.start_date - b.start_date;
      },
    },
    {
      title: "Cancel Date",
      width: 120,
      render: (text, record) => {
        return <>{moment(record.cancel_date).format("MM-DD-YY")}</>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.cancel_date.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.cancel_date - b.cancel_date;
      },
    },
    {
      title: 'Pending Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 120,
      render: (text, record) => {
        return <>{parseFloat(record.quantity).toFixed(0)}</>;
      },
      align: "right",
    },
    {
      title: 'Commited',
      dataIndex: 'quantity_committed',
      key: 'quantity_committed',
      width: 100,
      render: (text, record) => {
        return <>{parseFloat(record.quantity_committed).toFixed(0)}</>;
      },
      align: "right",
    },
    {
      title: "Pending Amount",
      dataIndex: "net",
      key: "net",
      width: 150,
      align: "right",
      render: (text, record) => {
        return <>{currencySymbol} {numeral(record.net).format("0,00")}</>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.net.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.net - b.net;
      },
    },

    {
      title: "Term",
      dataIndex: "payment_term",
      key: "payment_term",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.payment_term.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.payment_term - b.payment_term;
      },
    },

    {
      title: "CIT Status",
      dataIndex: "factor_decision",
      key: "factor_decision",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.factor_decision.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.factor_decision - b.factor_decision;
      },
    },

    {
      title: "Order Status",
      dataIndex: "status",
      key: "status",
      width: 140,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.status.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.status - b.status;
      },
    },

    {
      title: "Hold Status",
      dataIndex: "holdstatus",
      key: "holdstatus",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.holdstatus.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.holdstatus - b.holdstatus;
      },
    },

    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_name",
      width: 160,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.region_name - b.region_name;
      },
    },

    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.location_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.location_name - b.location_name;
      },
    },

    {
      title: "Sale Person Name",
      dataIndex: "salesperson_name",
      key: "salesperson_name",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.salesperson_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.salesperson_name - b.salesperson_name;
      },
    },

    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.gender_name - b.gender_name;
      },
    },

    {
      title: "Customer Type",
      dataIndex: "customer_type_name",
      key: "customer_type_name",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.customer_type_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.customer_type_name - b.customer_type_name;
      },
    },

    {
      title: "Billing State",
      dataIndex: "state_name",
      key: "state_name",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.state_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.state_name - b.state_name;
      },
    },

    {
      title: "Resale #",
      dataIndex: "resaleno",
      key: "resaleno",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.resaleno.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.resaleno - b.resaleno;
      },
    },
    {
      title: 'Resale Expiry',
      width: 120,
      render: (text, record) => {
        return <>{moment(record.resale_expiry).format("MM-DD-YYYY")}</>;
      },
    },

    {
      title: "Store",
      dataIndex: "store_id",
      key: "store_id",
      width: 80,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.store_id.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.store_id - b.store_id;
      },
    },

    {
      title: "Shipping Zip",
      dataIndex: "shipping_zip",
      key: "shipping_zip",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.shipping_zip.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.shipping_zip - b.shipping_zip;
      },
    },

    {
      title: "Shipper",
      dataIndex: "ship_via_description",
      key: "ship_via_description",
      width: 180,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.ship_via_description.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.ship_via_description - b.ship_via_description;
      },
    },

    {
      title: "Bergan Billing",
      dataIndex: "bergan_billing_name",
      key: "bergan_billing_name",
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.bergan_billing_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.bergan_billing_name - b.bergan_billing_name;
      },
    },
  ];

  const handleCustomerChanges = (val) => {
    if (val === "" || val === 'All') return
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null,)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data)
        }
      }
    );
  };

  function debounce(cb, delay = 1000) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }

  const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {

      dispatch(getPendingSummary(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setPendingSummaryList(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }





  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Customer Refund Report"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item
                          labelAlign="left"
                          name="customerID"
                          label="Customer"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          rules={[{ required: true, message: "Customer is required" }]}
                        >
                          <Select
                            notFoundContent={customerForms?.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                            </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                            size="small"
                            tabIndex={18}
                            autoComplete={"nope"}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            className="filter-fields"
                            loading={customerForms?.loading}
                            onSearch={(value) => {
                              optimizedFn(value)
                            }}
                            value={customer}
                          >
                            {customerForms?.payload?.data?.map(
                              (item) => {
                                return (
                                  <Option
                                    key={item.customer_id}
                                    value={item.customer_id}
                                  >
                                    {`${item.customer_code} ${item.customer_name}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>

                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item label="Account" name="account_no" labelAlign="left" labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }} initialValue={"All"}>
                          <Select
                            size="small"
                            autoComplete={"nope"}
                            allowClear
                            tabIndex={18}
                            showSearch
                            optionFilterProp="children"
                            className="filter-fields"
                            loading={arAccount.loading}
                          >
                            <Option value={null}>All</Option>
                            {arAccount?.payload?.data.map((item) => {
                              return (
                                <Option key={item.account_id} value={item.account_id}>
                                  {item.account_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="transaction_no"
                            label="Transaction #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>


          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {

          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            pendingSummaryList.length > 0 ? (
              <Table
                title={() =>
                  <DownloadTableExcel
                    filename={`Pending-Summary-${moment()}`}
                    sheet="Pending Summary"
                    currentTableRef={tableRef.current}
                  >
                    <span
                      className="print_icon printing_class">
                      <input style={{ height: "25px" }}
                        type="image"
                        name="ImgExcel"
                        alt="Export to excel"
                        id="ImgExcel"
                        title="Export to excel"
                        src={xlsx}
                      />
                    </span>
                  </DownloadTableExcel>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: 'calc(2200px + 90%)',
                  y: 450,
                }}
                
                dataSource={pendingSummaryList}
                columns={columns}
                loading={getPendingSummaryReducer.loading}
                pagination={{ pageSize: 3000 }}
                summary={(pageData) => {
                  let PendingQuantity = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.quantity
                    ));
                  },
                    0);
                  let Commited = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.quantity_committed
                    ));
                  },
                    0);
                  let PendingAmount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.net
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {PendingQuantity.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Commited.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {currencySymbol} {PendingAmount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )

        }
      </div>

    </div>
  )
}

export default CustomerRefundReport;
