import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, DatePicker, Checkbox, Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOmsStockSummary } from "../../redux/actions/omsStockSummary.action";
import { getGenderCategory, getGenderCategoryChange } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getProductType } from "../../redux/actions/productType.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { getSku } from "../../redux/actions/sku.action"
import { ReadCookie } from "../../utils/readCookie";
import { getWashName } from "../../redux/actions/wash.action";
import { getStyle, getStyleChange } from "../../redux/actions/style";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { Link, useHistory } from "react-router-dom";
import { Excel } from "antd-table-saveas-excel";
import Loader from "react-loader-spinner";
import numeral from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getSeasonYear } from "../../redux/actions/seasonYear.action";
import { imediateOtsBySize } from "../../redux/actions/omsStockSummary2.action";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import { currencySymbol } from "../../utils/constants";
const dateFormatList = ["MM-DD-YYYY"];

const ImediateOtsBySize = ({ token }) => {
  TabTitle("Immediate OTS By Size")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    getWiseStockReport,
    productType,
    gender,
    genderCategory,
    season,
    productStatus,
    productClass,
    size,
    wash,
    fitCategory,
    omsStockSummary2,
    genderCategoryChange,
    styleChange,
    Sku,
    seasonYear,
    adminLocation
  } = useSelector((state) => state);
  const history = useHistory();
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [productClassID, setProductClassID] = useState([]);
  const [seasonID, setSeasonID] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [isChecked2, setIsChecked2] = useState([]);
  const [allSesion, setAllSesion] = useState(true);
  const [allStatus, setAllStatus] = useState(true);
  const [isPCChecked, setIsPCChecked] = useState([]);
  const [allPC, setAllPC] = useState(true);
  const [loading, setLoading] = useState(false);
  const [BgradeCheck, setBgradeCheck] = useState(false);
  const [check, setCheck] = useState(false);
  const [data, setData] = useState([])
  const [excelData, setExcelData] = useState([])
  const [steps, setSteps] = useState([]);
  const stepsArr = [1, 17, 18, 25, 2, 29, 20, 21, 22, 28, 33];
  const [pendingChecked, setPendingChecked] = useState(true);
  const location = useLocation();

  const [stepsChecked, setStepChecked] = useState([]);

  
  useEffect(() => {
    if (adminLocation?.payload?.data?.length > 0) {
      setSteps(
        adminLocation?.payload?.data
          .filter((val) => stepsArr.includes(val.location_id))
          .map((it) => ({ ...it, selected: true }))
      );
    }
  }, [adminLocation]);

  const stepschecked =
  steps && steps?.filter((it) => it.selected)?.map((it) => it.location_id);

const getStepData =
  adminLocation &&
  adminLocation?.payload?.data.filter((val) =>
    stepsArr.includes(val.location_id)
  );


  let result =
    getWiseStockReport &&
    getWiseStockReport?.payload?.data?.reduce(function (r, a) {
      r[a.item_code] = r[a.item_code] || [];
      r[a.item_code].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);

  const values = {
    item_id: null,
    location_id: null,
  };

  const { Option } = Select;
  let pendingDate = "";

  const onChangeDate = (date, dateString) => {
    pendingDate = dateString;
  };

  const sesionSelectAll = (e) => {
    setAllSesion(e.target.checked);
  };
  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };
  const handlePendingOrder = (e) => {
    setPendingChecked(e.target.checked);
  };

  
  
  
  
  
  
  

  const isCheckboxChecked = (id, value) => {
    const filterCheckBox =
      season && season?.payload?.data.find((val) => val.season_id === id);
    const checkId = isChecked.includes(id);
    if (!checkId) {
      setIsChecked((current) => [...current, filterCheckBox.season_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked.filter(
        (item) => item !== filterCheckBox.season_id
      );
      setIsChecked(removeUnCheck);
    }
    setAllSesion(false);
  };


  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus && productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [...current, filterCheckBox.product_status_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

  
  

  

  useEffect(() => {
    if (location?.state && location?.state?.values?.new_season_id) {
      const values = {
        as_on_date: moment("12-31-2023"),
        sku: "",
        season_id: location?.state?.values?.new_season_id,
        gender_id: location?.state?.values?.gender_id,
        gender_category_id: null,
        fit_category_id: null,
        style_id: null,
        product_status_id: null,
        product_class_id: null,
        sort_on: null,
        include_bgrade: "abc",
        wash_id: null
      }
      form.setFieldsValue({
        season_id: values.season_id,
        gender_id: values.gender_id,
      });
      setLoading(true)
      dispatch(imediateOtsBySize(token, values, isChecked, isChecked2, isPCChecked, BgradeCheck)).then((res => {
        setLoading(false)
        setData(res?.payload?.data)
        downloadExcelFileData(res?.payload?.data);
      }));
    }
  }, [location?.state && location?.state?.values?.new_season_id])


  const onFinish = async (e) => {
    await form.validateFields().then((values) => {
      
       
       
       
       values.stepsChecked = stepsChecked;
       values.isChecked = isChecked;
       values.isChecked2 = isChecked2;
       
       
       
 
       if (allStatus) {
         values.allStatus = allStatus;
       }
 
       if (pendingChecked) {
         stepschecked.push(-1);
       }
       
       const styleId =
         style &&
         style?.payload?.data.find((val) => val.style_name === values.style);
       
       const productStatusId =
         productStatus &&
         productStatus?.payload?.data.find(
           (val) => val.product_status_name === values.product_Status
         );
 
       
       
       
       const distributorParam = {
         
         location_id: stepschecked.length === 0 ? null : stepschecked.toString(),
         season_id: values.season_id === "All" ? null : values.season_id,
         sku: values.sku === "All" ? null : values.sku,
         gender_category_id: values.type === "All" ? null : values.type,
         style_id: styleId ? styleId.style_id : null, 
         product_status_id: isChecked2.length > 0 ? isChecked2.toString() : null,
         pending_date: moment(values.as_on_date).format("YYYY-MM-DD"),
         as_on_date: moment(values.as_on_date).format("YYYY-MM-DD"),
         category_id: values.gender_category_id === "All" ? null : values.gender_category_id,
         fit_category_id: null,
         gender_id: values.gender_id === "All" ? null : values.gender_id,
         gender_category_id: null,
         product_class_id: null,
         sort_on: null,
         include_bgrade: "abc",
         wash_id: values.wash_id === "All" ? null : values.wash_id,
         new_sesaon_year_id: values.new_season_year_id === "All" ? null : values.new_season_year_id
       };
    setLoading(true)
      dispatch(
        imediateOtsBySize(token, distributorParam)).then((res => {
          setLoading(false)
          setData(res?.payload?.data)
          downloadExcelFileData(res?.payload?.data);
        }));
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Sku": v.item_code,
          "Inseam": v.inseam,
          "Style": v.style,
          "Wash": v.wash,
          "Current Season": v.current_season,
          "Gender": v.gender,
          "Fit Category": v.fit_category,
          "WS": Number(v.ws_price).toFixed(0),
          "RTL": Number(v.retail_price).toFixed(0),
          "BERGEN": v.bergan,
          "TRNS": v.transit,
          "PK": v.pakistan,
          "PROD": v.production,
          "PEND": v.pending,
          "INT": v.international,
          "OTS": v.ots,
          "ECOM": v.ecommerce,
          "NORD": v.nord,
          "NEIMAN": v.neiman,
          "SAKS": v.saks,
          "VERISHOP": v.verishop_canada,
          "BGS": v.bgs,
          "POD": v.pod,
          "POD": v.pod,
          "=\"\"2/3\"\"&CHAR(10)&\"\"XS\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"27\"\"&CHAR(10)&\"\"23\"\"": v.qty_1,
          "=\"\"4/5\"\"&CHAR(10)&\"\"S\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"28\"\"&CHAR(10)&\"\"24\"\"": v.qty_2,
          "=\"\"6/6X\"\"&CHAR(10)&\"\"M\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"29\"\"&CHAR(10)&\"\"25\"\"": v.qty_3,
          "=\"\"-\"\"&CHAR(10)&\"\"L\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"30\"\"&CHAR(10)&\"\"26\"\"": v.qty_4,
          "=\"\"-\"\"&CHAR(10)&\"\"XL\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"31\"\"&CHAR(10)&\"\"27\"\"": v.qty_5,
          "=\"\"-\"\"&CHAR(10)&\"\"1XL\"\"&CHAR(10)&\"\"6x\"\"&CHAR(10)&\"\"32\"\"&CHAR(10)&\"\"28\"\"": v.qty_6,
          "=\"\"-\"\"&CHAR(10)&\"\"2XL\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"33\"\"&CHAR(10)&\"\"29\"\"": v.qty_7,
          "=\"\"-\"\"&CHAR(10)&\"\"3XL\"\"&CHAR(10)&\"\"7x\"\"&CHAR(10)&\"\"34\"\"&CHAR(10)&\"\"30\"\"": v.qty_8,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"35\"\"&CHAR(10)&\"\"31\"\"": v.qty_9,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"36\"\"&CHAR(10)&\"\"32\"\"": v.qty_10,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"12\"\"&CHAR(10)&\"\"38\"\"&CHAR(10)&\"\"33\"\"": v.qty_11,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"14\"\"&CHAR(10)&\"\"40\"\"&CHAR(10)&\"\"34\"\"": v.qty_12,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"16\"\"&CHAR(10)&\"\"42\"\"&CHAR(10)&\"\"35\"\"": v.qty_13,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"18\"\"&CHAR(10)&\"\"44\"\"&CHAR(10)&\"\"36\"\"": v.qty_14,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"20\"\"&CHAR(10)&\"\"46\"\"&CHAR(10)&\"\"37\"\"": v.qty_15,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"48\"\"&CHAR(10)&\"\"38\"\"": v.qty_16,
        };
      })
    )
  }

  useEffect(() => {
    dispatch(getStyle(token, menuId));
    dispatch(getProductType(token, values));
    dispatch(getGenderCategory(token, menuId));
    dispatch(getSeason(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getProductClass(token, menuId));
    dispatch(getSize(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getFitCategory(token, menuId));
    dispatch(getWashName(token, menuId));
    dispatch(getSeasonYear(token, menuId));
    dispatch(getAdminLocation(token, menuId));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (allSesion) {
      setIsChecked([]);
    }
  }, [allSesion])

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus])

  useEffect(() => {
    if (allPC) {
      setIsPCChecked([]);
    }
  }, [allPC])



  const handlePrint = () => {
    window.print();
  };

  const handleExcelExport = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columsPageOne)
      .addDataSource(omsStockSummary2?.payload?.data, {
        str2Percent: true,
      })
      .setTHeadStyle()
      .saveAs("Excel.xlsx");
  };

  const columsPageOne = [
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Gender Cateogry",
      dataIndex: "gender_cateogry",
      key: "gender_cateogry",
    },
    {
      title: "Current Season",
      dataIndex: "current_season",
      key: "current_season",
    },
    {
      title: "Fabric",
      dataIndex: "fabric",
      key: "fabric",
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
    },
    {
      title: "SKU",
      dataIndex: "item_code",
      key: "item_code",
    },
    {
      title: "Style",
      dataIndex: "style",
      key: "style",
    },
    {
      title: "Wash",
      dataIndex: "wash",
      key: "wash",
    },
    {
      title: "Fit Category",
      dataIndex: "fit_category",
      key: "fit_category",
    },
    {
      title: "Production",
      dataIndex: "production",
      key: "producti  on",
    },
    {
      title: "PK",
      dataIndex: "pakistan",
      key: "pakistan",
    },
    {
      title: "Transit",
      dataIndex: "transit",
      key: "transit",
    },
    {
      title: "Bergen",
      dataIndex: "bergan",
      key: "bergan",
    },
    {
      title: "Pending",
      dataIndex: "pending",
      key: "pending",
    },
    {
      title: "Intl",
      dataIndex: "international",
      key: "international",
    },
    {
      title: "OTS",
      dataIndex: "ots",
      key: "ots",
    },
    {
      title: "Retail",
      dataIndex: "retail_price",
      key: "retail_price",
    },
    {
      title: "Cost Price",
      dataIndex: "ws_price",
      key: "ws_price",
    },
  ];



  

  let tableData = omsStockSummary2?.payload?.data;


  function totals() {
    let wsTotal = 0;
    let rtsTotal = 0;
    let berganTotal = 0;
    let transitTotal = 0;
    let pkTotal = 0;
    let productionTotal = 0;
    let pendingTotal = 0;
    let intTotal = 0;
    let otsTotal = 0;
    let ecomTotal = 0;
    let nordTotal = 0;
    let neminTotal = 0;
    let saksTotal = 0;
    let verishopTotal = 0;
    let bgsTotal = 0;
    let podTotal = 0;

    
    tableData && tableData.forEach((item) => {
      berganTotal += item.bergan;
      transitTotal += item.transit;
      pkTotal += item.pakistan;
      productionTotal += item.production;
      pendingTotal += item.pending;
      intTotal += item.international;
      otsTotal += item.ots;
      ecomTotal += item.ecommerce;
      nordTotal += item.nord;
      neminTotal += item.neiman;
      saksTotal += item.saks;
      verishopTotal += item.verishop_canada;
      bgsTotal += item.bgs;
      podTotal += item.pod;
    })

    return {
      berganTotal: berganTotal,
      transitTotal: transitTotal,
      pkTotal: pkTotal,
      productionTotal: productionTotal,
      pendingTotal: pendingTotal,
      intTotal: intTotal,
      otsTotal: otsTotal,
      ecomTotal: ecomTotal,
      nordTotal: nordTotal,
      neminTotal: neminTotal,
      saksTotal: saksTotal,
      verishopTotal: verishopTotal,
      bgsTotal: bgsTotal,
      podTotal: podTotal,
    }

  }


  const handleGender = (gender_id) => {
    dispatch(getGenderCategoryChange(token, gender_id));
    dispatch(getStyleChange(token, gender_id, null, null));
    form.setFieldsValue({
      gender_category_id: "All",
      style_id: "All",
      sku: "All"
    })
  }


  const handleStyle = (gender_category_id) => {
    dispatch(getStyleChange(token, form.getFieldValue().gender_id, gender_category_id, null))
    form.setFieldsValue({
      style_id: "All",
      sku: "All"
    })
  }

  const handleSku = (sku_id) => {
    if (sku_id === null) {
      dispatch(getSku(token, null, null, null));
    } else {
      dispatch(getSku(token, form.getFieldValue().gender_id, form.getFieldValue().gender_category_id, sku_id));
    }
    form.setFieldsValue({
      sku: "All"
    })
  }


  function debounce(cb, delay = 1000) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }



  const handleSkuSearch = () => {
    dispatch(getSku(token, null, null, null));
  }

  const optimizedFn = useCallback(debounce(handleSkuSearch), []);


  
  
  
  
  
  
  
  
  
  
  
  
  

  
  
  
  
  
  
  


  const sortBySku = (n) => {
    
    const userCopy = [...data];
    if (!check) {
      setCheck(true);
      if (n === "item_code") {
        userCopy.sort((a, b) => {
          return Number(a.item_code) - Number(b.item_code);
        });
      }
      else if (n === "inseam") {
        userCopy.sort((a, b) => {
          return parseInt(a.inseam) - parseInt(b.inseam);
        });
      }
      else if (n === "style") {
        userCopy.sort((a, b) => {
          if (a.style !== null || undefined && b.style !== null || undefined) {
            return a.style.localeCompare(b.style);
          }
        });
      }
      else if (n === "wash") {
        userCopy.sort((a, b) => {
          if (a.wash !== null || undefined && b.wash !== null || undefined) {
            return a.wash.localeCompare(b.wash);
          }
        });
      }
      else if (n === "current_season") {
        userCopy.sort((a, b) => {
          if (a.current_season !== null || undefined && b.current_season !== null || undefined) {
            return a.current_season.localeCompare(b.current_season);
          }
        });
      }
      else if (n === "gender") {
        userCopy.sort((a, b) => {
          if (a.gender !== null || undefined && b.gender !== null || undefined) {
            return a.gender.localeCompare(b.gender);
          }
        });
      }
      else if (n === "fit_category") {
        userCopy.sort((a, b) => {
          if (a.fit_category !== null || undefined && b.fit_category !== null || undefined) {
            return a.fit_category.localeCompare(b.fit_category);
          }
        });
      }
      else if (n === "ws_price") {
        userCopy.sort((a, b) => {
          return Number(a.ws_price) - Number(b.ws_price);
        });
      }
      else if (n === "retail_price") {
        userCopy.sort((a, b) => {
          return Number(a.retail_price) - Number(b.retail_price);
        });
      }
      else if (n === "bergan") {
        userCopy.sort((a, b) => {
          return Number(a.bergan) - Number(b.bergan);
        });
      }
      else if (n === "transit") {
        userCopy.sort((a, b) => {
          return Number(a.transit) - Number(b.transit);
        });
      }
      else if (n === "pakistan") {
        userCopy.sort((a, b) => {
          return Number(a.pakistan) - Number(b.pakistan);
        });
      }
      else if (n === "production") {
        userCopy.sort((a, b) => {
          return Number(a.production) - Number(b.production);
        });
      }
      else if (n === "pending") {
        userCopy.sort((a, b) => {
          return Number(a.pending) - Number(b.pending);
        });
      }
      else if (n === "international") {
        userCopy.sort((a, b) => {
          return Number(a.international) - Number(b.international);
        });
      }
      else if (n === "ots") {
        userCopy.sort((a, b) => {
          return Number(a.ots) - Number(b.ots);
        });
      }
      else if (n === "ecommerce") {
        userCopy.sort((a, b) => {
          return Number(a.ecommerce) - Number(b.ecommerce);
        });
      }
      else if (n === "nord") {
        userCopy.sort((a, b) => {
          return Number(a.nord) - Number(b.nord);
        });
      }
      else if (n === "neiman") {
        userCopy.sort((a, b) => {
          return Number(a.neiman) - Number(b.neiman);
        });
      }
      else if (n === "saks") {
        userCopy.sort((a, b) => {
          return Number(a.saks) - Number(b.saks);
        });
      }
      else if (n === "verishop_canada") {
        userCopy.sort((a, b) => {
          return Number(a.verishop_canada) - Number(b.verishop_canada);
        });
      }
      else if (n === "bgs") {
        userCopy.sort((a, b) => {
          return Number(a.bgs) - Number(b.bgs);
        });
      }
      else if (n === "pod") {
        userCopy.sort((a, b) => {
          return Number(a.pod) - Number(b.pod);
        });
      }
    }
    else {
      setCheck(false);
      if (n === "item_code") {
        userCopy.sort((a, b) => {
          return Number(b.item_code) - Number(a.item_code);
        });
      }
      else if (n === "inseam") {
        userCopy.sort((a, b) => {
          return parseInt(b.inseam) - parseInt(a.inseam);
        });
      }
      else if (n === "style") {
        userCopy.sort((a, b) => {
          if (b.style !== null || undefined && a.style !== null || undefined) {
            return b.style.localeCompare(a.style);
          }
        });
      }
      else if (n === "wash") {
        userCopy.sort((a, b) => {
          if (b.wash !== null || undefined && a.wash !== null || undefined) {
            return b.wash.localeCompare(a.wash);
          }
        });
      }
      else if (n === "current_season") {
        userCopy.sort((a, b) => {
          if (b.current_season !== null || undefined && a.current_season !== null || undefined) {
            return b.current_season.localeCompare(a.current_season);
          }
        });
      }
      else if (n === "gender") {
        userCopy.sort((a, b) => {
          if (b.gender !== null || undefined && a.gender !== null || undefined) {
            return b.gender.localeCompare(a.gender);
          }
        });
      }
      else if (n === "fit_category") {
        userCopy.sort((a, b) => {
          if (b.fit_category !== null || undefined && a.fit_category !== null || undefined) {
            return b.fit_category.localeCompare(a.fit_category);
          }
        });
      }
      else if (n === "ws_price") {
        userCopy.sort((a, b) => {
          return Number(b.ws_price) - Number(a.ws_price);
        });
      }
      else if (n === "retail_price") {
        userCopy.sort((a, b) => {
          return Number(b.retail_price) - Number(a.retail_price);
        });
      }
      else if (n === "bergan") {
        userCopy.sort((a, b) => {
          return Number(b.bergan) - Number(a.bergan);
        });
      }
      else if (n === "transit") {
        userCopy.sort((a, b) => {
          return Number(b.transit) - Number(a.transit);
        });
      }
      else if (n === "pakistan") {
        userCopy.sort((a, b) => {
          return Number(b.pakistan) - Number(a.pakistan);
        });
      }
      else if (n === "production") {
        userCopy.sort((a, b) => {
          return Number(b.production) - Number(a.production);
        });
      }
      else if (n === "pending") {
        userCopy.sort((a, b) => {
          return Number(b.pending) - Number(a.pending);
        });
      }
      else if (n === "international") {
        userCopy.sort((a, b) => {
          return Number(b.international) - Number(a.international);
        });
      }
      else if (n === "ots") {
        userCopy.sort((a, b) => {
          return Number(b.ots) - Number(a.ots);
        });
      }
      else if (n === "ecommerce") {
        userCopy.sort((a, b) => {
          return Number(b.ecommerce) - Number(a.ecommerce);
        });
      }
      else if (n === "nord") {
        userCopy.sort((a, b) => {
          return Number(b.nord) - Number(a.nord);
        });
      }
      else if (n === "neiman") {
        userCopy.sort((a, b) => {
          return Number(b.neiman) - Number(a.neiman);
        });
      }
      else if (n === "saks") {
        userCopy.sort((a, b) => {
          return Number(b.saks) - Number(a.saks);
        });
      }
      else if (n === "verishop_canada") {
        userCopy.sort((a, b) => {
          return Number(b.verishop_canada) - Number(a.verishop_canada);
        });
      }
      else if (n === "bgs") {
        userCopy.sort((a, b) => {
          return Number(b.bgs) - Number(a.bgs);
        });
      }
      else if (n === "pod") {
        userCopy.sort((a, b) => {
          return Number(b.pod) - Number(a.pod);
        });
      }

    }
    setData(userCopy)
  }


  return (
    <div className="header_class business_header_oms">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        {/* <div className="col-sm-6 box-space pl-sm-0">
                    <div className="brand-para">
                        <div>DL1961 Premium Denim Inc.</div>
                        <div>121 Varick Street</div>
                        <div>4th Floor</div>
                        <div>New York, NY 10013</div>
                        <div>(P) 646-514-9736</div>
                        <div>(F) 212-991-9989</div>
                        <div>(E) sales@DL1961.com</div>
                    </div>
                </div> */}
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Oms-Stock-Summary.csv"}
                  data={excelData}
                  onClick={() => {

                  }}
                  style={{display: "flex"}}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    height={14}
                  
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Immediate OTS By Size
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>


      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              {/* <hr className="mt-0 mb-2" />
              <div>
                <label htmlFor="">Classification</label>
                <div className="checkboxes">
                  <div className="PC_Chekbox">
                    <Checkbox
                      size="large"
                      checked={allPC}
                      onChange={PCSelectAll}
                    >
                      All
                    </Checkbox>
                  </div>
                  {productClass &&
                    productClass?.payload?.data.map((pcls, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="product_class_id">
                            <Checkbox
                              size="large"
                              checked={allPC ? false : isPCChecked.includes(pcls.product_class_id)}
                              onChange={(e) =>
                                isPCBoxChecked(
                                  pcls.product_class_id,
                                  e.target.checked
                                )
                              }
                            >
                              {pcls.product_class_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div> */}
              <hr className="my-1" />
              <div>
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <hr className="my-1" />
              <div>
                <label>Steps</label>
                <div className="checkboxes">
                  {getStepData &&
                    getStepData.map((step, i) => {
                      return (
                        <div className="checkbox_content">
                          <Checkbox
                            checked={steps[i]?.selected}
                            value={12}
                            onChange={(e) => {
                              setSteps(
                                steps.map((it) => {
                                  if (it.location_id == step.location_id) {
                                    return {
                                      ...it,
                                      selected: e.target.checked,
                                    };
                                  } else {
                                    return it;
                                  }
                                })
                              );
                            }}
                          >
                            {step.location_name}
                          </Checkbox>
                        </div>
                      );
                    })}
                  <Checkbox
                    checked={pendingChecked}
                    onChange={handlePendingOrder}
                  >
                    Pending Order
                  </Checkbox>
                </div>
              </div> */}
              {/* <hr className="my-1" />
              <div>
                <label htmlFor="">Season</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_season_id" className=" mb-0">
                      <Checkbox checked={allSesion} onChange={sesionSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {season &&
                    season?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_season_id">
                            <Checkbox
                              checked={allSesion ? false : isChecked.includes(it.season_id)}
                              onChange={(e) =>
                                isCheckboxChecked(
                                  it.season_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.season_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div> */}
              <hr className="my-1" />
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Gender</label>
                    <div className="w-100">
                      <Form.Item name="gender_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          onChange={(e) => handleGender(e)}
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_id}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Season</label>
                    <div className=" w-100">
                      <Form.Item name="season_id" initialValue={'All'}>
                        <Select
                          defaultValue={form.getFieldValue().season_id}
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={status.season_id}
                                  >
                                    {status.season_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Season Year</label>
                    <div className="w-100">
                      <Form.Item name="new_season_year_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          defaultValue={form.getFieldValue().new_season_year_id}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value!=="All"?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={"All"}>All</Option>
                          {seasonYear?.payload?.data?.map((res) => <Option key={res.season_year_id} value={res.season_year_id}>{res.season_year}</Option>
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Second*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Category</label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="gender_category_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          onChange={(e) => handleStyle(e)}
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        
                        
                        
                        
                        
                        >
                          <Option value={null}>All</Option>
                          {
                            genderCategoryChange &&
                              genderCategoryChange?.payload?.data.length >= 0 ? (
                              genderCategoryChange &&
                              genderCategoryChange?.payload?.data.map((it, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={it.gender_category_id}
                                  >
                                    {it.gender_category_name}
                                  </Option>
                                );
                              })
                            ) : (
                              genderCategory &&
                              genderCategory?.payload?.data.map((it, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={it.gender_category_id}
                                  >
                                    {it.gender_category_name}
                                  </Option>
                                );
                              })
                            )
                          }
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Sku</label>
                    <div className="w-100">
                      <Form.Item name="sku" initialValue={"All"}>
                        <Select
                          notFoundContent={Sku.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                          </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                          className={"select_Inputs"}
                          showSearch
                          onSearch={(value) => {
                            optimizedFn(value)
                          }}
                          
                          optionFilterProp="children"
                          size="small"

                        >
                          <Option value={null}>All</Option>
                          {Sku &&
                            Sku?.payload?.data.map((it, index) => {
                              return (
                                
                                <Option key={index} value={it.sku}>
                                  {it.sku}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Style</label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="style_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"

                          size="small"
                          onChange={(e) => handleSku(e)}
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        
                        
                        
                        
                        
                        >
                          <Option value={null}>All</Option>
                          {
                            styleChange && styleChange?.payload?.data.length >= 0 ? (
                              styleChange && styleChange?.payload?.data.map((style, index) => {
                                return (
                                  <Option key={index} value={style.style_id}>
                                    {style.style_name}
                                  </Option>
                                );
                              })
                            ) : (
                              style && style?.payload?.data.map((style, index) => {
                                return (
                                  <Option key={index} value={style.style_id}>
                                    {style.style_name}
                                  </Option>
                                );
                              })
                            )
                          }
                          {/* {style &&
                            style?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_id}>
                                  {style.style_name}
                                </Option>
                              );
                            })} */}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Wash</label>
                    <div className="w-100">
                      <Form.Item name="wash_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().wash_id}
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {wash &&
                            wash?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={status.wash_id}
                                  >
                                    {status.wash_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">

                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Pending Till</label>
                    <div className="w-100">
                      <Form.Item
                        name="as_on_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          onChange={onChangeDate}
                          format={dateFormatList}

                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginTop: "3px", float: "right" }}>
                {/* <label className=" no_visibility">none</label> */}
                <Button
                  style={{ width: "fit-content" }}
                  type="primary"
                  htmlType="submit"
                  className="submit-btn-btm m-0"
                >
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div>
        <div className="overflow"
          style={{
            overflowY: "scroll",
            height: "57vh",
            
          }}
        >
          {omsStockSummary2?.payload?.data !== undefined ?
            (
              data.length > 0 ? (
                <table
                  className="table_distributor-data stock_table table_content_oms oms_stock_summary_table immediate_table"
                
                
                >
                  <thead
                     style={{
                       background: "#dedede",
                       border: " 2px solid",
                       height: " 34px",
                       position: "sticky",
                       top: "0px",
                       fontSize: "12px"
                     }}
                  >
                    {/* first row */}
                  <tr>
                      <th colSpan={24}></th>
                      <th>T-Jacket</th>
                      <th style={{textAlign: "right"}}>"2/3"</th>
                      <th style={{textAlign: "right"}}>"4/5"</th>
                      <th style={{textAlign: "right"}}>"6/6X"</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                  </tr>
                    {/* second row */}
                  <tr>
                      <th colSpan={24}></th>
                      <th>Shirt</th>
                      <th style={{textAlign: "right"}}>XS</th>
                      <th style={{textAlign: "right"}}>S</th>
                      <th style={{textAlign: "right"}}>M</th>
                      <th style={{textAlign: "right"}}>L</th>
                      <th style={{textAlign: "right"}}>XL</th>
                      <th style={{textAlign: "right"}}>1XL</th>
                      <th style={{textAlign: "right"}}>2XL</th>
                      <th style={{textAlign: "right"}}>3XL</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                  </tr>
                   {/* third row */}
                   <tr>
                      <th colSpan={24}></th>
                      <th>W-Plus</th>
                      <th style={{textAlign: "right"}}>10W</th>
                      <th style={{textAlign: "right"}}>12W</th>
                      <th style={{textAlign: "right"}}>14W</th>
                      <th style={{textAlign: "right"}}>16W</th>
                      <th style={{textAlign: "right"}}>18W</th>
                      <th style={{textAlign: "right"}}>20W</th>
                      <th style={{textAlign: "right"}}>22W</th>
                      <th style={{textAlign: "right"}}>24W</th>
                      <th style={{textAlign: "right"}}>26W</th>
                      <th style={{textAlign: "right"}}>28W</th>
                      <th style={{textAlign: "right"}}>30W</th>
                      <th style={{textAlign: "right"}}>32W</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                  </tr>
                  {/* fourth row */}
                  <tr>
                      <th colSpan={24}></th>
                      <th>Kids</th>
                      <th style={{textAlign: "right"}}>2</th>
                      <th style={{textAlign: "right"}}>3</th>
                      <th style={{textAlign: "right"}}>4</th>
                      <th style={{textAlign: "right"}}>5</th>
                      <th style={{textAlign: "right"}}>6</th>
                      <th style={{textAlign: "right"}}>6x</th>
                      <th style={{textAlign: "right"}}>7</th>
                      <th style={{textAlign: "right"}}>7x</th>
                      <th style={{textAlign: "right"}}>8</th>
                      <th style={{textAlign: "right"}}>10</th>
                      <th style={{textAlign: "right"}}>12</th>
                      <th style={{textAlign: "right"}}>14</th>
                      <th style={{textAlign: "right"}}>16</th>
                      <th style={{textAlign: "right"}}>18</th>
                      <th style={{textAlign: "right"}}>20</th>
                      <th>-</th>
                  </tr>
                  {/* fifth row */}
                  <tr>
                      <th colSpan={24}></th>
                      <th>Men</th>
                      <th style={{textAlign: "right"}}>27</th>
                      <th style={{textAlign: "right"}}>28</th>
                      <th style={{textAlign: "right"}}>29</th>
                      <th style={{textAlign: "right"}}>30</th>
                      <th style={{textAlign: "right"}}>31</th>
                      <th style={{textAlign: "right"}}>32</th>
                      <th style={{textAlign: "right"}}>33</th>
                      <th style={{textAlign: "right"}}>34</th>
                      <th style={{textAlign: "right"}}>35</th>
                      <th style={{textAlign: "right"}}>36</th>
                      <th style={{textAlign: "right"}}>38</th>
                      <th style={{textAlign: "right"}}>40</th>
                      <th style={{textAlign: "right"}}>42</th>
                      <th style={{textAlign: "right"}}>44</th>
                      <th style={{textAlign: "right"}}>46</th>
                      <th style={{textAlign: "right"}}>48</th>
                  </tr>
                    <tr
                    
                      
                      
                      
                      
                      
                      
                      
                      
                    >
                      <th className="border1">#</th>
                      <th onClick={() => sortBySku('item_code')} >Sku</th>
                      <th onClick={() => sortBySku('inseam')}>Inseam</th>
                      <th onClick={() => sortBySku('style')}>Style</th>
                      <th onClick={() => sortBySku('wash')}>Wash</th>
                      <th onClick={() => sortBySku('current_season')} ><div> Current Season</div>
                      </th>
                      <th onClick={() => sortBySku('gender')}>Gender</th>
                      <th onClick={() => sortBySku('fit_category')}><div>Fit Category</div></th>
                      <th onClick={() => sortBySku('ws_price')} style={{textAlign: "right"}}>WS</th>
                      <th onClick={() => sortBySku('retail_price')} style={{textAlign: "right"}}>RTL</th>
                      <th onClick={() => sortBySku('bergan')} style={{textAlign: "right"}}>BERGEN</th>
                      <th onClick={() => sortBySku('transit')} style={{textAlign: "right"}}>TRNS</th>
                      <th onClick={() => sortBySku('pakistan')}style={{textAlign: "right"}}>PK</th>

                      <th onClick={() => sortBySku('production')} style={{textAlign: "right"}}>PROD</th>
                      <th onClick={() => sortBySku('pending')} style={{textAlign: "right"}}>PEND</th>
                      <th onClick={() => sortBySku('international')} style={{textAlign: "right"}}>INT</th>
                      <th onClick={() => sortBySku('ots')} style={{textAlign: "right"}}>OTS</th>
                      <th onClick={() => sortBySku('ecommerce')} style={{textAlign: "right"}}>ECOM</th>
                      <th onClick={() => sortBySku('nord')} style={{textAlign: "right"}}>NORD</th>
                      <th onClick={() => sortBySku('neiman')} style={{textAlign: "right"}}>NEIMAN</th>
                      <th onClick={() => sortBySku('saks')} style={{textAlign: "right"}}>SAKS</th>
                      <th onClick={() => sortBySku('verishop_canada')} style={{textAlign: "right"}}>VERISHOP</th>
                      <th onClick={() => sortBySku('bgs')} style={{textAlign: "right"}}>BGS</th>
                      <th onClick={() => sortBySku('pod')} style={{textAlign: "right"}}> POD</th>
                      <th>Women</th>
                      <th style={{textAlign: "right"}}>23</th>
                      <th style={{textAlign: "right"}}>24</th>
                      <th style={{textAlign: "right"}}>25</th>
                      <th style={{textAlign: "right"}}>26</th>
                      <th style={{textAlign: "right"}}>27</th>
                      <th style={{textAlign: "right"}}>28</th>
                      <th style={{textAlign: "right"}}>29</th>
                      <th style={{textAlign: "right"}}>30</th>
                      <th style={{textAlign: "right"}}>31</th>
                      <th style={{textAlign: "right"}}>32</th>
                      <th style={{textAlign: "right"}}>33</th>
                      <th style={{textAlign: "right"}}>34</th>
                      <th style={{textAlign: "right"}}>35</th>
                      <th style={{textAlign: "right"}}>36</th>
                      <th style={{textAlign: "right"}}>37</th>
                      <th style={{textAlign: "right"}}>38</th>
                    </tr>

                  </thead>
                  <tbody>
                    {data.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Link
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                              }}
                              to={{
                                pathname: '/sku-lookup',
                                state: data
                              }}
                            >
                              {data.item_code}
                            </Link>
                          </td>
                          <td>{data.inseam}</td>
                          <td>{data.style}</td>
                          <td>{data.wash}</td>
                          <td>{data.current_season}</td>
                          <td>{data.gender}</td>
                          <td>{data.fit_category}</td>
                          <td style={{textAlign: "right"}}>{currencySymbol}{Number(data.ws_price).toFixed(0)}</td>
                          <td style={{textAlign: "right"}}>{currencySymbol}{Number(data.retail_price).toFixed(0)}</td>
                          <td style={{textAlign: "right"}}>{data.bergan}</td>
                          <td style={{textAlign: "right"}}>{data.transit}</td>
                          <td style={{textAlign: "right"}}>{data.pakistan}</td>
                          <td style={{textAlign: "right"}}>{data.production}</td>
                          <td style={{textAlign: "right"}}><Link
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              textAlign: "right"
                            }}
                            to={{
                              pathname: '/pending-detail-summary-report',
                              state: data
                            }}
                          >{data.pending}</Link></td>
                          <td style={{textAlign: "right"}}>{data.international}</td>
                          <td style={{
                            color: "blue",
                            textAlign: "right"
                          }}>{data.ots}</td>
                          <td style={{textAlign: "right"}}>{data.ecommerce}</td>
                          <td style={{textAlign: "right"}}>{data.nord}</td>
                          <td style={{textAlign: "right"}}>{data.neiman}</td>
                          <td style={{textAlign: "right"}}>{data.saks}</td>
                          <td style={{textAlign: "right"}}>{data.verishop_canada}</td>
                          <td style={{textAlign: "right"}}>{data.bgs}</td>
                          <td style={{textAlign: "right"}}>{data.pod}</td>
                          <td></td>
                          <td style={{textAlign: "right"}}>{data.qty_1}</td>
                          <td style={{textAlign: "right"}}>{data.qty_2}</td>
                          <td style={{textAlign: "right"}}>{data.qty_3}</td>
                          <td style={{textAlign: "right"}}>{data.qty_4}</td>
                          <td style={{textAlign: "right"}}>{data.qty_5}</td>
                          <td style={{textAlign: "right"}}>{data.qty_6}</td>
                          <td style={{textAlign: "right"}}>{data.qty_7}</td>
                          <td style={{textAlign: "right"}}>{data.qty_8}</td>
                          <td style={{textAlign: "right"}}>{data.qty_9}</td>
                          <td style={{textAlign: "right"}}>{data.qty_10}</td>
                          <td style={{textAlign: "right"}}>{data.qty_11}</td>
                          <td style={{textAlign: "right"}}>{data.qty_12}</td>
                          <td style={{textAlign: "right"}}>{data.qty_13}</td>
                          <td style={{textAlign: "right"}}>{data.qty_14}</td>
                          <td style={{textAlign: "right"}}>{data.qty_15}</td>
                          <td style={{textAlign: "right"}}>{data.qty_16}</td>
                        </tr>
                      );
                    })}
                    <tr >
                      <td colSpan={9}></td>
                      <td>Total</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().berganTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().transitTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().pkTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().productionTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().pendingTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().intTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().otsTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().ecomTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().nordTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().neminTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().saksTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().verishopTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral(totals().bgsTotal).format("0,00,0")}</td>
                      <td style={{textAlign: "right"}}>{numeral((totals().podTotal)).format("0,00,0")}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div className="no-Data">
                  {/* {} */}
                  <h1>No Data</h1>
                </div>

              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ImediateOtsBySize;
