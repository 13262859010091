import { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
	DatePicker,
	Input,
	Tabs,
	Button,
	Form,
	Select,
	Tooltip,
	Table,
	Popconfirm,
	InputNumber,
} from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import Swal from "sweetalert2";
import { addCreditMemoHeader, updateCreditMemoHeader } from "../../../../redux/actions/creditMemo.action";
import { addCreditMemoLine, updateCreditMemoLine, getOneCreditMemoLine, deleteCreditMemoLine } from "../../../../redux/actions/creditMemoLine.action";
import { getChartofAccount } from "../../../../redux/actions/chartofaccount.action";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getAdminCurrency } from "../../../../redux/actions/adminCurrency.action";
import { getARAccounts } from "../../../../redux/actions/AR_Account.action";
import { getAdminLocation } from "../../../../redux/actions/adminLocation.action";
import { getShipVia } from "../../../../redux/actions/shipvia.action";
import { loadCustomers } from "../../../../redux/actions/customerForm";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getOneCreditMemoInvoice, deleteCreditMemoInvoice, updateCreditInvoice } from "../../../../redux/actions/creditMemoInvoice.action";
import { getPaymentTerm } from "../../../../redux/actions/paymentterm.action";
import { getGender } from "../../../../redux/actions/gender.action";
import { loadSalePerson } from "../../../../redux/actions/salePerson/salePersonForm.action";
import numeral from "numeral";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
import { getBrands } from "../../../../redux/actions/brand.action";
import { companyID, currencySymbol } from "../../../../utils/constants";
const { Option } = Select;
const { TabPane } = Tabs;

export default function CreditMemoForm({ formData, val, token, formState, submit, saleOrderID, changeState, loading: deleteLoading, setDisable }) {
	const dispatch = useDispatch();
	const { invoiceGlPeriod, companyAll, creditMemoLine, customerForms, arAccount, creditMemoInvoice, glImpact, paymentterm, gender, salePersonForm, systemLog, brand } = useSelector((state) => state);
	const companyId = ReadCookie("defaultCompany");
	const [form] = Form.useForm();
	const submitButton = useRef(null);
	const history = useHistory();
	const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));
	const [oDate, setOrderDate] = useState(moment());
	const [deletedItems, setDeletedItems] = useState([]);
	const [deletedMemoItems, setDeletedMemoItems] = useState([]);
	const [customer, setCustomer] = useState([])
	const focusInput = useRef(null);
	const [lines, setLines] = useState([]);
	const [memoInvoice, setMemoInvoice] = useState([]);
	const [menuId] = useState(1261);

	useEffect(() => {
		let resultArr = []
		dispatch(getOneInvoiceGlPeriod(token));
		invoiceGlPeriod?.payload?.map((item) => {
			return (
				resultArr.push(item)
			);
		})
		let result = resultArr.filter((item) => {
			return item.period_name == currentDate
		})
		setCurrentDate(result[0]?.period_id)
	}, []);

	useEffect(() => {
		dispatch(getChartofAccount(token));
		dispatch(getAdminCurrency(token, menuId));
		dispatch(getAllCompany(token));
		dispatch(getShipVia(token));
		dispatch(getARAccounts(token));
		dispatch(getAdminLocation(token));
		dispatch(loadSalePerson(token));
		dispatch(getGender(token, menuId));
		dispatch(getPaymentTerm(token, menuId));
		dispatch(getBrands(token));
	}, []);

	useEffect(() => {
		if (formState === "edit" || formState === "formView") {
			dispatch(getOneCreditMemoInvoice(token, saleOrderID)).then((res) => {
				setMemoInvoice(res?.payload?.data);
			});
			dispatch(getGLImpact(token, { "transaction_header_id": saleOrderID, "transaction_type": "CREDITMEMO" }));
		}
	}, [formState])

	useEffect(() => {
		if (formState === "add") {
			form.setFieldsValue({
				creditMemo: null,
				date: oDate,
				memo: null,
				postingPeriod: currentDate,
				paymentTerm: null,
				subsidiary: companyID,
				customer: null,
				gender: null,
				arAccount: null,
				sale_person: null,
			});
		}
	}, [formState]);

	useEffect(() => {
		if (formState === "formView") {
			dispatch(getOneCreditMemoLine(token, saleOrderID)).then((res) => {
				if (res.type === "GET_ONE_CREDIT_MEMO_LINE_SUCCESS") {
					setLines(res?.payload);
				}
			});
		}
	}, [formState]);

	useEffect(() => {
		if (formData) {
			form.setFieldsValue({
				creditMemo: formData?.memo_header_no,
				date: moment(formData?.memo_date),
				memo: formData?.memo,
				gender: formData?.gender_id,
				brand_id: formData?.brand_id,
				arAccount: formData?.ar_account_name,
				sale_person: formData?.sales_person_name,
				postingPeriod: currentDate,
				paymentTerm: formData?.term_id,
				subsidiary: formData?.company_name,
				customer: formData?.customer_name
			});
		}
	}, [formData]);

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	const handleCustomerChanges = (val) => {
		if (val === "" || val === 'All') return
		setCustomer(val);
		dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
			(res) => {
				if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
					setCustomer(res?.payload?.data)
				}
			}
		);
	};

	const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

	function debounce(cb, delay = 1000) {
		let timeout
		return (...args) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				cb(...args)
			}, delay)
		}
	}

	function handleChange(value, type) {
		if (type === "company") {
			companyId(value);
			form.setFieldsValue({ company: value });
		}
	}

	const onFinish = async (e) => {
		submit(false);
		const filteredLines = lines.filter(x => x.quantity > 0 && x.rate > 0 && !!x.item_id)
		if (!filteredLines.length) {
			setDisable(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "select atleast one Non Inventory Item",
			});
			return
		}
		await form.validateFields().then((values) => {
			setDisable(true);
			if (formState === "add") {
				dispatch(addCreditMemoHeader(token, values, parseInt(companyId))).then((res) => {
					let id = res?.payload?.memo_header_id;
					if (res.type === "ADD_CREDIT_MEMO_HEADER_SUCCESS") {
						dispatch(
							addCreditMemoLine(
								token,
								filteredLines.map((item) => {
									delete item.account_id;
									delete item.id;
									delete item.item_name;
									return {
										...item,
										memo_header_id: id
									};
								})
							)
						)
							.then((resline) => {
								if (resline.type === "ADD_CREDIT_MEMO_LINE_SUCCESS") {
									notification("success", "Credit", "Credit Memo created successfully!");
									setDisable(false);
									history.push({
										pathname: `/credit-memo/edit/${id}`,
										search: `?_id=${id}`
									})

								} else if (res.type === "ADD_CREDIT_MEMO_LINE_ERROR") {
									setDisable(false);
									Swal.fire({
										icon: "error",
										title: "Oops...",
										text: res?.payload?.data?.data,
									});
								}
							});
					} else if (res.type === "ADD_CREDIT_MEMO_HEADER_ERROR") {
						setDisable(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				})
			} else if (formState === "edit") {
				if (deletedItems.length > 0) {
					dispatch(deleteCreditMemoLine(token, saleOrderID, deletedItems));
				}
				if (deletedMemoItems.length > 0) {
					dispatch(deleteCreditMemoInvoice(token, saleOrderID, deletedMemoItems));
				}
				dispatch(updateCreditMemoHeader(token, values, saleOrderID, parseInt(companyId))).then((res) => {
					if (res.type === "UPDATE_CREDIT_MEMO_HEADER_SUCCESS") {
						dispatch(
							updateCreditMemoLine(
								token,
								filteredLines.map((item) => {
									delete item.deleted_date;
									delete item.deleted_by;
									delete item.last_updated_by;
									delete item.last_updated_date;
									delete item.created_by;
									delete item.created_date;
									delete item.item_name;
									delete item.memo_header_id;
									return {
										...item,
									};
								}), saleOrderID
							)
						).then((resline) => {
							if (resline.type === "UPDATE_CREDIT_MEMO_LINE_SUCCESS") {
								dispatch(
									updateCreditInvoice(
										token,
										memoInvoice,
										saleOrderID
									)
								).then((res) => {
									if (res.type === "UPDATE_CREDIT_MEMO_INVOICE_SUCCESS") {
										changeState("formView")
										setDisable(false);
										notification("success", "Credit Memo Updated");
									} else if (res.type === "UPDATE_CREDIT_MEMO_INVOICE_ERROR") {
										setDisable(false);
										Swal.fire({
											icon: "error",
											title: "Oops...",
											text: res?.payload?.data?.data,
										});
									}
								});
							} else if (res.type === "UPDATE_CREDIT_MEMO_LINE_ERROR") {
								setDisable(false);
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: res?.payload?.data?.data,
								});
							}
						})
					} else if (res.type === "UPDATE_CREDIT_MEMO_HEADER_ERROR") {
						setDisable(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			}
		}).catch(err => { });
	};

	const handleTab = (key) => {
		if (key === "header") {
			dispatch(getSystemLog(token, { table_name: "ar_credit_memo_header", record_id: saleOrderID }));
		} else if (key === "line") {
			dispatch(getSystemLog(token, { table_name: "ar_credit_memo_lines", record_id: saleOrderID }));
		}
	};

	const deleteItemRow = (record) => {
		const data = [...lines];
		const deleteSource = [...deletedItems];
		deleteSource.push(record);
		if (data.length > 0) {
			setLines(data.filter((val) => formState === "add" ? (val.id !== record.id) : (val.memo_line_id !== record.memo_line_id)));
			setDeletedItems(deleteSource);
		} else {
			const data = [];
			setLines(data.filter((item) => item.memo_line_id !== record.memo_line_id));
		}
	}

	const deleteMemoInvoiceRow = (record) => {
		const data = [...memoInvoice];
		const deleteSource = [...deletedMemoItems];
		deleteSource.push(record);
		setDeletedMemoItems(deleteSource);
		setMemoInvoice(data.filter((val) => val.memo_invoice_id !== record.memo_invoice_id));
	}

	const renderColumns = () => {
		const keys = Object.keys(systemLog?.payload?.data[0])
		return keys.map((it, i) => {
			return {
				title: it,
				dataIndex: it,
				key: i.toString(),
			}
		})
	}

	const addLines = () => {
		const data = [...lines];
		const iterateId = Math.floor(Math.random() * 100);
		const newItem = { id: iterateId, rate: 0, quantity: 0, description: '' }
		if (lines.length <= 50) {
			data.push(newItem);
			setLines(data);

		} else {
			notification("error", "Only 50 Lines Item can be Edit");
		}
	}

	const summary = () => {
		let quantity = 0;
		let gross_amount = 0;
		let tax = 0;
		let net_amount = 0;
		lines.forEach((v) => {
			quantity += Number(v.quantity) || 0;
			gross_amount += Number(v.gross_amount) || 0;
			tax += Number(v.tax_amount) || 0;
			net_amount += Number(v.net_amount) || 0;
		})
		return {
			quantity: quantity,
			tax: tax.toFixed(2),
			net_amount: net_amount.toFixed(2),
			gross_amount: gross_amount.toFixed(2)
		}
	}

	const LinesColumns = [
		{
			title: "No Inventory",
			dataIndex: "item_id",
			width: 140,
			render: (text, record, index) => {
				return (
					<Form.Item
						labelAlign="left"
					>
						<Select
							value={record?.item_id}
							size="small"

							autoComplete={"nope"}
							allowClear
							disabled={!(formState === "add" || formState === "edit")}
							loading={arAccount.loading}
							onChange={(e) => {
								let arr = [...lines];
								arr[index].item_id = e;
								setLines(arr);
							}}
						>
							<Option key={22} value={537374}>10001-MARK DOWN ALLOWANCE</Option>
							<Option key={21} value={537375}>10002-COOP ALLOWANCE</Option>
							<Option key={20} value={537376}>10003-DISCOUNT ALLOWANCE</Option>
							<Option key={19} value={537377}>10004-OTHERS</Option>
						</Select>
					</Form.Item>
				)
			}
		},
		{
			title: "Qty",
			dataIndex: "quantity",
			width: 100,
			render: (text, record, index) => {
				return (
					<Form.Item labelAlign="left" labelCol={{ span: 9 }}
						value={record.quantity}
						style={{ width: 100 }}
					>
						<InputNumber
							onChange={(e, i) => {
								setLines(lines.map((f, i) => {
									if (i === index) {
										const tax_amount = ((Number(record.tax_percent) / 100) * Number(record.rate)) * e
										const gross_amount = Number(record.rate) * e
										f.quantity = e
										f.gross_amount = gross_amount.toFixed(2)
										f.tax_amount = (tax_amount || 0).toFixed(2)
										f.net_amount = ((gross_amount || 0) + (tax_amount || 0)).toFixed(2)
									}
									return f
								}))
							}}
							value={record?.quantity === "" ? null : record?.quantity}
							tabIndex={18} size="small" className="customInputs" disabled={
								!(formState === "add" || formState === "edit")

							} />
					</Form.Item>
				)
			}
		},
		{
			title: "Rate",
			dataIndex: "rate",
			width: 100,
			render: (text, record, index) => {
				return (
					<Form.Item labelAlign="left" labelCol={{ span: 9 }}
						style={{ width: 100 }}
					>
						<InputNumber
							value={record.rate}
							onChange={(e) => {
								setLines(lines.map((f, i) => {
									if (i === index) {
										const tax_amount = ((Number(record.tax_percent) / 100) * e) * Number(record.quantity)
										const gross_amount = e * Number(record.quantity)
										f.rate = e
										f.gross_amount = gross_amount.toFixed(2)
										f.tax_amount = (tax_amount || 0).toFixed(2)
										f.net_amount = ((gross_amount || 0) + (tax_amount || 0)).toFixed(2)
									}
									return f
								}))
							}}
							tabIndex={18} size="small" className="customInputs" disabled={
								!(formState === "add" || formState === "edit")

							} />
					</Form.Item>
				)
			}
		},
		{
			title: "Amount",
			dataIndex: "gross_amount",
			width: 100,
			render: (text, record, index) => {
				return (
					<Form.Item labelAlign="left" labelCol={{ span: 9 }}
						style={{ width: 100 }}
					>
						<InputNumber disabled size="small" value={record.gross_amount} />
					</Form.Item>
				)
			}
		},
		{
			title: "Tax Percent",
			dataIndex: "tax_percent",
			width: 100,
			render: (text, record, index) => {
				return (
					<Form.Item labelAlign="left" labelCol={{ span: 9 }}
						style={{ width: 100 }}
					>
						<InputNumber
							value={record.tax_percent}
							onChange={(e) => {
								setLines(lines.map((f, i) => {
									if (i === index) {
										const tax_amount = ((e / 100) * Number(record.rate)) * Number(record.quantity)
										f.tax_percent = e
										f.tax_amount = (tax_amount || 0).toFixed(2)
										f.net_amount = ((Number(record.gross_amount) || 0) + (tax_amount || 0)).toFixed(2)
									}
									return f
								}))
							}}
							tabIndex={18} size="small" className="customInputs" disabled={
								!(formState === "add" || formState === "edit")

							} />
					</Form.Item>
				)
			}
		},
		{
			title: "Tax Amount",
			dataIndex: "tax_amount",
			width: 100,
			render: (text, record, index) => {
				return (
					<Form.Item labelAlign="left" labelCol={{ span: 9 }}
						style={{ width: 100 }}
					>
						<InputNumber disabled size="small" value={record.tax_amount} />
					</Form.Item>
				)
			}
		},
		{
			title: "Net Amount",
			dataIndex: "net_amount",
			width: 100,
			render: (text, record, index) => {
				return (
					<Form.Item labelAlign="left" labelCol={{ span: 9 }}
						style={{ width: 100 }}
					>
						<InputNumber disabled size="small" value={record.net_amount} />
					</Form.Item>
				)
			}
		},
		{
			title: "Description",
			dataIndex: "description",
			width: 100,
			render: (text, record, index) => {
				return (
					<Form.Item labelAlign="left" labelCol={{ span: 9 }}>
						<Input
							type="text"
							style={{ width: 100 }}
							value={record.description}
							onChange={(e) => {
								setLines(lines.map((f, i) => {
									if (i === index) {
										f.description = e.target.value
									}
									return f
								}))
							}}
							tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
					</Form.Item>
				)
			}
		},
		{
			title: "operation",
			width: 100,
			render: (record, index) => (
				formState === "add" || formState === "edit" ? (
					<Tooltip title="Delete" color={"white"}>
						<Popconfirm title={`Are you sure to delete the item ${formState === "add" ? record.id : record.memo_line_id}?`} onConfirm={() => deleteItemRow(record)}>
							<DeleteOutlined className="delete-Button" />
						</Popconfirm>
					</Tooltip >
				) : null
			)
		}
	];

	const updateInvoiceAmount = (value, index) => {
		let copyArr = [...memoInvoice];
		copyArr[index].invoice_amount = value;
		setMemoInvoice(copyArr);
	}

	const updatePaymentAmount = (value, index) => {
		let copyArr = [...memoInvoice];
		copyArr[index].payment_amount = value;
		setMemoInvoice(copyArr);
	}

	const memoInvoiceLine = [
		{
			title: "Invoice Header No",
			dataIndex: "invoice_header_no",
		},
		{
			title: "Transaction Type",
			dataIndex: "transaction_type",
		},
		{
			title: "Invoice Amount",
			dataIndex: "invoice_amount",
			render: (text, record, index) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								defaultValue={record?.invoice_amount}
								style={{ width: 250 }}
							>
								<InputNumber
									value={record?.invoice_amount}
									onChange={(e) => {
										updateInvoiceAmount(e, index)

									}}
								/>
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Payment Amount",
			dataIndex: "payment_amount",
			render: (text, record, index) => {

				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								style={{ width: 250 }}
							>
								<InputNumber
									value={record.payment_amount}
									onChange={(e, i) => {
										updatePaymentAmount(e, index)
									}}
								/>
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "operation",
			render: (record, index) => (
				formState === "add" || formState === "edit" ? (
					<Tooltip title="Delete" color={"white"}>
						<Popconfirm title={`Are you sure to delete the item ${record.memo_invoice_id}`} onConfirm={() => deleteMemoInvoiceRow(record)}>
							<DeleteOutlined className="delete-Button" />
						</Popconfirm>
					</Tooltip >
				) : null
			)
		}
	];

	const glImpcatCols = [
		{
			title: "Account Name",
			dataIndex: "account_name",
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record, index) => {
				return (
					<>
						{currencySymbol} {numeral(record.debit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record, index) => {
				return (
					<>
						{currencySymbol} {numeral(record.credit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]

	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
	};

	function checkTransactionDate(e) {
		let transaction_date = moment(e).format("YYYY-MM-DD")
		dispatch(getTransationLock(token, transaction_date)).then((r) => {
			if (r.payload.data.length > 0) {
				setOrderDate(moment())
				form.setFieldsValue({
					date: moment()
				})
				Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
			}
			else {
				setOrderDate(moment(e))
			}
		});
	}

	return (
		<>
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Brow>
							<Bcol md={12} xl={12} xxl={10} className="pbpx-10">
								<Brow>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="creditMemo" label="Credit Memo" labelAlign="left" labelCol={{ span: 5 }} >
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Date" name="date" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Date is required" }]}>
												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().date
															: moment(oDate)
													}
													onChange={(e) => {
														setOrderDate(moment(e))
														let result = invoiceGlPeriod?.payload?.find((item) => {
															return item.period_name === moment(e).format("MMM YYYY")
														});
														setCurrentDate(result?.period_id);
														form.setFieldsValue({ postingPeriod: result?.period_id });
														checkTransactionDate(e);
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().postingPeriod}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													showSearch
													allowClear
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												labelAlign="left"
												name="customer"
												label="Customer"
												labelCol={{ span: 5 }}
												rules={[{ required: true, message: "Customer is required" }]}
											>
												<Select
													notFoundContent={customerForms?.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<Loader type="Oval" color="#3c4b58" height={20} width={20} />
													</div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													disabled={!(formState === "add" || formState === "edit")}
													optionFilterProp="children"
													className="filter-fields"
													loading={customerForms?.loading}
													onSearch={(value) => {
														optimizedFn(value)
													}}
													ref={focusInput}
													value={customer}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{customerForms?.payload?.data?.map(
														(item) => {
															return (
																<Option
																	key={item.customer_id}
																	value={item.customer_id}
																>
																	{`${item.customer_code} ${item.customer_name}`}
																</Option>
															);
														}
													)}
												</Select>
											</Form.Item>

										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Payment Terms" name="paymentTerm" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().paymentTerm} rules={[{ required: true, message: "Payment Term is required" }]}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().paymentTerm}
													onChange={(value) => {
														form.setFieldsValue({
															paymentTerm: value,
														});
													}}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{paymentterm?.payload?.data.map((item) => {
														return (
															<Option key={item.term_id} value={item.term_id}>
																{item.term_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 5 }}>
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="slc">
											<Form.Item
												name="gender"
												label="Gender"
												labelAlign="left"
												labelCol={{ span: 5 }}
												rules={[{ required: true, message: "Gender is required" }]}
											>
												<Select
													size='small'
													showSearch
													optionFilterProp="children"
													filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													className="styleInput w-100"
													defaultValue={form.getFieldValue().gender}
													disabled={!(formState === "add" || formState === "edit")}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{
														gender?.payload?.data.map((val) => {
															return (
																<Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
															)
														})
													}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="slc">
											<Form.Item
												name="sale_person"
												label="Sale Person"
												labelAlign="left"
												labelCol={{ span: 5 }}
											>
												<Select
													size='small'
													showSearch
													optionFilterProp="children"
													filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													className="styleInput w-100"
													defaultValue={form.getFieldValue().sale_person}
													disabled={!(formState === "add" || formState === "edit")}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{
														salePersonForm?.payload?.data.map((val, index) => {
															return (
																<Option key={val.sales_person_id} value={val.sales_person_id}>{val.salesperson_name}</Option>
															)
														})
													}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="slc">
											<Form.Item
												name="arAccount"
												label="A/R Account"
												labelAlign="left"
												labelCol={{ span: 5 }}
												rules={[{ required: true, message: "AR Account is required" }]}
											>
												<Select
													size='small'
													showSearch
													optionFilterProp="children"
													filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													className="styleInput w-100"
													defaultValue={form.getFieldValue().arAccount}
													disabled={!(formState === "add" || formState === "edit")}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{
														(arAccount?.payload?.data || []).map((val) => {
															return (
																<Option key={val.account_id} value={val.account_id}>{val.account_name}</Option>
															)
														})
													}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="slc">
											<Form.Item
												name="brand_id"
												label="Brand"
												labelAlign="left"
												labelCol={{ span: 5 }}
												rules={[{ required: true, message: "Brand is required" }]}
											>
												<Select
													size='small'
													showSearch
													optionFilterProp="children"
													filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													className="styleInput w-100"
													defaultValue={form.getFieldValue().arAccount}
													disabled={!(formState === "add" || formState === "edit")}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{brand?.payload?.data?.map((res) => (
														<Option key={res.brand_id} value={res.brand_id}>
															{res.brand_name}
														</Option>
													))}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
							</Bcol>
							<Bcol md={4} xl={3} xxl={2}>
								<div className="firstSectionRo">
									<div className="order-summary">
										<div className="summary-title">Credit Memo Summary</div>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Quantity:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">
													{summary().quantity}
												</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Sub Total:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">
													{currencySymbol} {numeral(summary().gross_amount).format("00.00")}
												</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Add Tax:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">
													{currencySymbol} {numeral(summary().tax).format("00.00")}
												</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Amount:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">
													{currencySymbol} {numeral(summary().net_amount).format("00.00")}
												</p>
											</Bcol>
										</Brow>
									</div>
								</div>
							</Bcol>
						</Brow>
						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
					<SubTitles name={"Classification"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Bcol md={12} xl={9} xxl={10}>
							<Brow>
								<Bcol lg={6} xxl={4}>
									<div className="firstSectionRo">
										<Form.Item
											name="subsidiary"
											label="Subsidiary"
											labelAlign="left"
											labelCol={{ span: 5 }}
											rules={[{ required: true, message: "Subsidiary Id is required" }]}
										>
											<Select
												size="small"
												autoComplete={"nope"}
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												showSearch
												allowClear
												loading={companyAll.loading}
												disabled={true}
												defaultValue={form.getFieldValue().companyId}
												onChange={(value) => handleChange(value)}
												filterSort={(optionA, optionB) =>
													optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
												}
											>
												{companyAll?.payload?.data.map((item) => {
													return (
														<Option key={item.company_id} value={item.company_id}>
															{item.company_name}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</div>
								</Bcol>
							</Brow>
						</Bcol>
					</div>
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
						<TabPane tab="Credit" key="1">
							<Button type="primary" className="modalButton" disabled={!(formState === "edit" || formState === "add")} onClick={addLines}>
								Non Inventory Item
							</Button>
							{(lines.length > 0) && (
								<Table
									type={"company"}
									loading={creditMemoLine?.loading}
									scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									style={{ paddingTop: 10 }}
									dataSource={lines}
									columns={LinesColumns}
									pagination={false}
								/>
							)}
						</TabPane>
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="Credit Memo Invoice" key="credit_Memo_Invoice" >
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={memoInvoice}
										col={memoInvoiceLine}
										load={creditMemoInvoice.loading}
										paginate
									/>
								</div>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="System Information" key="logs" >
								<Tabs
									type="card"
									size="small"
									defaultActiveKey="1"
									centered
									onChange={(key) => handleTab(key)}
								>
									<TabPane tab="Header" key="header">
										<div className="tabs">
											{systemLog?.payload?.data?.length > 0 && <Tables
												type="company"
												scrollX={"50rem"}
												scroll={360}
												perPage={15}
												source={systemLog?.payload?.data}
												dataSource={[]}
												col={renderColumns()}
												load={systemLog.loading}
												paginate
											/>}
										</div>
									</TabPane>
									<TabPane tab="Line" key="line">
										<div className="tabs">
											{systemLog?.payload?.data?.length > 0 && <Tables
												type="company"
												scrollX={"50rem"}
												scroll={360}
												perPage={15}
												source={systemLog?.payload?.data}
												dataSource={[]}
												col={renderColumns()}
												load={systemLog.loading}
												paginate
											/>}
										</div>
									</TabPane>
								</Tabs>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="GL Impact" key="gl_impact" >
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={glImpact?.payload?.data}
										col={glImpcatCols}
										load={glImpact.loading}
										paginate
									/>
								</div>
							</TabPane>}
					</Tabs>
				</div>
			</Form>
		</>
	);
}
