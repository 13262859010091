import React, { useEffect, useState, useRef } from "react";
import { Spin, Select, DatePicker, Checkbox, Form, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { ReadCookie } from "../../utils/readCookie";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { getOtsDashboard } from "../../redux/actions/otsDashboard.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import numeral from "numeral";
const dateFormatList = ["MM-DD-YYYY"];

const OTSDashboard = ({ token }) => {
  TabTitle("OTS Dashboard")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    gender,
    season,
    productStatus,
    otsDashboard
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [isChecked2, setIsChecked2] = useState([]);
  const [allStatus, setAllStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const [excelData, setExcelData] = useState([])

  const groupedData = otsDashboard?.payload?.data.reduce((acc, item) => {
    if (!acc[item.gender]) {
      acc[item.gender] = [];
    }
    acc[item.gender].push(item);
    return acc;
  }, {});


  const { Option } = Select;
  let pendingDate = "";

  const onChangeDate = (date, dateString) => {
    pendingDate = dateString;
  };

  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus && productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [...current, filterCheckBox.product_status_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      dispatch(
        getOtsDashboard(token, values, [], isChecked2, [], false)).then((res => {
          setLoading(false)
          downloadExcelFileData(res?.payload?.data);
        }));
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Gender": v.gender,
          "CURR SSN": v.current_season,
          "# SKU": v.noofsku,
          "BERGEN": v.bergan,
          "TRNST #": v.transit,
          "Bulk WW": v.bulk_ww,
          "Bulk DL": v.bulk_dl,
          "PEND": v.pending,
          "OTS": v.ots,
          "Speciality": v.shipped_special,
          "Major's": v.shipped_depart,
          "Online": v.shipped_online,
          "OFF Price": v.shipped_offprice,
          "International": v.shipped_international,
          "Internal": v.shipped_internal,
          "Total": parseFloat(v.shipped_special) + parseFloat(v.shipped_depart) + parseFloat(v.shipped_online) + parseFloat(v.shipped_offprice) + parseFloat(v.shipped_international) + parseFloat(v.shipped_internal),
        };
      })
    )
  }

  useEffect(() => {
    dispatch(getSeason(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getGender(token, menuId));
  }, [dispatch, menuId, token]);


  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus])


  const handlePrint = () => {
    window.print();
  };

  const columns = [
    {
      title: "#",
      className: "b_bottom",
      render: (text, record, index) => {
        return index + 1
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      className: " b_left b_bottom",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.gender.localeCompare(b.gender)
      },
    },
    {
      title: "CURR SSN",
      dataIndex: "current_season",
      key: "current_season",
      className: " b_left b_bottom",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.current_season.localeCompare(b.current_season)
      },
    },
    {
      title: "SKU ",
      dataIndex: "noofsku",
      key: "noofsku",
      className: "b_left b_right_none b_bottom",
      align: "right",
      sorter: (a, b) => {
        return a.noofsku - b.noofsku
      },
    },
    {
      title: "BERGEN",
      dataIndex: "bergan",
      key: "bergan",
      align: "right",
      className: "b_left b_bottom",
      render: (text, record) => {
        return {
          children: <div>{numeral(text).format("0,00,0")}</div>
        }
      },
      sorter: (a, b) => {
        return a.bergan - b.bergan
      },
    },
    {
      title: "TRNST #",
      dataIndex: "transit",
      key: "transit",
      align: "right",
      className: "b_right b_left b_bottom",
      render: (text, record) => {
        return {
          children: <div>{parseFloat(text).toLocaleString('en-US')}</div>
        }
      },
      sorter: (a, b) => {
        return a.transit - b.transit
      },
    },
    {
      title: "Bulk WW",
      dataIndex: "bulk_ww",
      key: "bulk_ww",
      align: "right",
      className: "b_left b_right_none b_bottom",
      render: (text, record) => {
        return (
          <>
            {
              parseFloat(text).toLocaleString('en-US')
            }
          </>
        );
      },
      sorter: (a, b) => {
        return a.bulk_ww - b.bulk_ww
      },
    },
    {
      title: "Bulk DL",
      dataIndex: "bulk_dl",
      key: "bulk_dl",
      align: "right",
      className: " b_left b_bottom",
      render: (text, record) => {
        return (
          <>
            {
              parseFloat(text).toLocaleString('en-US')
            }
          </>
        );
      },
      sorter: (a, b) => {
        return a.bulk_dl - b.bulk_dl
      },
    },
    {
      title: "PEND",
      dataIndex: "pending",
      key: "pending",
      align: "right",
      className: " b_left b_bottom",
      render: (text, record) => {
        return {
          children:
            <div>
              {
                parseFloat(text).toLocaleString('en-US')
              }
            </div>
        }
      },
      sorter: (a, b) => {
        return a.pending - b.pending
      },
    },
    {
      title: "OTS",
      dataIndex: "ots",
      key: "ots",
      align: "right",
      className: " b_left b_bottom",
      render: (text, record) => {
        return (
          <>
            {
              parseFloat(text).toLocaleString('en-US')
            }
          </>
        );
      },
      sorter: (a, b) => {
        return a.ots - b.ots
      },
    },
    {
      title: "SHIPPED",
      children: [
        {
          title: "SPECIALTY",
          dataIndex: "shipped_special",
          key: "shipped_special",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return numeral(text).format("0,00,0")
          },
          sorter: (a, b) => {
            return a.shipped_special - b.shipped_special
          },
        },
        {
          title: "MAJOR",
          dataIndex: "shipped_depart",
          key: "shipped_depart",
          align: "right",
          className: "b_left b_right_none b_bottom",
          render: (text, record) => {
            return numeral(text).format("0,00,0")
          },
          sorter: (a, b) => {
            return a.shipped_depart - b.shipped_depart
          },
        },
        {
          title: "ONLINE",
          dataIndex: "shipped_online",
          key: "shipped_online",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return numeral(text).format("0,00,0")
          },
          sorter: (a, b) => {
            return a.shipped_online - b.shipped_online
          },
        },
        {
          title: "OFF PRICE",
          dataIndex: "shipped_offprice",
          key: "shipped_offprice",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return numeral(text).format("0,00,0")
          },
          sorter: (a, b) => {
            return a.shipped_offprice - b.shipped_offprice
          },
        },

        {
          title: "INTERNATIONAL",
          dataIndex: "shipped_international",
          key: "shipped_international",
          align: "right",
          className: "b_left b_right_none b_bottom",
          render: (text, record) => {
            return numeral(text).format("0,00,0")
          },
          sorter: (a, b) => {
            return a.shipped_international - b.shipped_international
          },
        },
        {
          title: "INTERNAL",
          dataIndex: "shipped_internal",
          key: "shipped_internal",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return numeral(text).format("0,00,0")
          },
          sorter: (a, b) => {
            return a.shipped_internal - b.shipped_internal
          },
        },
        {
          title: "TOTAL",
          align: "right",
          className: "b_left b_bottom",
          render: (text, record) => {
            return numeral(Number(record.shipped_special) + Number(record.shipped_depart) + Number(record.shipped_online) + Number(record.shipped_offprice) + Number(record.shipped_international) + Number(record.shipped_internal)).format("0,00,0")
          },
        },
        {
          title: "ST %",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return numeral(
              (
                (parseFloat(record.shipped_special) + parseFloat(record.shipped_depart) + parseFloat(record.shipped_online) + parseFloat(record.shipped_offprice) + parseFloat(record.shipped_international) + parseFloat(record.shipped_internal)) -
                parseFloat(record.pending))
              /
              (
                (record.ots + record.bulk_ww + record.bulk_dl) +
                (parseFloat(record.shipped_special) + parseFloat(record.shipped_depart) + parseFloat(record.shipped_online) + parseFloat(record.shipped_offprice) + parseFloat(record.shipped_international) + parseFloat(record.shipped_internal)) -
                (record.pending))
            ).format("0.00")
          },
        },
      ],
    },
  ];


  return (
    <div className="header_class business_header_oms">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"OTS-Dashboard.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              OTS Dashboard
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div>
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              <hr className="my-1" />
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Season</label>
                    <div className="w-100">
                      <Form.Item name="season_id" initialValue={'All'}>
                        <Select
                          defaultValue={form.getFieldValue().season_id}
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={status.season_id}
                                  >
                                    {status.season_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                </div>
                {/*Second*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Gender</label>
                    <div className="w-100">
                      <Form.Item name="gender_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_id}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Third*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">From Date</label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment().startOf('year')}
                      >
                        <DatePicker
                          size="small"
                          onChange={onChangeDate}
                          format={dateFormatList}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Fourth*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">To Date</label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          onChange={onChangeDate}
                          format={dateFormatList}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="justify-content-end" style={{ display: "flex", alignItems: "end" }}>
                    <label className=" no_visibility">none</label>
                    <Button
                      style={{ width: "fit-content" }}
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0 float-end"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="table_content"
        style={{ width: 'fit-content' }}
        ref={tableRef}
      >
        <div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin />
            </div>
          ) : otsDashboard?.payload?.data.length ? (
            // Loop through each state group and render a table
            Object.entries(groupedData).map(([gender, data]) => (
              <div key={gender} >
                <h3 style={{ borderBottom: "1px solid black", paddingBottom: "1px", display: "inline-block" }}>{gender}</h3>
                <Table
                  style={{ fontSize: '10.3px' }}
                  className="test04 mt-3 sales_target_report_table sales_report_res_table sales_target_report_table_dashboard speciality_state"
                  size="small"
                  pagination={false}
                  summary={pageData => {
                    let bergen = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.bergan)
                    }, 0);
                    let trnst = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.transit)
                    }, 0);
                    let bulkWW = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.bulk_ww)
                    }, 0);
                    let bulkDL = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.bulk_dl)
                    }, 0);
                    let pend = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.pending)
                    }, 0);
                    let ots = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.ots)
                    }, 0);
                    let speciality = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_special)
                    }, 0);
                    let major = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_depart)
                    }, 0);
                    let online = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_online)
                    }, 0);
                    let offPrice = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_offprice)
                    }, 0);
                    let international = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_international)
                    }, 0);
                    let internal = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_internal)
                    }, 0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right" }}>Total Quantity</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(bergen.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(trnst.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(bulkWW.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(bulkDL.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(pend.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(ots.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(speciality.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(major.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {online.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {offPrice.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {international.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {internal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral((Number(speciality) + Number(major) + Number(online) + Number(offPrice) + Number(international) + Number(internal)).toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                  bordered
                  columns={columns}
                  scroll={{ x: 'max-content' }}
                  dataSource={data}
                />
              </div>
            ))
          ) : (
            <div
              style={{
                backgroundColor: '#CCCCCC',
                borderWidth: '1pt',
                borderStyle: 'Dashed',
                fontWeight: 'bold',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              No record(s)
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OTSDashboard;
