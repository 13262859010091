import {
  GET_ALL_PRODUCT_FOR_INT_REQUEST,
  GET_ALL_PRODUCT_FOR_INT_SUCCESS,
  GET_ALL_PRODUCT_FOR_INT_ERROR,

} from "./types";

import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getProductForInt = (token, data, saleOrderID, fromLocationValue, brand_id) => (dispatch) => {




  data.map((record) => {
    delete record.Id;

    return record.item_code;
  })

  dispatch({ type: GET_ALL_PRODUCT_FOR_INT_REQUEST });
  return axios({
    method: "POST",

    url: `${BASEURL}getAll/productForInvAdj`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      data,
      fromLocationValue,
      brand_id
    },
  })
    .then((response) => {

      return dispatch({
        type: GET_ALL_PRODUCT_FOR_INT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_PRODUCT_FOR_INT_ERROR,
        payload: error.response,
      });
    });
};


