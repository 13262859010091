import {
  GET_BOOK_SUMMARY_REQUEST,
  GET_BOOK_SUMMARY_SUCCESS,
  GET_BOOK_SUMMARY_ERROR,
  GET_ONLINE_ORDERS_SUMMARY_REQUEST,
  GET_ONLINE_ORDERS_SUMMARY_SUCCESS,
  GET_ONLINE_ORDERS_SUMMARY_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getBookSummary = (token, values) => (dispatch) => {
  let cleanedVal = values.customer === undefined ? null : values.customer.includes("'") ? values.customer.replace(/'/g, "''") : values.customer;
  let cleanedVal1 = values.billing_addressee === undefined ? null : values.billing_addressee.includes("'") ? values.billing_addressee.replace(/'/g, "''") : values.billing_addressee;
  dispatch({ type: GET_BOOK_SUMMARY_REQUEST });
  const newData = {
    customer_cat_id: values.cutomer_category === "All" || values.cutomer_category === undefined ? null : values.cutomer_category,
    date_from: moment(values.from_date).format("MM-DD-YYYY"),
    date_to: moment(values.to_date).format("MM-DD-YYYY"),
    order_header_no: values.order_no === undefined || values.order_no === "" ? null : values.order_no,
    reference_no: values.po_no === undefined || values.po_no === "" ? null : values.po_no,
    customer: cleanedVal === undefined || cleanedVal === "" ? null : cleanedVal,
    bill_addressee: cleanedVal1 === undefined || cleanedVal1 === "" ? null : cleanedVal1,
    sp_id: values.sale_person === "All" || values.sale_person === undefined ? null : values.sale_person,
    region_id: values.region === "All" || values.region === undefined ? null : values.region,
    gender_id: values.gender === "All" || values.gender === undefined ? null : values.gender,
    brand_id: values.brand_id === "All" || values.brand_id === undefined ? null : values.brand_id,
    payment_term_id: values.terms === "All" || values.terms === undefined ? null : values.terms,
    store_id: values.store === undefined || values.store === "" ? null : values.store,
    dc_no: values.dc === undefined || values.dc === "" ? null : values.dc,
    department_code: values.dept === undefined || values.dept === "" ? null : values.dept,
    company_id: values.subsidary === "All" || values.subsidary === undefined ? null : values.subsidary,
    source_type: values.source === undefined || values.source === "" ? null : values.source,
    sku: values.sku === undefined || values.sku === "" ? null : values.sku,
    status: values.status === undefined || values.status === "All" ? null : values.status,
    style_id: values.style === undefined || values.style === "All" ? null : values.style,
    location_id: values.location === undefined || values.location === "All" ? null : values.location,
    customer_id: values.customerNew === undefined ? null : values.customerNew,
    memo: values.memo === undefined ? null : values.memo,
  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/funcOmsBooked`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {
      return dispatch({
        type: GET_BOOK_SUMMARY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_BOOK_SUMMARY_ERROR,
        payload: [],
      });
    });
};

export const getOnlineOrdersSummary = (token, values) => (dispatch) => {
  dispatch({ type: GET_ONLINE_ORDERS_SUMMARY_REQUEST });
  const newData = {
    date_from: moment(values.from_date).format("YYYY-MM-DD"),
    date_to: moment(values.to_date).format("YYYY-MM-DD"),
    order_header_no:
      values.order_no === undefined || values.order_no === ""
        ? null
        : values.order_no,
    reference_no:
      values.po_no === undefined || values.po_no === "" ? null : values.po_no,
  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/onlineOrdersSummary`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {
      return dispatch({
        type: GET_ONLINE_ORDERS_SUMMARY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ONLINE_ORDERS_SUMMARY_ERROR,
        payload: [],
      });
    });
};