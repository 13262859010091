import {
  GET_ALL_RETURN_DETAIL_REQUEST,
  GET_ALL_RETURN_DETAIL_SUCCESS,
  GET_ALL_RETURN_DETAIL_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getReturnDetail = (token, values) => (dispatch) => {
  dispatch({ type: GET_ALL_RETURN_DETAIL_REQUEST });

  const newData = {
    customer_cat_id: values.cutomer_category === "All" ? null : values.cutomer_category,
    date_from: moment(values.from_date).format("MM-DD-YYYY"),
    date_to: moment(values.to_date).format("MM-DD-YYYY"),
    note_header_no: values.note_header_no === undefined || values.note_header_no === "" ? null : values.note_header_no,
    return_header_no: values.return_header_no === undefined || values.return_header_no === "" ? null : values.return_header_no,
    invoice_header_no: values.invoice_header_no === undefined || values.invoice_header_no === "" ? null : values.invoice_header_no,
    ship_header_no: values.ship_header_no === undefined || values.ship_header_no === "" ? null : values.ship_header_no,
    
    
    reference_no: values.po_no === undefined || values.po_no === "" ? null : values.po_no,
    customer: values.customer === undefined || values.customer === "" ? null : values.customer,
    billcustomer: values.billing_addressee === undefined || values.billing_addressee === "" ? null : values.billing_addressee,
    sp_id: values.sale_person === "All" ? null : values.sale_person,
    region_id: values.region === "All" ? null : values.region,
    gender_id: values.gender === "All" ? null : values.gender,
    brand_id: values.brand_id === "All" ? null : values.brand_id,
    payment_term_id: values.terms === "All" ? null : values.terms,
    company_id: values.subsidary === "All" ? null : values.subsidary,
    sku: values.sku === undefined || values.sku === "" ? null : values.sku,
    location_id: values.location === undefined || values.location === "All" ? null : values.location,
    p_lot_no: values.asn_no === undefined ? null : values.asn_no,
    ra_no : values.ra_aproval === undefined ? null : values.ra_aproval,
    ntype: null,
    order_header_no: null,
    store_id: null,
    source_type: null,
    status: null,
  };
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/OmsReturnDetail`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_RETURN_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_RETURN_DETAIL_ERROR,
        payload: [],
      });
      
      
      
      
      
      
      
      
      
      
    });
};
