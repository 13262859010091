import {
	GET_SALEORDERADJUSTMENTFORMDATA_REQUEST,
	GET_SALEORDERADJUSTMENTFORMDATA_SUCCESS,
	GET_SALEORDERADJUSTMENTFORMDATA_ERROR,
	ADD_SALEORDERADJUSTMENT_HEADER_REQUEST,
	ADD_SALEORDERADJUSTMENT_HEADER_SUCCESS,
	ADD_SALEORDERADJUSTMENT_HEADER_ERROR,
	GET_ALL_SALEORDERADJUSTMENT_REQUEST,
	GET_ALL_SALEORDERADJUSTMENT_SUCCESS,
	GET_ALL_SALEORDERADJUSTMENT_ERROR,
	DELETE_SALEORDERADJUSTMENTHEADER_REQUEST,
	DELETE_SALEORDERADJUSTMENTHEADER_SUCCESS,
	DELETE_SALEORDERADJUSTMENTHEADER_ERROR,
	UPDATE_SALEORDERADJUSTMENT_HEADER_REQUEST,
	UPDATE_SALEORDERADJUSTMENT_HEADER_ERROR,
	UPDATE_SALEORDERADJUSTMENT_HEADER_SUCCESS,
	GET_ONE_SALEORDERADJUSTMENTHEADER_SUCCESS,
	GET_ONE_SALEORDERADJUSTMENTHEADER_REQUEST,
	GET_ONE_SALEORDERADJUSTMENTHEADER_ERROR,
	GET_ONE_SALEORDERADJUSTMENT_REQUEST,
	GET_ONE_SALEORDERADJUSTMENT_SUCCESS,
	GET_ONE_SALEORDERADJUSTMENT_ERROR,
	DELETE_SALESORDER_ADJ_LINE_REQUEST,
	DELETE_SALESORDER_ADJ_LINE_SUCCESS,
	DELETE_SALESORDER_ADJ_LINE_ERROR,
	GET_ONE_PENDINGORDERADJLINE_REQUEST,
	GET_ONE_PENDINGORDERADJLINE_SUCCESS,
	GET_ONE_PENDINGORDERADJLINE_ERROR,
} from "./types";
import moment from "moment";
import numeral from "numeral";
import { BASEURL } from "../../constant/config.js";
import { companyID } from "../../utils/constants.js";
const axios = require("axios");


export const getSaleOrderAdjustmentFormData = (token, companyId, menuId, customerName, customerId, clear) => (dispatch) => {
	dispatch({ type: GET_SALEORDERADJUSTMENTFORMDATA_REQUEST });

	if (clear) {
		return dispatch({
			type: GET_SALEORDERADJUSTMENTFORMDATA_SUCCESS,
			payload: {
				berganPaymentTerm: [],
				termForPrint: [],
				factorDetail: [],
				customerDetail: [],
				adminShipTaxCode: [],
				adminShipMethod: [],
				shipVia: [],
				customerTerm: [],
				freightTerm: [],
				berganBilling: [],
				salesPersonDetail: [],
				adminRegionDetail: [],
				orderStatusDetail: [],
				gender: [],
				locationDetail: [],
			},
		});
	}
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/formByCompany`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			company_id: companyId,
			menu_id: menuId,
			customer_name: customerName,
			customer_id: customerId,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_SALEORDERADJUSTMENTFORMDATA_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_SALEORDERADJUSTMENTFORMDATA_ERROR,
				payload: error.response,
			});
		});
};

export const getOneSaleOrderAdjustmentHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_SALEORDERADJUSTMENTHEADER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/saleOrderAdjHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_SALEORDERADJUSTMENTHEADER_SUCCESS,
				payload: response?.data.data[0],
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_SALEORDERADJUSTMENTHEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneSalesOrderAdjLine = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_SALEORDERADJUSTMENT_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/saleOrderAdjLines/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_SALEORDERADJUSTMENT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_SALEORDERADJUSTMENT_ERROR,
				payload: error.response,
			});
		});
};

export const pendingOrderAdjLine = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_PENDINGORDERADJLINE_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/getsaleorderlineadj/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_PENDINGORDERADJLINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_PENDINGORDERADJLINE_ERROR,
				payload: error.response,
			});
		});
};

export const addSaleOrderAdjustmentHeader = (token, formData, company_id, orderItems) => (dispatch) => {

	let uniqueOrderItems = orderItems.filter(
		(v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
	);
	let newData = {
		commit_status: orderItems.commit_status,
		bergan_billing_id: formData.berganBillingId,
		bergan_payment_term_id: formData.berganPaymentTermId,
		billing_address_id: formData.billingCustomer,
		cancel_date: moment(formData.cancelDate).format("MM-DD-YYYY"),
		company_id: company_id,
		customer_id: formData.customer,
		customer_store_no: formData.store,
		dc_no: formData.dc,
		discount_id: formData.discountId,
		discount_code: formData.discountCode,
		discount_basis: formData.discountBasis,
		discount_amount: numeral(formData.discount).format("00.00"),
		discount_percent: formData.discountPercent,
		department_description: formData.deptDescription,
		division_code: formData.divisionCode,
		division_description: formData.divisionDescription,
		factor_id: formData.factor,
		freight_amount: formData.freightAmount,
		freight_charges: formData.freightCharges,
		freight_term_id: formData.freightTermId,
		gender_id: formData.gender,
		is_closed: formData.isClosed,
		is_ship_complete: formData.isShipComplete,
		location_id: formData.location,
		mark_email: formData.markForEmail,
		mark_for_address_1: formData.markForAddress1,
		mark_for_address_2: formData.markForAddress2,
		mark_for_city: formData.markForCity,
		mark_for_code: formData.markForCode,
		mark_for_company_name: formData.markForCompanyName,
		mark_for_country: formData.markForCountry,
		mark_for_phone: formData.markForPhone,
		mark_for_state: formData.markForState,
		mark_for_zip: formData.markForZip,
		order_adj_date: moment(formData.orderDate).format("MM-DD-YYYY"),
		order_status_id: formData.orderStatus,
		payment_term_id: formData.paymentTermId ? formData.paymentTermId : formData.paymentTermId,
		randomData: uniqueOrderItems,
		reference_no: formData.po,
		region_id: formData.region,
		release_certificate_date: formData.resaleCertificateDate,
		release_certificate_no: formData.resaleCertificateNo,
		remarks: formData.remarks,
		sales_person_id: formData.salesPerson,
		salesPerson: formData.sales_person_id,
		ship_account_no: formData.shippingAccountNo,
		ship_via_id: formData.shipViaId,
		shipping_address_id: formData.shippingCustomer,
		shipping_cost: formData.shippingCost ? formData.shippingCost : 0,
		shipping_method_id: formData.shippingMethodId,
		shipping_tax_code: formData.shippingTaxCode,
		shipping_tax_rate: formData.shippingTaxRate,
		source_type: formData.sourceType,
		subsidiary_name: formData.subsidary,
		sp_region_category_id: formData.category,
		start_date: moment(formData.startDate).format("MM-DD-YYYY"),
		status: formData.order_status_id,
		term_for_print: formData.termForPrint,
		text_percent: formData.textPercent,
		memo: formData.memo,
		external_ref_id: formData.refID,
		department_code: formData.deptCode,
		ship_to_code: formData.shipToCode,
		special_instructions: formData.specialInstructions,
		billing_firstName: formData.billing_firstname ? formData.billing_firstname : null,
		billing_lastName: formData.billing_lastname ? formData.billing_lastname : null,
		shipping_firstName: formData.shipping_firstname ? formData.shipping_firstname : null,
		shipping_lastName: formData.shipping_lastname ? formData.shipping_lastname : null,
		order_header_id: formData.order_header_id,
		brand_id: formData.brand_id,
		bill_to_non_us: formData.bill_to_non_us ? formData.bill_to_non_us : null,
		ship_to_non_us: formData.ship_to_non_us ? formData.ship_to_non_us : null,
		order_type_id: formData.order_type ? formData.order_type : null,
		tax_duties: formData.tax_duties ? formData.tax_duties : null,
	};
	dispatch({ type: ADD_SALEORDERADJUSTMENT_HEADER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/saleOrderAdjHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_SALEORDERADJUSTMENT_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_SALEORDERADJUSTMENT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateSaleOrderAdjustmentHeader = (token, formData, saleOrderID, company_id, orderItems) => (dispatch) => {
	let uniqueOrderItems = orderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
	let newData = {
		commit_status: orderItems.commit_status,
		bergan_billing_id: formData.berganBillingId,
		bergan_payment_term_id: formData.berganPaymentTermId,
		billing_address_id: formData.billingCustomer,
		cancel_date: moment(formData.cancelDate).format("MM-DD-YYYY"),
		company_id: company_id,
		customer_id: formData.customer,
		customer_store_no: formData.store,
		dc_no: formData.dc,
		discount_id: formData.discountId,
		discount_code: formData.discountCode,
		discount_basis: formData.discountBasis,
		discount_amount: numeral(formData.discount).format("00.00"),
		discount_percent: formData.discountPercent,
		department_description: formData.deptDescription,
		division_code: formData.divisionCode,
		division_description: formData.divisionDescription,
		factor_id: formData.factor,
		freight_amount: formData.freightAmount,
		freight_charges: formData.freightCharges,
		freight_term_id: formData.freightTermId,
		gender_id: formData.gender,
		is_closed: formData.isClosed,
		is_ship_complete: formData.isShipComplete,
		location_id: formData.location,
		mark_email: formData.markForEmail,
		mark_for_address_1: formData.markForAddress1,
		mark_for_address_2: formData.markForAddress2,
		mark_for_city: formData.markForCity,
		mark_for_code: formData.markForCode,
		mark_for_company_name: formData.markForCompanyName,
		mark_for_country: formData.markForCountry,
		mark_for_phone: formData.markForPhone,
		mark_for_state: formData.markForState,
		mark_for_zip: formData.markForZip,
		order_adj_date: moment(formData.orderDate).format("MM-DD-YYYY"),
		order_status_id: formData.orderStatus,
		payment_term_id: formData.paymentTermId ? formData.paymentTermId : formData.paymentTermId,
		randomData: uniqueOrderItems,
		reference_no: formData.po,
		region_id: formData.region,
		release_certificate_date: formData.resaleCertificateDate,
		release_certificate_no: formData.resaleCertificateNo,
		remarks: formData.remarks,
		sales_person_id: formData.salesPerson,
		salesPerson: formData.sales_person_id,
		ship_account_no: formData.shippingAccountNo,
		ship_via_id: formData.shipViaId,
		shipping_address_id: formData.shippingCustomer,
		shipping_cost: formData.shippingCost,
		shipping_method_id: formData.shippingMethodId,
		shipping_tax_code: formData.shippingTaxCode,
		shipping_tax_rate: formData.shippingTaxRate,
		source_type: formData.sourceType,
		subsidiary_name: formData.subsidary,
		sp_region_category_id: formData.category,
		start_date: moment(formData.startDate).format("MM-DD-YYYY"),
		status: formData.order_status_id,
		term_for_print: formData.termForPrint,
		text_percent: formData.textPercent,
		memo: formData.memo,
		external_ref_id: formData.refID,
		department_code: formData.deptCode,
		ship_to_code: formData.shipToCode,
		special_instructions: formData.specialInstructions,
		billing_firstName: formData.billing_firstname ? formData.billing_firstname : null,
		billing_lastName: formData.billing_lastname ? formData.billing_lastname : null,
		shipping_firstName: formData.shipping_firstname ? formData.shipping_firstname : null,
		shipping_lastName: formData.shipping_lastname ? formData.shipping_lastname : null,
		order_header_id: formData.order_header_id,
		bill_to_non_us: formData.bill_to_non_us ? formData.bill_to_non_us : null,
		ship_to_non_us: formData.ship_to_non_us ? formData.ship_to_non_us : null,
		order_type_id: formData.order_type ? formData.order_type : null,
		tax_duties: formData.tax_duties ? formData.tax_duties : null,
	};
	dispatch({ type: UPDATE_SALEORDERADJUSTMENT_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/saleOrderAdjHeader/${saleOrderID}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_SALEORDERADJUSTMENT_HEADER_SUCCESS,
				payload: response.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_SALEORDERADJUSTMENT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getAllSalesOrderAdjustmentHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_SALEORDERADJUSTMENT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/saleOrderAdjHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			order_header_no: data.order === "" ? null : data.order,
			order_header_adj_no: data.orderAdj === "" ? null : data.orderAdj,
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
			company_id: companyID,
			po_no: data.po_no === "" ? null : data.po_no,
			customer_code: data.customer_code === "" ? null : data.customer_code,
			sales_person_name: data.sales_person_name === "" ? null : data.sales_person_name,
			region_name: data.region_name === "" ? null : data.region_name,
			gender_name: data.gender_name === "" ? null : data.gender_name,
			sp_region_category_id: null,
			term_name: data.term_name === "" ? null : data.term_name,
			brand_id: data.brand_id === "" ? null : data.brand_id
		}
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_SALEORDERADJUSTMENT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_SALEORDERADJUSTMENT_ERROR,
				payload: error.response,
			});
		});
};

export const deleteSalesOrderAdjLine = (token, id, data) => (dispatch) => {

	data.map((val) => {
		delete val.amount
		delete val.avg_cost
		delete val.commit_status
		delete val.created_by
		delete val.created_date
		delete val.deleted_by
		delete val.deleted_date
		delete val.discount
		delete val.discount_id
		delete val.discount_percent
		delete val.is_active
		delete val.is_closed
		delete val.is_deleted
		delete val.item_id
		delete val.last_updated_by
		delete val.last_updated_date
		delete val.net_amount
		delete val.new_rate
		delete val.ns_quantity_committed
		delete val.order_header_id
		
		delete val.order_header_adj_id
		delete val.quantity
		delete val.quantity_backorder
		delete val.quantity_billed
		delete val.quantity_committed
		delete val.quantity_packed
		delete val.quantity_picked
		delete val.quantity_rejected
		delete val.quantity_shipped
		delete val.rate
		delete val.style_id
		delete val.tax_amount
		delete val.tax_id
		delete val.tax_percent
		delete val.wash_id
	})
	dispatch({ type: DELETE_SALESORDER_ADJ_LINE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/saleOrderAdjLines/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: data }
	})
		.then((response) => {
			return dispatch({
				type: DELETE_SALESORDER_ADJ_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_SALESORDER_ADJ_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const deleteSaleOrderAdjustmentHeader = (token, id, orderItems) => (dispatch) => {
	dispatch({ type: DELETE_SALEORDERADJUSTMENTHEADER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/saleOrderAdjHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		
	})
		.then((response) => {
			return dispatch({
				type: DELETE_SALEORDERADJUSTMENTHEADER_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_SALEORDERADJUSTMENTHEADER_ERROR,
				payload: error.response,
			});
		});
};